import {get, writable} from "svelte/store";

/**
 * Similar to `LoginPresenter.currentSession` in GWT projects.
 */
export const session = writable(null);

export const clearSession = () => {
    session.set(null);
};

/*
 * Getters
 * It would not be recommended to make a getter for each property of `session` but rather
 * subscribe and gather only the values you need
 * https://svelte.dev/docs#get
 */
export const getUser = () => {
    return get(session)?.user;
};

export const getToken = () => {
    return get(session)?.token;
};

export const getPdModOverrideVar = () => {
    return get(session)?.pd_module_override;
};

export const getDefaultInstitution = () => {
    return get(session)?.default_institution;
};
