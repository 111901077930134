<!--This component is modified from https://svelte.dev/repl/2549062d9ef34baba3a4be9e6d342e72?version=3.14.0 -->

<script>
    import {onMount, tick} from 'svelte';
    import {fly} from 'svelte/transition';
    import {KEY_ENTER, KEY_DOWN, KEY_UP} from "../utils/constants";
    import randomString from "../utils/randomString";
    import PlusIcon from "../icons/plus-sign.svg";
    import ClearIcon from "../icons/clear-icon.svg";
    import SVGButton from "./SVGButton.svelte";

    export let readonly = false;
    export let showPlusButton = true;
    export let placeholder = '';
    export let value = [];

    export let onAdd = () => {
        console.log('onAdd not implemented');
    };

    export let onKeyUp = null;
    export let inputValue = '';
    export let options = [];

    const id = randomString();

    let input,
        activeOption,
        showOptions = false,
        selected = {},
        first = true, // tracks if first render or not
        slot; // <datalist> containing options, only useful if passing in options list as DOM

    // set initial value and selection
    onMount(() => {
        slot.querySelectorAll('option').forEach(option => {
            if (option.selected && !value.includes(option.value)) {
                value = [...value, option.value];
            }
            options = [...options, {value: option.value, name: option.textContent}];
        });
        // update selection in dropdown
        selected = options.reduce((obj, option) => {
            if (value.includes(option.value)) {
                return {...obj, [option.value]: option};
            }
            return obj;
        }, {});
        first = false;
    });

    // update value
    $: if (!first) value = Object.values(selected).map(o => o.value);
    // update filtered list after typing
    $: filtered = options.filter(option => {
        if (inputValue) {
            return option.name.toLowerCase().includes(inputValue.toLowerCase());
        }
        return option;
    }).sort((a, b) => a.length - b.length).slice(0, 15);

    $: if (activeOption && !filtered.includes(activeOption) || !activeOption && inputValue) {
        activeOption = filtered[0];
    }

    // trigger onAdd and reset input value
    function triggerOnAdd() {
        onAdd(value);
        inputValue = '';
    }

    function add(token) {
        if (!readonly) {
            selected[token.value] = token;
            inputValue = '';
        }
    }

    function remove(value) {
        if (!readonly) {
            // eslint-disable-next-line no-unused-vars
            const {[value]: val, ...rest} = selected;
            selected = rest;
        }
    }

    function optionsVisibility(show) {
        if (readonly) return;
        if (typeof show === 'boolean') {
            showOptions = show;
            show && input.focus();
        } else {
            showOptions = !showOptions;
        }
        if (!showOptions) {
            activeOption = undefined;
        }
    }

    function handleKeyup(e) {
        if (e.keyCode === KEY_ENTER) {
            Object.keys(selected).includes(activeOption.value) ? remove(activeOption.value) : add(activeOption);
            inputValue = '';
        } else if ([KEY_DOWN, KEY_UP].includes(e.keyCode)) { // up and down arrows
            const increment = e.keyCode === KEY_DOWN ? -1 : 1;
            const calcIndex = filtered.indexOf(activeOption) + increment;
            if (calcIndex < 0) {
                activeOption = filtered[filtered.length - 1];
            } else if (calcIndex === filtered.length) {
                activeOption = filtered[0];
            } else {
                activeOption = filtered[calcIndex];
            }
        }
        if (onKeyUp) {
            onKeyUp(e);
        }
    }

    function handleBlur() {
        optionsVisibility(false);
        // if the add button is hidden triggerOnAdd on blur
        if (!showPlusButton) {
            triggerOnAdd();
        }
    }

    async function handleTokenClick(e) {
        if (e.target.closest('.token-remove')) {
            e.stopPropagation();
            remove(e.target.closest('.token').dataset.id);
        } else if (e.target.closest('.remove-all')) {
            selected = [];
            inputValue = '';
            await tick(); // wait for values to propagate
            if (!showPlusButton) {
                triggerOnAdd();
            }
        } else {
            optionsVisibility(true);
        }
    }

    function handleOptionMousedown(e) {
        const value = e.target.dataset.value;
        if (selected[value]) {
            remove(value);
        } else {
            add(options.filter(o => o.value === value)[0]);
            input.focus();
        }
        // clear textbox when an option is selected, disabled so the user can select multiple options
        // inputValue = '';
    }
</script>

<div class="multiselect-container">
    <div class="multiselect" class:readonly>
        <div class="tokens" class:showOptions on:click={handleTokenClick}>
            {#each Object.values(selected) as s}
                <div class="token" data-id="{s.value}">
                    <span>{s.name}</span>
                    {#if !readonly}
                        <img class="token-remove" src={ClearIcon} title="Remove {s.name}" alt="remove">
                    {/if}
                </div>
            {/each}
            <div class="actions">
                {#if !readonly}
                    <input id={id} autocomplete="off" bind:value={inputValue} bind:this={input} on:keyup={handleKeyup} on:blur={handleBlur} placeholder={placeholder}/>
                    <img class="remove-all" src={ClearIcon} title="Remove All" class:hidden={!Object.keys(selected).length} alt="remove all">
                    <svg class="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M5 8l4 4 4-4z"></path></svg>
                {/if}
            </div>
        </div>

        <datalist bind:this={slot} class="hidden">
            <slot></slot>
        </datalist>

        {#if showOptions}
            <ul class="options" transition:fly="{{duration: 200, y: 5}}" on:mousedown|preventDefault={handleOptionMousedown}>
                {#each filtered as option}
                    <li class:selected={selected[option.value]} class:active={activeOption === option} data-value="{option.value}">{option.name}</li>
                {/each}
            </ul>
        {/if}
    </div>
    {#if showPlusButton}
        <SVGButton onClick={triggerOnAdd} svg={PlusIcon} disabled={!value.length} />
    {/if}
</div>

<style>
    .multiselect-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .multiselect {
        background-color: white;
        border-bottom: 1px solid hsl(0, 0%, 70%);
        position: relative;
    }
    .multiselect:not(.readonly):hover {
        border-bottom-color: hsl(0, 0%, 50%);
    }

    .tokens {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .tokens::after {
        bottom: -1px;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: rgb(2,95,204);
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }
    .tokens.showOptions::after {
        width: 100%;
        left: 0;
    }
    .token {
        align-items: center;
        background-color: hsl(214, 17%, 92%);
        border-radius: 1.25rem;
        display: flex;
        margin: .25rem .5rem .25rem 0;
        padding: .25rem .5rem .25rem .5rem;
        transition: background-color .3s;
    }
    .token:hover {
        background-color: hsl(214, 15%, 88%);
    }
    .readonly .token {
        color: hsl(0, 0%, 40%);
    }
    .token-remove, .remove-all {
        align-items: center;
        background-color: hsl(214, 15%, 55%);
        border-radius: 50%;
        color: hsl(214, 17%, 92%);
        display: flex;
        justify-content: center;
        height: 1.25rem;
        margin-left: .25rem;
        min-width: 1.25rem;
    }
    .token-remove:hover, .remove-all:hover {
        background-color: hsl(215, 21%, 43%);
        cursor: pointer;
    }

    .actions {
        align-items: center;
        display: flex;
        flex: 1;
        min-width: 15rem;
    }

    input {
        border: none;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 0;
        outline: none;
        padding: 0;
        width: 100%;
    }

    .dropdown-arrow path {
        fill: hsl(0, 0%, 70%);
    }
    .multiselect:hover .dropdown-arrow path {
        fill: hsl(0, 0%, 50%);
    }

    .options {
        box-shadow: 0 2px 4px rgba(0,0,0,.1), 0 -2px 4px rgba(0,0,0,.1);
        left: 0;
        list-style: none;
        margin-block-end: 0;
        margin-block-start: 0;
        max-height: 70vh;
        overflow: auto;
        padding-inline-start: 0;
        position: absolute;
        top: calc(100% + 1px);
        width: 100%;
        z-index: 100;
    }
    li {
        background-color: white;
        cursor: pointer;
        padding: .5rem;
    }
    li:last-child {
        border-bottom-left-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    li:not(.selected):hover {
        background-color: hsl(214, 17%, 92%);
    }
    li.selected {
        background-color: hsl(232, 54%, 41%);
        color: white;
    }
    li.selected:nth-child(even) {
        background-color: hsl(232, 50%, 45%);
        color: white;
    }
    li.active {
        background-color: hsl(214, 17%, 88%);
    }
    li.selected.active, li.selected:hover {
        background-color: hsl(232, 48%, 50%);
    }
</style>