import { writable } from 'svelte/store';

const patientSearchQueries= {
    full_name: '',
    patient_mrn: '',
    assay_names: '',
    markers: '',
    pos_markers: '',
    vus_markers: '',
    neg_markers: '',
    diagnosis: '',
    date_of_birth: '',
    reported_start_date: '',
    reported_end_date: '',
    gender: '',
    report_id: '',
    ordering_physician: '',
    genes: '',
    report_status: '',
    data_sets: ''
};

export let searchQueries = writable(patientSearchQueries);

export let totalPatients = writable('');

export let matchedPatients = writable('');