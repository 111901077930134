<script>
    import { getUser } from "../../store/session";

    function getUserGreeting() {
        const user = getUser();
        const { first_name, last_name, username } = user;
        if (!first_name && !last_name) {
            return username;
        }
        return `${first_name} ${last_name}`.trim();
    }

</script>

<h3>Welcome, {getUserGreeting()}</h3>