<script>
    import {allTrialfilters, instTrialfilters, patientIsLoaded, distanceParam, zipcodeParam} from "../../store/patientMatch";
    import {addToHistory, expandMainHeader, selectedOption} from "../../store/navigation";
    import {getBaseNavigationUrl} from '../../utils/urls';
    import {pane,selectedCase} from "../../store/patientMatch";
    import {faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {Icon} from "svelte-awesome";

    let allFilters = [];
    let instFilters = [];

    function selectOption(option) {
        addToHistory(option);
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }

    $: {
        // Reset filter lists
        allFilters = [];
        instFilters = [];

        // Update allFilters list
        Object.keys($allTrialfilters).forEach(key => {
            $allTrialfilters[key].forEach(value => {
                allFilters.push({label: key, value: value, source: 'all'});
            });
        });

        expandMainHeader.set(true);

        // Update instFilters list
        Object.keys($instTrialfilters).forEach(key => {
            $instTrialfilters[key].forEach(value => {
                // Check if value exists in allFilters
                const existsInAllFilters = allFilters.find(filter => filter.label === key && filter.value === value);
                if (existsInAllFilters) {
                    existsInAllFilters.source = 'all';
                } else {
                    instFilters.push({label: key, value: value, source: 'inst'});
                }
            });
        });

    }

    function removeInstFilter(value, label) {
        instTrialfilters.update(currentFilters => {
            const updatedFilters = {...currentFilters};
            updatedFilters[label] = updatedFilters[label].filter(filterValue => filterValue !== value);
            return updatedFilters;
        });
    }

    function removeFilter(value, label) {
        allTrialfilters.update(currentFilters => {
            const updatedFilters = {...currentFilters};
            updatedFilters[label] = updatedFilters[label].filter(filterValue => filterValue !== value);
            return updatedFilters;
        });
        if(label === 'Distance'){
            distanceParam.set('unset')
            zipcodeParam.set('unset')
        }
    }


    // Toggle function to switch between "all" and "inst" states
    function toggleFilter(filter) {
        if (filter.source === 'all') {
            // Switch to 'inst'
            allTrialfilters.update(currentFilters => {
                const updatedFilters = {...currentFilters};
                updatedFilters[filter.label] = updatedFilters[filter.label].filter(value => value !== filter.value);
                return updatedFilters;
            });
            instTrialfilters.update(currentFilters => {
                const updatedFilters = {...currentFilters};
                updatedFilters[filter.label] = [...currentFilters[filter.label], filter.value];
                return updatedFilters;
            });
        } else if (filter.source === 'inst') {
            // Switch to 'all'
            instTrialfilters.update(currentFilters => {
                const updatedFilters = {...currentFilters};
                updatedFilters[filter.label] = updatedFilters[filter.label].filter(value => value !== filter.value);
                return updatedFilters;
            });
            allTrialfilters.update(currentFilters => {
                const updatedFilters = {...currentFilters};
                updatedFilters[filter.label] = [...currentFilters[filter.label], filter.value];
                return updatedFilters;
            });
        }
    }

</script>

<style>
    .truncate {
        position: relative;
    }

    .truncate:hover:after {
        display: initial;
        flex-direction: initial;
        justify-content: initial;
        content: attr(data-tooltip); /* Display full text as tooltip */
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 5px;
        border-radius: 3px;
        font-size: 12px;
        /* Adjust tooltip position */
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        max-width: 300px;
    }

    .selected-values-container {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        max-width: 100vw;
    }

    .selected-value {

    }

    .toggle-switch {
        cursor: pointer;
    }

    .remove-selection {
        margin-left: 1em;
        cursor: pointer;
    }

    .match-header {
        cursor: default;
        display: flex;
        align-content: space-between;
        width: calc(100vw - 100px);
        text-align: left;
    }

    .header {
        font-weight: bold;
        margin-top: 5px;
    }

    .body {
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5vw;
        max-width: calc(100vw / 4);
        height: 10vh;
        overflow-x: scroll;
    }

    .filter-body {
        height: 10vh;
        margin: 0;
        overflow-x: scroll;
    }

    .summary-button:hover {
        background-color: #f2f2f2;
    }

    .summary-button {
        margin-left: 1vw;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 10px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        background-color: #f2f2f2;
    }

    .patient-details {
        margin-right: 10px;
    }
</style>
{#if ($patientIsLoaded)}
    <div class="match-header" style="height:150px">
        <div style="margin-right: 1.5vw;">
            <p class="summary-button" style="margin-top: 45px;" on:click={() => pane.set("backToSummary")}> Back to<br/>Summary</p>
        </div>
        <div class="patient-details">
            <div style="margin-top: 5px;">
                <span style="font-weight: bold">Name:</span>
                {#if ($selectedCase.patient_name)}
                    <span>{$selectedCase.patient_name}</span>
                {/if}
            </div>
            <div style="margin-top: 5px;">
                <span style="font-weight: bold">Disease:</span>
                {#if ($selectedCase.specific_diseases)}
                    <span>{$selectedCase.specific_diseases}</span>
                {/if}
            </div>
            <div style="margin-top: 5px;">
                <span style="font-weight: bold">Assay:</span>
                {#if ($selectedCase.assay_names)}
                    <span>{($selectedCase.assay_names).join(', ')}</span>
                {/if}
            </div>
        </div>
        <p class="header">Trigger Alterations: </p>
        <p class="body">
            {#if ($selectedCase.case_glance)}
                <span>{($selectedCase.case_glance.trigger_alterations).join(', ')}</span>
            {/if}
        </p>
        {#if instFilters.length > 0 || allFilters.length > 0}
            <div style="width: 45vw;">
            <p class="header">Applied Filters: </p>
            <div class="filter-body">
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "NCTID")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "NCTID"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                NCT ID(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'NCTID'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'NCTID'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "ProtocolID")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "ProtocolID"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Protocol ID(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'ProtocolID'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'ProtocolID'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "Biomarkers")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "Biomarkers"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Biomarker(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'Biomarkers'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Biomarkers'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "Diseases")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "Diseases"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Disease(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'Diseases'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Diseases'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "Phase")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "Phase"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Phase(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "RecruitingStatus")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "RecruitingStatus"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Recruiting Status(es):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'RecruitingStatus'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "Drugs")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "Drugs"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Drug(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'Drugs'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Drugs'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "DrugCategory")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "DrugCategory"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Drug Category:
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'DrugCategory'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">
                                                        {#if (Object.keys(filter.value).length < 40)}
                                                            {Object.keys(filter.value)}
                                                        {:else}
                                                            <span class="truncate"
                                                                  data-tooltip="{Object.keys(filter.value)}">
                                                                {Object.keys(filter.value).slice(0, 37)}...
                                                            </span>
                                                        {/if}
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Drugs'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "PriorTreatment")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "PriorTreatment"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Prior Treatment:
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'PriorTreatment'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">
                                                        {filter.value}
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "TherapeuticContext")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "TherapeuticContext"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Therapeutic Context(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'TherapeuticContext'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">
                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "DiseaseState")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "DiseaseState"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Disease State(s):
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'DiseaseState'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
                {#if (allFilters.length > 0 && allFilters.some(filter => filter.label === "Distance")) || (instFilters.length > 0 && instFilters.some(filter => filter.label === "Distance"))}
                    <table style="margin-bottom: 10px;">
                        <tr>
                            <td style="width:200px; font-weight: bold;">
                                Matched Location:
                            </td>
                            <td style="width:425px; display: flex; flex-direction:column;">
                                {#if (allFilters.length > 0)}
                                    {#each allFilters as filter, index (filter)}
                                        {#if filter.label === 'Distance'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            <strong>Zip Code</strong>: {filter.value.zip} <strong>Distance</strong>: {filter.value.distance} miles

                                                        <!--<span style="margin-left:1em; font-size: 12px;">inst</span>-->
                                                        <!--<span class="toggle-switch"-->
                                                        <!--      on:click={() => toggleFilter(filter)}>-->
                                                        <!--    <Icon data="{faToggleOn}" scale:0.5/>-->
                                                        <!--</span>-->
                                                        <!--<span style="font-size: 12px;">all</span>-->
                                                        <span class="remove-selection"
                                                              on:click={() => removeFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>
                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                                {#if (instFilters.length > 0)}
                                    {#each instFilters as filter, index (filter)}
                                        {#if filter.label === 'Phase'}
                                            <div class="selected-values-container">
                                                    <span class="selected-value">

                                                            {#if (filter.value.length < 40)}
                                                                {filter.value}
                                                            {:else}
                                                                <span class="truncate"
                                                                      data-tooltip="{filter.value}">
                                                                    {filter.value.slice(0, 37)}...
                                                                </span>
                                                            {/if}

                                                        <span style="margin-left:1em; font-size: 12px;">inst</span>
                                                        <span class="toggle-switch"
                                                              on:click={() => toggleFilter(filter)}>
                                                            <Icon data="{faToggleOff}" scale:0.5/>
                                                        </span>
                                                        <span style="font-size: 12px;">all</span>
                                                        <span class="remove-selection"
                                                              on:click={() => removeInstFilter(filter.value, filter.label)}>
                                                            <Icon data="{faCircleXmark}" scale="1"/>

                                                        </span>
                                                    </span>
                                            </div>
                                        {/if}
                                    {/each}
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/if}
            </div>
        </div>
        {/if}
    </div>
{:else}
    <div class="match-header">
        <div style="margin-right: 1.5vw;">
            <p class="summary-button" style="margin-top:45px;" on:click={() => pane.set("backToSummary")}> Back to<br/>Summary</p>
        </div>
    </div>
{/if}
