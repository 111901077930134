import Dashboard from "./pages/Dashboard.svelte";
import AddCases from "./pages/AddCases.svelte"
import PatientSearch from "./pages/PatientSearch.svelte";
import PatientMatch from "./pages/PatientMatch.svelte";
import PatientTracking from "./pages/PatientTracking.svelte";
import AlertDashboard from "./pages/AlertDashboard.svelte";
import Notifications from "./pages/Notifications.svelte";
import InstitutionalInsights from "./pages/InstitutionalInsights.svelte";


export default {
    '/dashboard': Dashboard,
    '/patient-search': PatientSearch,
    '/alert-dashboard': AlertDashboard,
    '/notifications': Notifications,
    '/institutional-insights': InstitutionalInsights,
    '/patient-management': PatientTracking,
    '/patient-match': PatientMatch,
    '/add-cases': AddCases,

    // Home
    '/': Dashboard,
    '*': Dashboard,
};