<script>
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
    import InstitutionalHeader from "../components/institutional_insights/InstitutionalHeader.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{InstitutionalHeader}"/>
</div>


