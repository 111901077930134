import {writable} from "svelte/store";
import {queryKeys} from "./currentAlertQuery";

/**
 * A writeable store that keeps track of the facetConfig. Used by exportPayload to determine the default export table
 * columns as well as defining the sections of the left-hand querying UI (i.e. Patient Characteristics, etc.)
 */
export const facetConfig = writable(...[]);


export default function setFacetConfig(config) {
    let categoryKeys = [];
    for (let filterCategory of config) {
        categoryKeys.push(filterCategory["filterCategory"]);
    }
    facetConfig.set(config);
    queryKeys.set(categoryKeys);
}