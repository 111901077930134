export const RADIO_ALL = 'All';
export const RADIO_ANY = 'Any';
export const RADIO_NONE = 'None';

export const KEY_ENTER = 13;
export const KEY_DOWN = 38;
export const KEY_UP = 40;

export const OPEN = 'open';

export const FULL_ACCESS = 'FULL_ACCESS';

export const CASE_FIELDS = "id,significant_alterations,vus_alterations,oncologic_treatments,uuid,order_identifier,lab_report_report_ids,alert_triggers,patient_name,patient_id,patient_uuid,patient_information,patient_mrn,specific_diseases,meta,assay_names,lab_reported_dates";