<script>
    let username = '';
    let email = '';
    let state = "entry";

    export let closeForgotModalWithSubmit;

    const onSubmit = async () => {
        console.log("sending forgot password");
        closeForgotModalWithSubmit();
        //      Example:
        //         state = "wait";
        //         const success = await forgotPassword(username, email);

        //         if (success) {
        //             dispatch("passwordSent");
        //         } else {
        //             state = "error";
        //         }
    };

    const clearError = () => {
        state = "entry";
    };

    $: clearError(username, email);
</script>

<div class="contents">
<h1 class="go-navy-text">Forgot Password</h1>

    <form class="ui large form" on:submit|preventDefault={onSubmit}>
        <div class="field">
            <div class="ui left icon input">
                <i class="user icon"></i>
                <input class="forgotField" required="required" type="text" placeholder="Username"
                       bind:value={username}>
            </div>
        </div>
        <div class="field">
            <div class="ui left icon input">
                <i class="mail icon"></i>
                <input class="forgotField" required="required" type="email" placeholder="Email"
                       bind:value={email}/>
            </div>
        </div>
        <div id="buttons">
            <button class="modalButton" type="submit">
                Submit
            </button>
        </div>
        {#if state === "error"}
            <div class="ui visible error message">
                Request failed, please try again or contact support.
            </div>
        {/if}
        {#if state === "wait"}
            <div class="ui visible info message">
                <div class="ui active loader inline fast"></div>
                &nbsp;
                Requesting Email Token
            </div>
        {/if}
    </form>
</div>

<style>
    .contents{
        width: 75%;
    }

    div#buttons {
        padding-top: 2em;
    }

    .forgotField{
        border-radius: 5px;
        margin-bottom: .5em;
    }

    .modalButton{
        background-color: #5da3d9;
        width: 30%;
        border-radius: 5px;
        color: white;
        -webkit-font-smoothing: antialiased;
    }
</style>