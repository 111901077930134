export function getBaseNavigationUrl() {
    let hostInformation = window.location.host;
    let host = hostInformation;
    let sPROTOCOL = "https";
    if (hostInformation.includes("localhost") ||
        hostInformation.includes("127.0.0.1") ||
        hostInformation.includes("192.168") ||
        hostInformation.includes("10.0.1")) {
        sPROTOCOL = "http";
        return `${sPROTOCOL}://${host}/`;
    }
    return `${sPROTOCOL}://${host}/precision-decision-v2/`;
}