<script>
    // store
    import {createAlertConfig, getUsers, getAlertConfig, updateAlertConfig} from "../../network/user";
    import {getUser} from "../../store/session";
    import {clearQuery} from "../../store/currentAlertQuery";
    import query from "../../store/query";
    import {onMount} from "svelte";
    import Modal from "../Modal.svelte";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {createQueryFromExistingAlertParams} from "../../utils/queryUtil";
    import {alertID} from "../../store/navigation";
    import {Icon} from "svelte-awesome";
    import {faAsterisk} from "@fortawesome/free-solid-svg-icons";

    let shareAlertModal;

    let isUpdateView = false;
    let shareAlertOption = false;
    let alertName = '';
    let alertFrequency = '';
    let alertTime = '';
    let alert_id = $alertID;
    let notificationFrequency = '';
    let selectedDay = "";
    let numOfDays = "";
    let alertFrequencyDay = "";

    onMount(async () => {
        await retrieveUsers();
        if (alert_id){
            await setAlertInfoFromID(alert_id);
        }
    });

    let rows = [];
    async function retrieveUsers() {
        const response = await getUsers();
        if (response.ok) {
            const body = await response.json();
            rows = body.results;
        }
    }

    async function setAlertInfoFromID(id) {
        if (id !== '') {
            const response = await getAlertConfig(id);
            alertName = response.name;
            alertFrequency = response.cadence;
            if (response.cadence_day !== null) {
                selectedDay = response.cadence_day;
                notificationFrequency = "dayOfWeek";
            } else {
                if (alertFrequency === 0) {
                    notificationFrequency = "instantaneous";
                } else if (alertFrequency === 1) {
                    notificationFrequency = "daily";
                } else {
                    notificationFrequency = "everyNumOfDays";
                    numOfDays = response.cadence;
                }
            }
            if (response.time !== null) {
                const parts = response.time.split(":");
                const hours = parts[0];
                const minutes = parts[1];
                alertTime = `${hours}:${minutes}`;
            }
            queryToSave = response.alert_parameters.filters;
            createQueryFromExistingAlertParams(response);
            if (response.shared_users !== null) {
                shareAlertOption = true;
                setSharedUsers(response.shared_users);
            }
            isUpdateView = true;
            alertID.set(id);
        }
    }

    function setSharedUsers(shared_users) {
        let user_names = shared_users.split(",");
        rows.forEach(user => {
            for (let i = 0; i < user_names.length; i++) {
                if (user.username === user_names[i]) {
                    selectedItems.add(user);
                }
            }
        });
    }

    const onSaveAlert = async () => {
        if (alertName === '') {
            pushFail("Must add an alert name.");
        } else if (notificationFrequency === '') {
            pushFail("Must add a notification frequency.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays === "") {
            pushFail("Must add a value for Every __ Days entry.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays <= 1) {
            pushFail("Must add a value for Every __ Days entry above 1.")
        } else if (notificationFrequency === "dayOfWeek" && selectedDay === "") {
            pushFail("Must choose a day of the week.");
        } else if (queryToSave.length === 0) {
            pushFail("Must add at least one query filter.");
        } else if (shareAlertOption) {
            shareAlertModal.open();
        } else {
            determineFrequencyInfo();
            let body = {
                "name": alertName,
                "cadence": alertFrequency,
                "alert_parameters": {
                    "filters": queryToSave
                }
            };
            if (alertFrequencyDay !== "") {
                body["cadence_day"] = alertFrequencyDay;
            }
            if (alertTime !== "") {
                body["time"] = alertTime;
            }
            let response;
            if (isUpdateView) {
                response = await updateAlertConfig(alert_id, body);
            } else {
                response = await createAlertConfig(getUser().id, body);
            }
            if (response.ok) {
                if (isUpdateView) {
                    pushSuccess("Alert has been updated.");
                } else {
                    pushSuccess("Alert has been created.");
                }
                shareAlertOption = false;
                handleClear();
            } else {
                const body = await response.json();
                if (body.name) {
                    pushFail('This alert name is already in use.');
                } else {
                    if (isUpdateView) {
                        pushFail('There was a problem updating the alert.');
                    } else {
                        pushFail('There was a problem creating the alert.');
                    }
                }
            }
        }
    };

    const onSaveShareAlert = async () => {
        determineFrequencyInfo();
        let body = {
            "name": alertName,
            "cadence": alertFrequency,
            "shared_users": Array.from(selectedItems).map(item => item.username).join(','),
            "alert_parameters": {
                "filters": queryToSave
            }
        };
        if (alertFrequencyDay !== "") {
            body["cadence_day"] = alertFrequencyDay;
        }
        if (alertTime !== "") {
            body["time"] = alertTime;
        }
        let response;
        if (isUpdateView) {
            response = await updateAlertConfig(alert_id, body);
        } else {
            response = await createAlertConfig(getUser().id, body);
        }
        if (response.ok) {
            if (isUpdateView) {
                pushSuccess("Alert has been updated.");
            } else {
                pushSuccess("Alert has been created.");
            }
            shareAlertModal.close();
            shareAlertOption = false;
            handleClear();
        } else {
            const body = await response.json();
            if (body.name) {
                pushFail('This alert name is already in use.');
            } else {
                if (isUpdateView) {
                    pushFail('There was a problem updating the alert.');
                } else {
                    pushFail('There was a problem creating the alert.');
                }
            }
        }
    };

    function determineFrequencyInfo() {
        if (notificationFrequency === "instantaneous") {
            alertFrequency = 0;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "daily") {
            alertFrequency = 1;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "everyNumOfDays") {
            alertFrequency = numOfDays;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "dayOfWeek") {
            alertFrequency = 7;
            alertFrequencyDay = selectedDay;
        }
    }

    let queryToSave;
    query.subscribe((val) => {
        queryToSave = val;
    });

    function handleClear() {
        shareAlertOption = false;
        clearQuery();
        alertName = '';
        alertFrequency = '';
        selectedItems = new Set();
        isUpdateView = false;
        notificationFrequency = '';
        alertTime = '';
        selectedDay = '';
        numOfDays = '';
    }

    let selectedItems = new Set();
    function handleCheckboxChange(item) {
        if (selectedItems.has(item)) {
            selectedItems.delete(item);
        } else {
            selectedItems.add(item);
        }
    }

</script>

<div class="alert-configuration">
    <div class="content">
        <div class="alert-modal-first-row">
            <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => handleClear()}>Clear</button>
            {#if isUpdateView}
                <button class="alert-modal-button" style="background-color: #9FCE67" on:click={() => onSaveAlert()}>Update</button>
            {:else}
                <button class="alert-modal-button" style="background-color: #9FCE67" on:click={() => onSaveAlert()}>Create</button>
            {/if}
            <label>
                <input class="alert-modal-checkbox" type="checkbox" style="width: 15px; height: 15px;" bind:checked={shareAlertOption}>
                Share Alert
            </label>
        </div>
    </div>
    <div class="footer">
        <div class="alert-field-first">
            <label style="float: left;">Name</label>
            <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
            <input class="alert-input-first" type="text" bind:value={alertName} name="alertName" placeholder="Alert Name" required>
        </div>
        <div class="alert-field-second">
            <div style="margin-right: 50px;">
                <div style="margin-bottom: 10px">
                    <label style="float: left;">Notification Frequency</label>
                    <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
                </div>
                <div style="text-align: left; margin-top: 10px;">
                    <label>
                        <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="instantaneous" bind:group={notificationFrequency}>
                        Instantaneous
                    </label>
                    <label>
                        <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="daily" bind:group={notificationFrequency}>
                        Daily
                    </label>
                    <label>
                        <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="everyNumOfDays" bind:group={notificationFrequency}>
                        Every __ Days
                    </label>
                    {#if notificationFrequency === "everyNumOfDays"}
                        <input type="text" bind:value={numOfDays}>
                    {/if}
                    <label>
                        <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="dayOfWeek" bind:group={notificationFrequency}>
                        Weekly
                    </label>
                    {#if notificationFrequency === "dayOfWeek"}
                        <select bind:value={selectedDay}>
                            <option value="">Select a day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                        </select>
                    {/if}
                </div>
            </div>
            <div>
                {#if notificationFrequency !== "instantaneous"}
                    <label style="text-align: left">Notification Time</label>
                    <input class="alert-input-second" type="time" bind:value={alertTime}/>
                {/if}
            </div>
        </div>
    </div>
</div>

<!--Share Alert Modal-->
<form on:submit|preventDefault={onSaveShareAlert}>
    <Modal bind:modalFunc={shareAlertModal}>
        <div slot="header">
            <div>
                <h1>Please select which users to share notifications with.</h1>
            </div>
        </div>
        <div slot="content">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {#each rows as item}
                    <tr>
                        <td><input type="checkbox" checked={selectedItems.has(item)} on:change={() => handleCheckboxChange(item)} /></td>
                        <td>{item.username}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email}</td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
        <div slot="footer">
            <div class="alert-modal-footer-row">
                <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => shareAlertModal.close()}>Cancel</button>
                {#if isUpdateView}
                    <button class="alert-modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Update</button>
                {:else}
                    <button class="alert-modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Create</button>
                {/if}
            </div>
        </div>
    </Modal>
</form>

<style>

    .alert-field-first{
        margin-top: 10px;
    }
    .alert-field-second{
        margin-top: 20px;
        display: flex;
    }
    .alert-input-first{
        width: 35vw;
    }
    .alert-input-second{
        float: left;
        width: 15vw;
    }
    .alert-modal-button{
        margin-right: 10px;
        padding: 8px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .alert-modal-checkbox{
        margin-top: 10px;
        margin-left: 10px;
    }
    .alert-modal-first-row{
        margin-top: 10px;
        display: flex;
    }

    .range-slide-labels span {
        font-size: 1.5em;
    }

    .query-row input {
        width: 80%;
        padding: 8px 12px;
    }

    .content {
        margin-left: 10px;
        margin-right: 10px;
    }

    .footer {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }

</style>