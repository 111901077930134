<!-- Modal.svelte -->
<script>
    import { createEventDispatcher, afterUpdate, onMount } from "svelte";

    export let isOpen = false;
    export let width = "90%";
    let modalWidth;

    const dispatch = createEventDispatcher();

    function closeModal() {
        dispatch("close");
    }

    function handleOutsideClick(event) {
        const modalContent = document.querySelector(".modal-content");
        const isButton = event.target.closest("button");
        if (!modalContent.contains(event.target) && !isButton) {
            closeModal();
        }
    }

    function handleOutsideKeydown(event) {
        if (event.key === "Escape") {
            closeModal();
        }
    }

    onMount(() => {
        // Set initial width value when the component is mounted
        modalWidth = width;
    });

    afterUpdate(() => {
        // Update width value when it changes
        modalWidth = width;
    });
</script>

{#if isOpen}
    <div class="modal" tabindex="-1" on:click={handleOutsideClick} on:keydown={handleOutsideKeydown}>
        <div class="modal-content" style="width: {modalWidth};">
            <div class="content-wrapper">
                <slot></slot>
            </div>
            <button class="close-button" on:click={closeModal}>X</button>
        </div>
    </div>
{/if}

<style>
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999; /* Ensure the modal appears on top of other elements */
    }

    .modal-content {
        background-color: white;
        max-width: 100%;
        padding: 20px;
        border-radius: 5px;
        position: relative;
    }

    .content-wrapper {
        max-height: 80vh; /* Adjust the max height as needed */
        overflow-y: auto;
    }

    .close-button {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 5px;
        font-size: 20px;
        color: white;
        background-color: #233C6B;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        z-index: 1;
    }
</style>
