<script>
    import PatientManagementTable from "../components/patient_search/PatientManagementTable.svelte";
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PatientSearchHeader from "../components/patient_search/PatientSearchHeader.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{PatientSearchHeader}" mainContent={PatientManagementTable}/>
</div>


