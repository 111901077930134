<script>
    import {onMount} from 'svelte';
    import {patientInfoOptions} from '../../store/patientInfoOptions.js';
    let visibleOptions = [];
    let headers = [];
    let data = [];
    let tableData = [];

    export let additionalPatientInfoCase;

    async function setupTable() {
        visibleOptions = tableData.filter(option => option.visible);
        headers = visibleOptions.map(option => option.name);
        data = visibleOptions.map(option => option.field);
    }

    patientInfoOptions.subscribe(value => {
        tableData = value;
        setupTable();
    });

    function getInfoFromCase(field) {
        let c = additionalPatientInfoCase;
        let value = 'N/A';
        if (field in c) {
            value = c[field];
        } else if (c['meta'] !== null && field in c['meta']) {
            value = c['meta'][field];
        } else if (c['patient_information'] !== null && field in c['patient_information'][0]) {
            value = c['patient_information'][0][field];
        }

        if (Array.isArray(value)) {
            if (value.every(value => value === null)) {
                return "N/A";
            } else {
                return Array.from(new Set(value)).join(", ");
            }
        } else {
            if (value === null) {
                return "N/A";
            } else {
                return value;
            }
        }
    }

    function groupTherapiesByType() {
        const grouped = {};
        additionalPatientInfoCase['oncologic_treatments'].forEach(therapy => {
            if (!grouped[therapy.type_of_therapy]) {
                grouped[therapy.type_of_therapy] = [];
            }
            if (!grouped[therapy.type_of_therapy].includes(therapy.drugs)) {
                grouped[therapy.type_of_therapy].push(therapy.drugs);
            }
        });
        return grouped;
    }

    onMount(() => {
        setupTable();
    });

</script>
<style>
    .container {
        display: flex;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid black;
    }
    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid black;
    }
    th {
        background-color: lightgray;
        color: #ffffff;
    }
    tr {
        background-color: white;
    }

</style>
<div class="container">
    <table>
        <thead>
        <tr>
            {#each headers as header}
                <th style="color: black;">{header}</th>
            {/each}
        </tr>
        </thead>
        <tbody>
        <tr>
            {#each data as row}
                {#if row === "oncologic_treatments"}
                    {#if Object.keys(groupTherapiesByType()).length !== 0}
                        {#each Object.entries(groupTherapiesByType()) as [type, drugs]}
                            <div>
                                <div style="font-weight: bold; margin-top: 5px;">{type}</div>
                                <ul style="margin-top: 0">
                                    {#each drugs as drug}
                                        <li>{drug}</li>
                                    {/each}
                                </ul>
                            </div>
                        {/each}
                    {:else}
                        <td style="max-width: 175px; vertical-align: top;">N/A</td>
                    {/if}
                {:else}
                    <td style="max-width: 175px; vertical-align: top;">{getInfoFromCase(row)}</td>
                {/if}
            {/each}
        </tr>
        </tbody>
    </table>
</div>