import {callApi} from "./rest";

const ROOT_URL_CONSULT = '/consult';

export const getDashboardInfo = async () => {
    const url = `${ROOT_URL_CONSULT}/precision_decision/dashboard`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get dashboard info");
    }
};

