<script>
    import {createEventDispatcher} from 'svelte';
    import {changePassword} from '../../network/user';
    import {toast} from "@zerodevx/svelte-toast";

    const dispatch = createEventDispatcher();

    let params = location.hash.split('%7C');  // pipe (|) = %7C
    let token = params[1];
    let email = params[2];
    let username = params[3];
    let password = "";
    let confirm = "";

    location.hash = "#"; // clear hash to prevent re-entry

    let errorMsg = null;
    let waiting = false;

    function pushSuccess(message) {
        toast.push(message, {
            theme: {
                '--toastBackground': '#48BB78',
                '--toastProgressBackground': '#2F855A',
            }
        });
    }


    const onSubmit = async () => {
        if (password.length <= 5 || password !== confirm) {
            errorMsg = "Passwords do not match. Please correct.";
            return;
        }

        waiting = true;
        const response = await changePassword(token, email, username, password);
        waiting = false;

        if (response.ok) {
            pushSuccess("Password reset successfully.");
            toLogin();
        } else {
            errorMsg = "Reset failed, please try again or contact support.";
            password = "";
            confirm = "";
        }
    };

    const toLogin = () => {
        dispatch("toLogin");
    };

    const clearError = () => {
        errorMsg = null;
    };

    $: clearError(password, confirm);
</script>

<h2>Reset Password</h2>

<form class="ui large form" on:submit|preventDefault={onSubmit}>
    <div class="field">
        <div class="ui transparent left icon input">
            <i class="user icon"></i>
            <input disabled="disabled" type="text" placeholder="Username"
                   value="{username} ({email})">
        </div>
    </div>

    <div class="field">
        <div class="ui left icon input">
            <i class="lock icon"></i>
            <input required="required" type="password"
                   placeholder="Enter Password"
                   bind:value={password}>
        </div>
    </div>

    <div class="field">
        <div class="ui left icon input">
            <i class="check double icon"></i>
            <input required="required" type="password"
                   placeholder="Confirm Password"
                   bind:value={confirm}>
        </div>
    </div>

    <div id="buttons">
        <button class="ui large primary button" type="submit">
            Submit
        </button>
        <span id="toLogin">Back to Login
<!--             <a href="javascript:void(0)" on:click={toLogin}>Back to Login</a> -->
        </span>
    </div>

    {#if errorMsg}
        <div class="ui visible error message">
            {errorMsg}
        </div>
    {:else if waiting}
        <div class="ui visible info message">
            <div class="ui active loader inline fast"></div>
            &nbsp;
            Submitting new password.
        </div>
    {/if}
</form>

<style>
    h2 {
        padding-bottom: 0.2em;
    }

    div#buttons {
        padding-top: 2em;
    }
</style>