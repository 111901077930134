<script>
    import {onMount} from "svelte";
    import ResetForm from "../components/login/ResetForm.svelte";
    import LoginForm from "../components/login/LoginForm.svelte";
    import SentMessage from "../components/login/SentMessage.svelte";

    // modes = login, forgot, token, sent
    // login: user can login with username, password, and 2FA (optional)
    // forgot: user can submit username and email address to request token
    // token: user clicks link with token and can submit new password
    // sent: user submitted password token request and should check email
    let mode = "login";

    const reset_password_information = {
        uid: '',
        token: ''
    };

    onMount(() => {
        mode = location.hash.startsWith("#token") ? "token" : "login";

        if (mode === 'token') {
            reset_password_information.uid = location.hash.split('|')[1];
            reset_password_information.token = location.hash.split('|')[2];
        }
    });

    const toLogin = () => {
        mode = "login";
    };

    const forgotPassword = () => {
        mode = "forgot";
    };
</script>


<div>
    <div>
        <div style="background-color:#11203a;" class="row">
            <div id="mainAuthContainer">
                <div id="authContainer">
                    <img src="static/images/GenomLogo_large.png" height="auto" width="40%" alt="GenomOncology Logo">
                </div>
                <div id="childAuthHolder">
                    <div>
                        <div class="meta">
                            <span class="white-text">Welcome to the <b>Precision Decision</b></span>
                        </div>

                        <div id="authContent" class="ui top attached stacked segment">
                            {#if mode === "login"}
                                <LoginForm on:forgotPassword={forgotPassword}/>
                            {:else if mode === "token"}
                                <ResetForm {...reset_password_information} on:toLogin={toLogin}/>
                            {:else if mode === "sent"}
                                <SentMessage on:toLogin={toLogin}
                                             on:forgotPassword={forgotPassword}/>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
            <div id="hiveContainer"><img src="static/images/beehive.png" width="100%" alt="abstract hexagon background">
            </div>
        </div>
    </div>
</div>

<style>

    div#authContent {
        padding: 1.5em;
    }

    .white-text {
        color: #fff !important
    }

    .meta{
        font-size:22px;
        font-family: 'Avenir-Light', 'Avenir Light', 'Avenir Book', 'Avenir',serif;
        -webkit-font-smoothing: antialiased;
    }

    div#mainAuthContainer {
        width: 41.66667%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

    div#authContainer {
        width: 100%;
        margin-left: auto;
        left: auto;
        right: auto;
        margin-bottom: 100px;
        padding-left: 50px;
        margin-top: 20px;
    }

    div#childAuthHolder {
        width: 66.66667%;
        left: auto;
        right: auto;
        margin-left: 16.66667%
    }

    div#hiveContainer {
        width: 58.33333%;
        margin-left: auto;
        left: auto;
        right: auto;
    }

</style>
