<script>
    import {onMount} from 'svelte';
    import Loader from "../shared/Loader.svelte";
    import {getAlertConfigs} from "../../network/user";
    import {getAllPatientTrackingStatuses} from "../../network/patient_search";
    import {getUser} from "../../store/session";
    import PatientActionTable from "./PatientActionTable.svelte";
    import Modal from "../Modal.svelte";

    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = '';
    let isLoading = false;
    let alert_configs = [];
    let alert_configs_summary = {};
    let patient_tracking_statuses = [];
    let page = 'no track';
    let refreshTable = false;
    let ordering = 'num_not_tracked';
    let patientExportModal;
    let alertConfigToExport;
    let export_all_configs_summary = {};
    let export_all_configs = [];
    let isTrackView = true;
    let config_id = '';
    let filter = '';

    async function fetchInfo() {
        isLoading = true;
        alert_configs_summary = await getAlertConfigs(getUser().id, currentPage, selectedPageSize, "", ordering, "not_tracked", true);
        allCount = alert_configs_summary.hits;
        alert_configs = alert_configs_summary.results;
        export_all_configs_summary = await getAlertConfigs(getUser().id, 1, 100000, "", ordering, "not_tracked", true);
        export_all_configs = export_all_configs_summary.results;
        let patient_tracking_statuses_summary = await getAllPatientTrackingStatuses();
        patient_tracking_statuses = patient_tracking_statuses_summary.results;
        isLoading = false;
    }

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await fetchInfo();
    }

    async function changePage(page) {
        currentPage = page;
        await fetchInfo();
    }

    function getNumberNotTracked(id) {
        let tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === id && !item.tracked);
        return tracking_statuses.length;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function formatTime(datetimeString) {
        let date = new Date(datetimeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    function openPatientTable(id) {
        let track_statuses = patient_tracking_statuses.filter(item => item.alert_config === id && !item.tracked && !item.needs_more_review);
        if (track_statuses.length > 0) {
            config_id = id;
            filter = "not_tracked";
            isTrackView = false;
        }
    }

    function sortAlerts(property) {
        console.log(property);
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchInfo();
    }

    const onPatientExport = async () => {
        let statuses;
        if (alertConfigToExport !== '') {
            statuses = patient_tracking_statuses.filter(item => item.alert_config === alertConfigToExport.id && !item.tracked && !item.needs_more_review);
        } else {
            statuses = patient_tracking_statuses.filter(item => !item.tracked && !item.needs_more_review);
        }
        await exportToCSV(statuses);
        patientExportModal.close();
    };

    async function exportToCSV(statuses) {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent(statuses))}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = "not_tracked_patients.csv";
        link.click();
    }

    function generateCSVContent(statuses) {
        // Define the headers for the CSV
        let headers = [
            "Alert Name",
            "Patient Name",
            "MRN",
            "Matching Parameters",
            "Last Data Update",
            "Not Tracked Details",
            "Comments"
        ];

        // Convert each patient's lab results into CSV rows
        const rows = [];
        statuses.forEach((s) => {
            let config = getAlertConfig(s.alert_config);
            let alert_name = config.name;
            let patient_name = s.patient_name;
            let patient_mrn = s.patient_mrn;
            let matching_parameters = formatParameters(s.matched_diseases, s.matched_alterations, s.matched_genes);
            let last_data_update = s.patient_cdw_associated_date
            let not_tracked_reasons = s.tracked_user_name + " | " + formatDate(s.created) + " | " + s.not_tracking_reasons.replaceAll(",", ", ");
            let comments = getComments(s.patient, config.track_comments);
            let row_info = [
                alert_name || "",
                patient_name || "",
                patient_mrn || "",
                matching_parameters || "",
                last_data_update || "",
                not_tracked_reasons || "",
                comments || ""];
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    function openExportModal(alert) {
        alertConfigToExport = alert;
        let statuses = patient_tracking_statuses.filter(item => item.alert_config === alertConfigToExport.id && !item.tracked && !item.needs_more_review);
        if (statuses.length > 0) {
            patientExportModal.open();
        }
    }

    function openAllExportModal() {
        alertConfigToExport = '';
        let statuses = patient_tracking_statuses.filter(item => !item.tracked && !item.needs_more_review);
        if (statuses.length > 0) {
            patientExportModal.open();
        }
    }

    function getComments(id, export_comments) {
        let patient_comments = export_comments.filter(comment => comment.patient === id);
        patient_comments = patient_comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        let string = "";
        patient_comments.forEach((c) => {
            if (string !== "") {
                string = string + ", " + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")";
            } else {
                string = string + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")";
            }
        });
        return string;
    }

    function formatParameters(diseases, alts, genes) {
        let finalString = "";
        if (diseases && diseases.length !== 0) {
            finalString = finalString + "Diseases: " + diseases.join(", ");
        }
        if (alts && alts.length !== 0) {
            if (finalString !== "") {
                finalString = finalString + ", Alterations: " + alts.join(", ");
            } else {
                finalString = finalString + "Alterations: " + alts.join(", ");
            }
        }
        if (genes && genes.length !== 0) {
            if (finalString !== "") {
                finalString = finalString + ", Genes: " + genes.join(", ");
            } else {
                finalString = finalString + "Genes: " + genes.join(", ");
            }
        }
        return finalString;
    }

    function getAlertConfig(id) {
        return export_all_configs.find(item => item.id === id);
    }

    onMount(() => {
        fetchInfo();
    });

    $: {
        if (refreshTable) {
            fetchInfo();
            refreshTable = false;
        }
    }

    $: totalPages = Math.ceil(allCount / selectedPageSize);
</script>
<style>
    .container {
        height: calc(80vh - 51px);
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    thead th {
        position: sticky;
        top: 0;
    }
    th {
        background-color: #233C6B;
        color: #ffffff;
    }
    tr {
        background-color: white;
    }
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }
    .summary {
        height: 60vh;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 94%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
    }
    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px;
    }
    .pagination button {
        margin-left: 0.5em;
    }
    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }
    .scrollable-table {
        overflow-y: auto;
        max-height: calc(62vh - 4em);
        border-radius: 10px;
    }
    .export-icon-png {
        width: 50px;
        cursor: pointer;
    }
    .icon-png-large {
        width: 40px;
        cursor: pointer;
    }

</style>
<div class="container">
    {#if isTrackView}
        <div class="summary">
            <div class="pagination">
                <div style="font-size: 20px"> <b>Export All:</b> </div>
                <div on:click={() => openAllExportModal()} aria-hidden="true">
                    <div title="Export" style="margin-right: 50px">
                        <img src="static/images/exportCurrentResultsIcon.png"
                             class="export-icon-png" alt=""></div>
                </div>
                <label for="page-size">Page Size:</label>
                <select id="page-size" on:change={changePageSize}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="{allCount}">All</option>
                </select>
                {#if !isLoading && alert_configs.length > 0}
                    <div class="pagination">
                        {#if alert_configs_summary.pagination.previous}
                            <button class="next-button" on:click={() => changePage(currentPage - 1)}>Previous</button>
                        {/if}
                        <span class="pages-span">
                                Page {currentPage} of {totalPages}
                            </span>
                        {#if alert_configs_summary.pagination.next}
                            <button class="next-button" on:click={() => changePage(currentPage + 1)}>Next</button>
                        {/if}
                    </div>
                {/if}
            </div>
            <div class="scrollable-table">
                {#if isLoading}
                    <Loader/>
                {:else}
                    <table>
                        <thead>
                        <tr>
                            <th on:click={() => sortAlerts('name')}>
                                Name
                                {#if ordering === 'name'}
                                    &#9650; <!-- Upward arrow -->
                                {:else if ordering === '-name'}
                                    &#9660; <!-- Downward arrow -->
                                {/if}
                            </th>
                            <th on:click={() => sortAlerts('last_run_date')}>
                                Most Recent Notification Date
                                {#if ordering === 'last_run_date'}
                                    &#9650; <!-- Upward arrow -->
                                {:else if ordering === '-last_run_date'}
                                    &#9660; <!-- Downward arrow -->
                                {/if}
                            </th>
                            <th on:click={() => sortAlerts('num_not_tracked')}>
                                Number of Not Tracked Patients
                                {#if ordering === 'num_not_tracked'}
                                    &#9650; <!-- Upward arrow -->
                                {:else if ordering === '-num_not_tracked'}
                                    &#9660; <!-- Downward arrow -->
                                {/if}
                            </th>
                            <th>View</th>
                            <th>Export</th>
                        </tr>
                        </thead>
                        <tbody>
                        {#each alert_configs as alert}
                            <tr>
                                <td>{alert.name}</td>
                                <td>{formatDate(alert.last_run_date)}</td>
                                <td>{getNumberNotTracked(alert.id)}</td>
                                <td>
                                    <div on:click={() => openPatientTable(alert.id)}
                                         aria-hidden="true" style="margin-right: 30px">
                                         <span title="View">
                                             <img src="static/images/viewCurrentResultsIcon.png"
                                                  class="icon-png-large" alt="">
                                         </span>
                                    </div>
                                </td>
                                <td on:click={() => openExportModal(alert)}>
                                    <div title="Export">
                                        <img src="static/images/exportCurrentResultsIcon.png"
                                             class="export-icon-png" alt=""></div>
                                </td>
                            </tr>
                        {/each}
                        </tbody>
                    </table>
                {/if}
            </div>
        </div>
    {:else}
        <div class="table">
            <PatientActionTable bind:isTrackView={isTrackView} bind:refreshTable={refreshTable} {page} {config_id} {filter}/>
        </div>
    {/if}
</div>

<!--Patient Export Modal-->
<form on:submit|preventDefault={onPatientExport}>
    <Modal bind:modalFunc={patientExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export patients?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => patientExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>