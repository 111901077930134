<script>
    import queryItem from "../../utils/queryItem";
    import {add} from "../../store/currentAlertQuery";
    import {RADIO_ALL} from "../../utils/constants";
    import PlusIcon from '../../icons/plus-sign.svg';
    import SVGButton from "../SVGButton.svelte";

    //Field to query on in solr
    export let solrField;
    //How this filter will be named in the UI
    export let displayName;
    //ALL - ANY - NONE query option
    export let filterMode = RADIO_ALL;
    export let core;
    //Fetched Values
    export let allValues;
    export let queryLabel = "genomicMarkerCharacteristics";


    let textValue = '';
    const addFilter = () => {
        const qi = queryItem(solrField,
            textValue,
            filterMode,
            core,
            `${displayName}: ${textValue}`);
        add(queryLabel, qi)();
        textValue = '';
    };


</script>

<input type="text" list="`{solrField}-list`" class="value-input" bind:value={textValue}>
<datalist id="`{solrField}-list`">
    {#each allValues as value}
        <option>{ value }</option>
    {/each}
</datalist>
<SVGButton onClick={addFilter} svg={PlusIcon}/>