<script>

    import SVGButton from "../SVGButton.svelte";
    import PlusIcon from '../../icons/plus-sign.svg';
    import DoubleRangeSlider from '../DoubleRangeSlider.svelte';
    import {RADIO_ALL} from "../../utils/constants";
    import {add} from "../../store/currentAlertQuery";
    import queryItem from "../../utils/queryItem";

    export let startValue = 0;
    export let endValue = 100;
    //Field to query on in solr
    export let solrField;
    //How this filter will be named in the UI
    export let displayName;
    //ALL - ANY - NONE query option
    export let filterMode = RADIO_ALL;
    export let core;
    //Fetched Values
    export let queryLabel;
    export let is_age_field = false;


    const addFilter = () => {
        let value_range;
        if (is_age_field) {
            value_range = [startValue * 12, endValue * 12];
        } else {
            value_range = [startValue, endValue];
        }
        let qi = queryItem(
            solrField,
            value_range,
            filterMode,
            core,
            `${displayName}: ${startValue} - ${endValue}`,
            "range"
        );

        add(queryLabel, qi)();
    };

</script>

<div class="accordion-content">
    <div class="range-slide-labels row space-around align-items-center">
        <span>&leq;</span>
        <input class="age-input" type="number" bind:value={startValue}>
        <span>to</span>
        <input class="age-input" type="number" bind:value={endValue}>
        <span>&geq;</span>
        <SVGButton svg={PlusIcon} onClick={addFilter} />
    </div>
    <DoubleRangeSlider bind:startVal={startValue} bind:endVal={endValue} max="100" min="0" />
</div>

<style>

    .accordion-content {
        padding: 1em;
    }

    .range-slide-labels span {
        font-size: 1.5em;
    }

    input {
        width: 80%;
        padding: 8px 12px;
    }

</style>