import {callApi} from "../rest.js";
import DynamicCharacteristics from "../../components/DynamicCharacteristics.svelte";
import {addProps} from "../../store/displayNames";
import {currentQuery, queryKeys} from "../../store/currentAlertQuery";

const ROOT_URL = '/analytics/facets';


/**
 * fetch a list of possible facets
 * @param facetType: which facet type to fetch
 * @param [core='cases'] {String}: which core to query, optional, default is 'cases'
 * @returns {Promise<Response|any>}
 */
export async function getFacets(facetType, core = 'cases') {
    const url = `${ROOT_URL}/${facetType}?core=${core}`;
    return await callApi(url, "GET");
}

export async function getFacetsStartingWith(facetType, prefix, core = 'cases') {
    const url = `${ROOT_URL}/${facetType}?core=${core}&prefix=${prefix}`;
    return await callApi(url, "GET");
}

export async function getFacetConfig() {
    // Returns a list of objects, sort of like
    // [{
    //   "filters": [
    //     {
    //       "displayName": "Disease",
    //       "solrField": "specific_diseases",
    //       "displayType": "facet field",
    //       "filterInput": "type ahead",
    //     },
    //   ],
    //   "filterCategory": "Patient Values"
    // }]

    const response =  await callApi(`${ROOT_URL}/facet-config`, "GET");
    if (response.ok) {
        const body = await response.json();
        for (let category of body) {
            category["filters"] = category["filters"].filter(f => {
                return f["alertsOption"];
            });
        }
        return body;
    }
    else {
        console.error("Could not get facet config");
    }
}

export function build_export_filters(configured_facets) {
    // Returns a data structure that makes it easy to make components.
    // The returned data structure looks kind of like:
    //
    //     [
    //         {
    //             "catComponent": class DynamicCharacteristics,
    //             "componentProps": {
    //                 "categoryLabel": "Specimen Characteristics",
    //                 "filters": [
    //                     {
    //                         "displayName": "Cellularity",
    //                         "solrField": "case__Cellularity__string",
    //                         "displayType": "facet field",
    //                         "filterInput": "term or range",
    //                     },
    //                     {
    //                         "displayName": "Necrosis",
    //                         "solrField": "case__Necrosis__string",
    //                         "displayType": "facet field",
    //                         "filterInput": "term or range",
    //                     }
    //                 ],
    //                 "filter_data_values": ["case__Cellularity__string", "case__Necrosis__string"],
    //             }
    //         }
    // ]
    let x = configured_facets.map(function (cf) {
        let filter_data_values = cf["filters"].map(function (f) {
            return f["solrField"];
        });

        return {
            catComponent: DynamicCharacteristics,
            componentProps: {
                categoryLabel: cf["filterCategory"],
                filters: cf['filters'],
                filter_data_values: filter_data_values,
            }

        };
    });
    return x;
}

export function update_display_names (export_filters) {
    export_filters.map(function (ef) {
        ef.componentProps["filters"].map(function (f) {
            let section = ef.componentProps.categoryLabel;
            let fdv = f["solrField"]; // e.g. biomarkers
            let label = f["displayName"]; // "genes"
            let core = f["core"];
            let props = {};
            props[label] = fdv;
            addProps(section, props, core);
        });
    });
}

export function build_export_filters_and_update_display_names(configured_facets, should_update_query) {
    let export_filters = build_export_filters(configured_facets);
    update_display_names(export_filters);
    if (should_update_query) {
        update_current_query_with_configured_facets(configured_facets);
    }
    return export_filters;
}

export function update_current_query_with_configured_facets(configured_facets) {
    currentQuery.update(Q => {
        for (let filterCategory of configured_facets) {
            Q[filterCategory["filterCategory"]] = [];
        }
        return Q;
    });
    let newKeys = [];
    for (let filterCategory of configured_facets) {
        newKeys.push(filterCategory["filterCategory"]);
    }
    queryKeys.set(newKeys);
}

export const getDiseaseConfig = async (display_names_only, icd_codes_only) => {
    let url = `${ROOT_URL}/disease-config?page=1`;
    if (display_names_only !== null) {
        url = `${url}&display_names_only=${display_names_only}`;
    }
    if (icd_codes_only !== null) {
        url = `${url}&icd_codes_only=${icd_codes_only}`;
    }
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get disease config");
    }
};