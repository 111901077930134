import {toast} from "@zerodevx/svelte-toast";

export function pushSuccess(message) {
    toast.push(message, {
        theme: {
            '--toastBackground': '#48BB78',
            '--toastProgressBackground': '#2F855A',
        }
    });
}


export function pushFail(message) {
    toast.push(message, {
        theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030',
        }
    });
}

export function clearToast() {
    toast.pop();
}

export function pushOnCondition(condition, message) {
    if (condition) {
        message += ": Success";
        pushSuccess(message);
    } else {
        message += ": Failed";
        pushFail(message);
    }
}