<script>
    import {onMount} from "svelte";
    import Modal from "../Modal.svelte";
    import {patientInfoOptions, setDefaultOptions} from "../../store/patientInfoOptions";
    import {getCasesWithUuids} from "../../network/cases";
    import {CASE_FIELDS} from "../../utils/constants";
    import {
        additionalDetailKey,
        additionalDetailsPaneContent,
        additionalDetailsPaneWidth, mainPaneWidth
    } from "../../store/notificationNavigation";

    let caseExportModal;


    export let notifications;
    export let selectedNotification;
    export let uuids;
    export let name;
    export let date;
    export let id;
    export let isNotificationView;


    let tableData = [];
    let exportData = [];
    let ordering = '';

    // Simulate fetching data from an API
    const fetchData = async () => {
        tableData = notifications.filter(o => o.name === selectedNotification.name && o !== selectedNotification);
        sortOlderNotifications();
    };

    patientInfoOptions.subscribe(value => {
        exportData = value;
    });

    async function openCaseTable(notification) {
        if (notification.case_uuids.split(",")[0] !== '') {
            additionalDetailKey.set('');
            additionalDetailsPaneContent.set('');
            additionalDetailsPaneWidth.set('0');
            mainPaneWidth.set('100%');
            setDefaultOptions();
            uuids = notification.case_uuids;
            date = formatDate(notification.created);
            id = notification.id;
            name = notification.name;
            isNotificationView = false;
        }
    }

    async function openCaseExportModal(notification) {
        if (notification.case_uuids.split(",")[0] !== '') {
            setDefaultOptions();
            uuids = notification.case_uuids;
            caseExportModal.open();
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function getMatchingParameters(c) {
        if (c.alert_triggers.length !== 0) {
            let trigger = c.alert_triggers.find(item => item.alert_id === id);
            if (trigger !== undefined) {
                let finalString = "";
                if (trigger.disease_triggers !== "[]") {
                    finalString = finalString + "<b>Diseases:</b> " +
                        trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.alteration_triggers !== "[]") {
                    finalString = finalString + "<br><b>Alterations:</b> " +
                        trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.gene_triggers !== "[]") {
                    finalString = finalString + "<br><b>Genes:</b> " +
                        trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                return finalString;
            }
        }
        return "";
    }

    function getInfoFromCase(c, field) {
        let value = 'N/A';
        if (field in c) {
            value = c[field];
        } else if (c['meta'] !== null && field in c['meta']) {
            value = c['meta'][field];
        } else if (c['patient_information'] !== null && field in c['patient_information'][0]) {
            value = c['patient_information'][0][field];
        }

        if (Array.isArray(value)) {
            if (value.every(value => value === null)) {
                return "N/A";
            } else if (field === 'oncologic_treatments') {
                let drugs = []
                value.forEach((treatment) => {
                    if (!drugs.find(item => item.drugs === treatment.drugs)) {
                        drugs = [...drugs, treatment.drugs];
                    }
                });
                return Array.from(new Set(drugs)).join(", ");
            } else {
                return Array.from(new Set(value)).join(", ");
            }
        } else {
            if (value === null) {
                return "N/A";
            } else {
                return value;
            }
        }
    }

    const onCaseExport = async () => {
        let response = await getCasesWithUuids(CASE_FIELDS, uuids, 1, 1000);
        let results = response.results;
        await exportToCSV(results);
        caseExportModal.close();
    };

    function generateCSVContent(data) {
        // Define the headers for the CSV
        let headers = [
            "Full Name",
            "Patient MRN",
            "Ordering Physician",
            "Assay Names",
            "Lab Reported Dates",
            "Matching Parameters"
        ];
        exportData.forEach((option) => {
            if (option.visible) {
                headers = [...headers, option.name];
            }
        });

        // Convert each patient's lab results into CSV rows
        const rows = [];
        data.forEach((c) => {
            let full_name = c.patient_name;
            let patient_mrn = c.patient_mrn;
            let ordering_physician = c.patient_information[0].ordering_physician;
            let assay_names = c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let reported_dates = Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let matching_parameters = getMatchingParameters(c);
            let row_info = [
                full_name || "",
                patient_mrn || "",
                ordering_physician || "",
                assay_names || "",
                reported_dates || "",
                matching_parameters || ""];
            exportData.forEach((option) => {
                if (option.visible) {
                    row_info = [...row_info, getInfoFromCase(c, option.field)];
                }
            });
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    async function exportToCSV(data) {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent(data))}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = "cases.csv";
        link.click();
    }

    function getNumCases(case_uuids) {
        if (case_uuids.split(",")[0] !== '') {
            return case_uuids.split(",").length;
        } else {
            return 0;
        }
    }

    function sortNotifications(property) {
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchData();
    }

    function sortOlderNotifications() {
        if (ordering !== '') {
            if (ordering === 'date') {
                tableData.sort((a, b) => new Date(b.created) - new Date(a.created));
            } else if (ordering === '-date') {
                tableData.sort((a, b) => new Date(a.created) - new Date(b.created));
            } else if (ordering === 'num_cases') {
                tableData.sort((a, b) => getNumCases(a.case_uuids) - getNumCases(b.case_uuids));
            } else if (ordering === '-num_cases') {
                tableData.sort((a, b) => getNumCases(b.case_uuids) - getNumCases(a.case_uuids));
            }
        } else {
            tableData.sort((a, b) => new Date(b.created) - new Date(a.created));
        }
    }

    onMount(() => {
        fetchData();
    });

</script>
<style>
    .container {
        display: flex;
        border-collapse: collapse;
        /*border: 1px solid black;*/
        /*border-radius: 10px;*/
    }
    table {
        width: 100%;
    }
    td, th {
        padding: 12px;
        text-align: center;
        border-bottom: 1px solid #ddd;
    }
    th {
        color: black;
    }
    tr {
        background-color: white;
    }
    tr:last-child td, tr:last-child th {
        border-bottom: none; /* Remove bottom border for the last row */
    }
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }
    .icon-container {
        display: inline-flex;
        align-items: center;
        background-color: white;
    }

    .icon-png {
        width: 25px;
        cursor: pointer;
    }

    .export-icon-png {
        width: 30px;
        cursor: pointer;
    }

</style>
<div class="container">
    <table>
        <thead>
        <tr>
            <th on:click={() => sortNotifications('date')}>
                Notification Date
                {#if ordering === 'date'}
                    &#9650; <!-- Upward arrow -->
                {:else if ordering === '-date'}
                    &#9660; <!-- Downward arrow -->
                {/if}
            </th>
            <th on:click={() => sortNotifications('num_cases')}>
                Number of Cases
                {#if ordering === 'num_cases'}
                    &#9650; <!-- Upward arrow -->
                {:else if ordering === '-num_cases'}
                    &#9660; <!-- Downward arrow -->
                {/if}
            </th>
            <th style="width: 40%"> Queue Status </th>
            <th> View / Export Cases</th>
        </tr>
        </thead>
        <tbody>
        {#each tableData as olderObj}
            <tr>
                <td> {formatDate(olderObj.created)} </td>
                <td>
                    {#if olderObj.case_uuids.split(",")[0] !== ''}
                        {olderObj.case_uuids.split(",").length}
                    {:else}
                        {0}
                    {/if}
                </td>
                <td>
                    {#if olderObj.queue_status.total_statuses !== 0}
                        <div>{olderObj.queue_status.total_statuses_decided}/{olderObj.queue_status.total_statuses} Reviewed</div>
                    {/if}
                    {#if olderObj.queue_status.total_statuses_needs_more_review !== 0}
                        <div>{olderObj.queue_status.total_statuses_needs_more_review} Needs More Review</div>
                    {/if}

                </td>
                <td>
                    <div class="icon-container">
                        <div on:click={() => openCaseTable(olderObj)} aria-hidden="true" style="margin-right: 30px">
                            <img src="static/images/viewCurrentResultsIcon.png"
                                 class="icon-png" alt="">
                        </div>
                        <div on:click={() => openCaseExportModal(olderObj)} aria-hidden="true">
                            <img src="static/images/exportCurrentResultsIcon.png"
                                 class="export-icon-png" alt="">
                        </div>
                    </div>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>

<!--Case Export Modal-->
<form on:submit|preventDefault={onCaseExport}>
    <Modal bind:modalFunc={caseExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export cases?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => caseExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>
