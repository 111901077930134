<script>

    import SVGButton from "../SVGButton.svelte";
    import PlusIcon from '../../icons/plus-sign.svg';
    import {RADIO_ALL} from "../../utils/constants";
    import {add} from "../../store/currentAlertQuery";
    import queryItem from "../../utils/queryItem";

    //Field to query on in solr
    export let solrField;
    //How this filter will be named in the UI
    export let displayName;
    //ALL - ANY - NONE query option
    export let filterMode = RADIO_ALL;
    export let core;
    //All possible values for radio buttons
    export let allValues;
    export let queryLabel = '';


    let radio_selection = '';
    const addFilter = () => {
        add(queryLabel, queryItem(solrField, radio_selection, filterMode, core, `${displayName}: ${radio_selection}`))();
        radio_selection = '';
    };

</script>


<div class="row space-around align-items-center">
    {#each allValues as value}
        <label for="`{value}-radio`">
            <input id="`{value}-radio`" type="radio" bind:group={radio_selection} name={solrField} value={value}>
            {value}
        </label>
    {/each}
    <SVGButton onClick={addFilter} svg={PlusIcon} />
</div>

<style>
    input {
        width: 80%;
        padding: 8px 12px;
    }
</style>