<script>
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
    import PatientMatchHeader from "../components/patient_match/PatientMatchHeader.svelte";
    import PatientMatchTable from "../components/patient_match/PatientMatchTable.svelte";

</script>

<div>
    <div class="content-wrapper">
        <PageHeader/>
        <AppLayout subHeader={PatientMatchHeader} mainContent="{PatientMatchTable}" leftPaneSize="10%" rightPaneSize="90%"/>
    </div>

</div>

<style>
    .content-wrapper{
        height:95vh;
        overflow-x: scroll;
    }
</style>


