<script>
    import {login, register2FA, verify2FA} from "../../network/user";
    import {session, getUser} from "../../store/session";
    import AppModal from "../shared/AppModal.svelte";
    import ForgotForm from "./ForgotForm.svelte";

    let errorMessage = false;
    let is2FANeeded = false;
    let is2FARegistrationNeeded = false;
    let isForgotPasswordOpen = false;
    let isMessageModalOpen = false;
    let isTwoFactorModalOpen = false;

    let email2FA = '';
    let phonenumber2FA = '';
    let verificationToken = '';

    const onSubmit = async (e) => {
        const response = await login(
            e.target.elements['username'].value,
            e.target.elements['password'].value,
        );
        const body = await response.json();
        if (response.ok) {
            session.set(body);
            if (!body.is_2FA_registered) {
                is2FARegistrationNeeded = true;
            } else if (!body.token) {
                is2FANeeded = true;
            }
        } else if (body.non_field_errors) {
            errorMessage = body.non_field_errors;
            errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
        }

        return {
            errorMessage,
            is2FANeeded
        };
    };

    const onRegister2FA = async () => {
        is2FARegistrationNeeded = false;
        const response = await register2FA(
            getUser().id,
            email2FA,
            phonenumber2FA
        );
        if (response.ok) {
            close2FAModal();
            is2FANeeded = true;
        } else {
            errorMessage = 'Failed to register two factor authentication.';
        }
    };

    const onVerify2FA = async () => {
        const response = await verify2FA(
            getUser().id,
            verificationToken,
        );
        const body = await response.json();
        if (response.ok) {
            session.set(body);
        } else {
            errorMessage = body?.message;
        }
    };

    const open2FAModal = () => {
        isTwoFactorModalOpen = true;
    };

    const close2FAModal = () => {
        isTwoFactorModalOpen = false;
    };

    $: if (is2FARegistrationNeeded) {
        open2FAModal();
    }

    const openForgotModal = () => {
        isForgotPasswordOpen = true;
    };

    const closeForgotModal = () => {
        isForgotPasswordOpen = false;
    };

    const closeForgotModalWithSubmit = () => {
        isForgotPasswordOpen = false;
        isMessageModalOpen = true;
    };

    const closeMessageModal = () => {
        isMessageModalOpen = false;
    };
</script>

<form on:submit|preventDefault={is2FANeeded ? onVerify2FA : onSubmit} class="ui large form">
    <div class="field">
        <div>
            <input class="input" required="required" type="text" name="username" placeholder="Username">
        </div>
    </div>
    <div class="field">
        <div>
            <input class="input" required="required" type="password" name="password" placeholder="Password">
        </div>
        <div id="forgot">
            <a style="cursor: pointer" href="{null}" on:click={() => openForgotModal()}>Forgot Password?</a>
        </div>
    </div>

    <div class="field">
        <div>
            {#if is2FANeeded }
                <input class="input" type="text" name="sms_token" placeholder="2FA Code" bind:value={verificationToken}>
            {:else}
                <input type="hidden" name="sms_token" value=""/>
            {/if}
        </div>
    </div>

    <div>
        <button class="button white" type="submit">
            Login
        </button>
    </div>
    {#if errorMessage }
        <div class="white">{errorMessage}</div>
    {:else}
        <div>&nbsp;</div>
    {/if}

    <AppModal isOpen={isForgotPasswordOpen} width={"400px"} on:close={closeForgotModal}>
        <ForgotForm {closeForgotModalWithSubmit} />
    </AppModal>

    <AppModal isOpen={isTwoFactorModalOpen} width={"500px"} on:close={close2FAModal}>
        <div class="contents">
            <div>
                <div>
                    <h1>Two-Factor Authentication Registration</h1>
                </div>
            </div>
            <div>
                <p>Please re-enter your email address and a mobile number in order to receive an authentication code.</p>
                <div class="field">
                    <input class="input" type="email" name="email2FA" placeholder="Email" bind:value={email2FA}>
                </div>
                <div class="field">
                    <input class="input" type="text" name="phonenumber2FA" placeholder="Phone Number" bind:value={phonenumber2FA}>
                </div>
            </div>
            <div>
                <div>
                    <button class="modalButton" on:click={() => close2FAModal()}>Cancel</button>
                    <button class="modalButton" on:click={onRegister2FA}>Submit</button>
                </div>
            </div>
        </div>
    </AppModal>

    <AppModal isOpen={isMessageModalOpen} width={"500px"} on:close={closeMessageModal}>
        <div class="contents">
            <div>
                <p>An email has been sent to the email entered with further instructions!</p>
            </div>
            <div>
                <div>
                    <button class="modalButton white" on:click={() => closeMessageModal()}>Okay</button>
                </div>
            </div>
        </div>
    </AppModal>
</form>

<style>
    div#forgot {
        text-align: right;
    }

    div#forgot a {
        color: #5da3d9;
        font-size: .8em;
    }

    .button:hover{
        background-color: #3a63b0;
    }

    .button{
        background-color: #5da3d9;
        width: 100%;
        border-radius: 5px;
    }

    .modalButton{
        background-color: #5da3d9;
        width: 30%;
        border-radius: 5px;
    }

    .input{
        width: 100%;
        border-radius: 5px;
        padding-left: 10px;
    }

    .field{
        padding-bottom: 1em;
    }

    .form{
        padding: 6%;
        background-color: #243c6a;
        border-radius: 10px;
    }

    #forgot{
        padding-bottom: 5px;
    }

    .white{
        color: white;
        -webkit-font-smoothing: antialiased;
    }

</style>
