/**
 * converts a queryItem into a makePayloadItem
 * @param queryItem {Object}: an object with the keys: value, icon, core, queryKey, and display
 * @returns { {field: String, operator: ['All'|'Any'|'Some'], type: ['range'|'term'], values: [{value}]} }
 */
export default function makeQueryPayloadItem(queryItem) {
    const payloadItem = {
        field: queryItem.queryKey,
        operator: queryItem.icon,
        type: queryItem.keyType,
        core: queryItem.core,
        display: queryItem.display
    };
    if (Array.isArray(queryItem.value)) {
        payloadItem.values = queryItem.value.map((v) => ({value: v}));
    } else {
        payloadItem.values = [
            { value: queryItem.value }
        ];
    }
    return payloadItem;
}