<script>
    import AlertManagementTable from "./AlertManagementTable.svelte";
    import PausedAlertTable from "./PausedAlertTable.svelte";
    import ArchivedAlertTable from "./ArchivedAlertTable.svelte";

    export let activeTab;
</script>

{#if activeTab === 'tab1'}
    <AlertManagementTable/>
{:else if activeTab === 'tab2'}
    <PausedAlertTable/>
{:else if activeTab === 'tab3'}
    <ArchivedAlertTable/>
{/if}