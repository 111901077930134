<script>
    import {patientInfoOptions, unselectAllOptions, selectAllOptions, setDefaultOptions} from '../../store/patientInfoOptions.js';


</script>

<div>
    <h2>Additional Fields</h2>
    {#each $patientInfoOptions as option}
        <label style="text-align: left; margin-left: 35px">
            <input type="checkbox" bind:checked={option.visible}/>
            {option.name}
        </label>
        <br>
    {/each}
    <button style="background-color: rgba(144, 195, 76, 1); padding: 0.5rem 1rem;" on:click={() => selectAllOptions()}>Select All</button>
    <button style="background-color: lightcoral; padding: 0.5rem 1rem;" on:click={() => unselectAllOptions()}>Unselect All</button>
    <button style="background-color: lightgray; padding: 0.5rem 1rem; margin-top: 10px;" on:click={() => setDefaultOptions()}>Reset</button>
</div>