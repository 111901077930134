<script>
    export let svg;
    export let disabled = false;
    export let onClick = () => {
        console.log('onClick for SVGButton is unimplemented');
    };
</script>

<button class="svg-button"
        on:click={onClick}
        disabled='{disabled}'
>
    <img alt="svg-button" src={svg}>
</button>

<style>
    .svg-button {
        border-width: 0;
        background-color: transparent;
        background-repeat:no-repeat;
        background-position: center center;
        padding: 0;
        margin: 0;
    }
    .svg-button[disabled] {
        filter: grayscale(0.5) opacity(40%);
    }
</style>