<script>
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
    import NotificationsHeader from "../components/notifications/NotificationsHeader.svelte";
    import NotificationTable from "../components/notifications/NotificationTable.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{NotificationsHeader}" mainContent={NotificationTable}/>
</div>


