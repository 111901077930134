<script>
    import AllRadioIcon from '../icons/all-radio-icon.svg';
    import AnyRadioIcon from '../icons/any-radio-icon.svg';
    import NoneRadioIcon from '../icons/none-radio-icon.svg';
</script>

<img src={AllRadioIcon} alt=""/>
<div class="key-label">All</div>
<img src={AnyRadioIcon} alt=""/>
<div class="key-label">Any</div>
<img src={NoneRadioIcon} alt=""/>
<div class="key-label last">None</div>

<style>
    .key-label {
        margin-left: 2px;
        margin-right: 4px;
        font-size: 0.8em;
    }
    .last {
        margin-right: 1em;
    }
</style>