import {get as getStore, writable} from "svelte/store";

/**
 * Stores for pagination: currentPage, pageSize, and sortBy
 */

export const currentPage = writable(1);
export const pageSize = writable(20);
export const sortBy = writable(null);

// categories corresponding to accordion sections in queryFilter
export const queryKeys = writable(...[]);
export const currentQuery = writable({...{}});

/** reset all values in currentQuery
 */
export const clearQuery = () => {
    let currentKeys = [];
    queryKeys.subscribe((val) => {
        currentKeys = val;
    });
    currentQuery.update((Q) => {
        for (let key of currentKeys) {
            Q[key] = [];
        }
        return Q;
    });
    currentPage.set(1);
    pageSize.set(20);
    sortBy.set(null);
};

/**
 * receive the value of the store and add {key: val} to it
 * @param Q {Object}
 * @param key {String}
 * @param val {QueryItem}
 */
function addQueryItemToStore(Q, key, val) {
    // if Q[key] and val is an array concat val to Q[key]
    if (Q[key] && Array.isArray(Q[key]) && Array.isArray(val)) {
        Q[key] = Q[key].concat(val);
        // if Q[key] is an array and val is a single queryItem append val to Q[key]
    } else if (Q[key] && Array.isArray(Q[key])) {
        Q[key] = Q[key].concat([val]);
    } else {
        Q[key] = val;
    }
}

/** add values to the currentQuery
 * @param {String} key: key to store the values under in currentQuery
 * @param {Object|[Object]} val: queryItems to add the the currentQuery, either one of them or an array
 */
export const add = (key, val) => {
    return () => {
        currentQuery.update(Q => {
            addQueryItemToStore(Q, key, val);
            return Q;
        });
    };
};

/** remove values to the currentQuery
 * @param {String} key: key to be removed from currentQuery
 */
export const remove = (key) => {
    return () => {
        currentQuery.update(Q => {
            delete Q[key];
            return Q;
        });
    };
};

/** remove an index from a property of currentQuery that is an array
 * @param {String} key: key for the array in currentQuery
 * @param {Number} index: index of the item to be removed
 */
export const removeIndex = (key, index) => {
    return () => {
        currentQuery.update(Q => {
            const newArr = [...Q[key]];
            newArr.splice(index, 1);
            Q[key] = newArr;
            return Q;
        });
    };
};

/**
 * clears previous values and creates new store from an array of queryItems and keys
 * @param queryItems {Array}: array of query items to be the new currentQuery
 */
export const createCurrentQueryWithItems = (queryItems) => {
    // eslint-disable-next-line no-unused-vars
    clearQuery();
    currentQuery.update((Q) => {
        for (const {sectionKey, queryItem} of queryItems) {
            addQueryItemToStore(Q, sectionKey, queryItem);
        }
        return Q;
    });
};

export function queryCount(cQ, ...keys) {
    let count = 0;
    for (const key of keys) {
        const item = cQ[key];
        if (Array.isArray(item)) {
            count += item.length;
        } else if (item?.value) {
            count += 1;
        }
    }
    return count;
}
