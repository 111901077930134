<script>
    import {searchQueries, totalPatients, matchedPatients} from "../../store/patientSearchQueries";
    import {expandMainHeader} from "../../store/navigation";

    let filterData = {};
    let total = '';
    let matched = '';
    let titleHeight = 0;
    let titleRef;

    $: filterData = $searchQueries;

    $: total = $totalPatients;

    $: matched = $matchedPatients;

    searchQueries.subscribe(value => {
        if (titleRef) {
            setTimeout(() => {
                titleHeight = titleRef.offsetHeight;
                console.log(titleHeight);
                if (titleHeight > 24) {
                    expandMainHeader.set(true);
                } else {
                    expandMainHeader.set(false);
                }
            });
        }
    });

    function getTitle(key) {
        if (key === "full_name") {
            return "Patient Name";
        } else if (key === "patient_mrn") {
            return "MRN";
        } else if (key === "assay_names") {
            return "Test Name";
        } else if (key === "pos_markers") {
            return "Positive Alterations";
        } else if (key === "vus_markers") {
            return "Uncertain Alterations";
        } else if (key === "neg_markers") {
            return "Negative Alterations";
        } else if (key === "diagnosis") {
            return "Diagnosis";
        } else if (key === "date_of_birth") {
            return "Date of Birth";
        } else if (key === "gender") {
            return "Gender";
        } else if (key === "report_id") {
            return "Report ID";
        } else if (key === "ordering_physician") {
            return "Ordering Physician";
        } else if (key === "genes") {
            return "Genes";
        } else if (key === "report_status") {
            return "Report Status";
        } else if (key === "reported_start_date") {
            return "Report Start Date";
        } else if (key === "reported_end_date") {
            return "Report End Date";
        } else if (key === "data_sets") {
            return "Vendor";
        }
    }
</script>

<style>
    .header {
        display: flex;
        text-align: center;
        font-size: 25px;
        vertical-align: top;
        margin-top: 10px;
        height: 60px;
        justify-content: center;
        margin-left: 295px;
    }
    .filters {
        font-size: 20px;
        display: flex;
        align-items: center;
        width: calc(100vw - 130px);
        margin-bottom: 20px;
        vertical-align: center;
        margin-left: 30px;
    }
    .filter-header {
        display: flex;
        font-weight: bold;
        font-size: 20px;
    }
    .filter-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

    }
    .filter-item {
        font-size: 17px;
        margin-right: 10px;
    }
    .filter-key {
        font-weight: bold;
    }
    .center-align {
        flex: 1;
        text-align: center;
    }
    .right-align {
        margin-left: auto;
        font-size: 18px;
        margin-top: 33px;
        margin-right: 80px;
    }
</style>

<div class="full-header">
    <div class="header">
        <h5 class="center-align">Patient Search</h5>
        <div class="right-align">
            <div>
                <span style="font-weight: bold;">Total Patient Count:</span> {total}
            </div>
            {#if matched !== "" && total.toString() !== matched.toString()}
                <div>
                    <span style="font-weight: bold;">Matching Patient Count:</span> {matched}
                </div>
            {/if}
        </div>
    </div>
    <div class="filters">
        <p class="filter-header" bind:this={titleRef}>Applied Filters: </p>
        <div class="filter-items">
            {#each Object.keys(filterData) as key}
                {#if filterData[key] !== '' && filterData[key].length !== 0}
                    <div class="filter-item">
                        <span class="filter-key">{getTitle(key)}:</span> {filterData[key].toString().replaceAll(",", ", ")}
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</div>
