import { writable } from 'svelte/store';

const notificationSearchQueries= {
    full_name: '',
    mrn: '',
    markers: '',
    notification_start_date: '',
    notification_end_date: '',
    ordering_physician: '',
    genes: '',
    data_sets: ''
};

export let searchQueries = writable(notificationSearchQueries);

export let inFilterView = writable(false);