import { writable } from 'svelte/store';

const initialValue = [
    { name: 'Report Diagnosis', field: 'specific_diseases', visible: true },
    { name: 'Stage', field: 'stages', visible: true },
    { name: 'Cancer ICD10 Codes', field: 'ICD10__Cancer__code', visible: true },
    { name: 'Alterations', field: 'significant_alterations', visible: true },
    { name: 'Treatments', field: 'oncologic_treatments', visible: true },
    { name: 'Cancer Type', field: 'Cancer_Type', visible: true },
    { name: 'ECOG Value', field: 'performance_status_value', visible: true },
    { name: 'Uncertain Alterations', field: 'vus_alterations', visible: false },
    { name: 'Age', field: 'age', visible: false },
    { name: 'Gender', field: 'gender', visible: false },
    { name: 'ICD10 Codes', field: 'ICD10__Non__Cancer__code', visible: false },
    { name: 'Vital Status', field: 'vital_status', visible: false },
    { name: 'Race', field: 'race', visible: false },
    { name: 'OrderID', field: 'lab_report_report_ids', visible: false }
];

const selectAll = [
    { name: 'Report Diagnosis', field: 'specific_diseases', visible: true },
    { name: 'Stage', field: 'stages', visible: true },
    { name: 'Cancer ICD10 Codes', field: 'ICD10__Cancer__code', visible: true },
    { name: 'Alterations', field: 'significant_alterations', visible: true },
    { name: 'Treatments', field: 'oncologic_treatments', visible: true },
    { name: 'Cancer Type', field: 'Cancer_Type', visible: true },
    { name: 'ECOG Value', field: 'performance_status_value', visible: true },
    { name: 'Uncertain Alterations', field: 'vus_alterations', visible: true },
    { name: 'Age', field: 'age', visible: true },
    { name: 'Gender', field: 'gender', visible: true },
    { name: 'ICD10 Codes', field: 'ICD10__Non__Cancer__code', visible: true },
    { name: 'Vital Status', field: 'vital_status', visible: true },
    { name: 'Race', field: 'race', visible: true },
    { name: 'OrderID', field: 'lab_report_report_ids', visible: true }
];

const unselectAll = [
    { name: 'Report Diagnosis', field: 'specific_diseases', visible: false },
    { name: 'Stage', field: 'stages', visible: false },
    { name: 'Cancer ICD10 Codes', field: 'ICD10__Cancer__code', visible: false },
    { name: 'Alterations', field: 'significant_alterations', visible: false },
    { name: 'Treatments', field: 'oncologic_treatments', visible: false },
    { name: 'Cancer Type', field: 'Cancer_Type', visible: false },
    { name: 'ECOG Value', field: 'performance_status_value', visible: false },
    { name: 'Uncertain Alterations', field: 'vus_alterations', visible: false },
    { name: 'Age', field: 'age', visible: false },
    { name: 'Gender', field: 'gender', visible: false },
    { name: 'ICD10 Codes', field: 'ICD10__Non__Cancer__code', visible: false },
    { name: 'Vital Status', field: 'vital_status', visible: false },
    { name: 'Race', field: 'race', visible: false },
    { name: 'OrderID', field: 'lab_report_report_ids', visible: false }
];

export const patientInfoOptions = writable(initialValue);

export function selectAllOptions() {
    patientInfoOptions.set(selectAll);
}

export function unselectAllOptions() {
    patientInfoOptions.set(unselectAll);
}

export function setDefaultOptions() {
    patientInfoOptions.set([
        { name: 'Report Diagnosis', field: 'specific_diseases', visible: true },
        { name: 'Stage', field: 'stages', visible: true },
        { name: 'Cancer ICD10 Codes', field: 'ICD10__Cancer__code', visible: true },
        { name: 'Alterations', field: 'significant_alterations', visible: true },
        { name: 'Treatments', field: 'oncologic_treatments', visible: true },
        { name: 'Cancer Type', field: 'Cancer_Type', visible: true },
        { name: 'ECOG Value', field: 'performance_status_value', visible: true },
        { name: 'Uncertain Alterations', field: 'vus_alterations', visible: false },
        { name: 'Age', field: 'age', visible: false },
        { name: 'Gender', field: 'gender', visible: false },
        { name: 'ICD10 Codes', field: 'ICD10__Non__Cancer__code', visible: false },
        { name: 'Vital Status', field: 'vital_status', visible: false },
        { name: 'Race', field: 'race', visible: false },
        { name: 'OrderID', field: 'lab_report_report_ids', visible: false }
    ]);
}