<script>
    import {alertPage, alertID} from "../../store/navigation";


    function createAlerts(){
        alertPage.set("create");
        alertID.set("")
    }

    function manageAlerts(){
        alertPage.set("management");
    }

</script>

<div class="nav">
    <button style="background-color: {$alertPage === 'management' ? '#233C6B' : '#f2f2f2'}; color: {$alertPage === 'management' ? 'white' : '#233C6B'}; border: 1px solid #ccc; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);" on:click={manageAlerts}>Manage Alerts</button>
    <button style="background-color: {$alertPage === 'create' ? '#233C6B' : '#f2f2f2'}; color: {$alertPage === 'create' ? 'white' : '#233C6B'}; border: 1px solid #ccc; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);" on:click={createAlerts}>Create Alerts</button>
</div>


<style>
    .nav {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .nav button {
        margin: 10px;
        padding: 8px 32px;
        font-size: 16px;
        text-overflow: clip;
        border: none;
        border-radius: 4px;
        cursor: pointer;

    }

    .nav button:hover {
        text-decoration:underline;
    }
</style>
