<script>
    import {additionalPatientDetails, selectedCase, historical_match_params} from "../../store/patientMatch";
    import {createEventDispatcher} from 'svelte';
    import {onMount} from 'svelte';

    const dispatch = createEventDispatcher();

    let checkboxes = {};

    let excludedMeta = [ "ICD10__code", "ICD10__description", "ICD10__Cancer__code"]

    onMount(() => {
        // Populate checkboxes dynamically based on case.meta
        checkboxes = $historical_match_params.patient_fields.patient_meta_data
            ? Object.fromEntries(
                Object.keys($historical_match_params.patient_fields.patient_meta_data)
                    .filter(key => !excludedMeta.includes(key)) // Exclude specified keys
                    .map(key => [key, $additionalPatientDetails.includes(key)])
            )
            : {};
    });


    function handleSubmit() {
        let tempAdditionalDetails = [];
        Object.keys(checkboxes).forEach(label => {
            if (checkboxes[label]){
                tempAdditionalDetails.push(label)
            }
        })
        additionalPatientDetails.set(tempAdditionalDetails);
        dispatch('submitAction');
    }

    function handleCancel() {
        Object.keys(checkboxes).forEach(key => checkboxes[key] = false);
        additionalPatientDetails.set([]);
        dispatch('removeAction');
    }
</script>

<div>
    <h3>View Additional Details</h3>
    {#each Object.keys(checkboxes) as label}
        <div class="checkbox-wrapper">
            <input type="checkbox" bind:checked={checkboxes[label]}/>
            <p>{label.replaceAll("_"," ")}</p>
        </div>
    {/each}
    <button class="search-button" on:click={handleSubmit}>Submit</button>
    <button class="clear-button" on:click={handleCancel}>Reset</button>
</div>

<style>
    .checkbox-wrapper {
        display: flex;
        align-content: space-between;
    }

    .search-button {
        margin-top: 10px;
        background-color: #9d9d9f;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .clear-button {
        margin-top: 10px;
        background-color: #d98b95;
        color: #fff;
        margin-right: 0.5em;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
</style>
