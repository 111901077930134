import {writable, get} from "svelte/store";

/**
 * Store for display names for query keys
 * formatted { query_key: { display: String, sectionKey: String, core: String }
 */

export const displayNames = writable({...{}});

export const addProps = (sectionKey, props, core = 'cases') => {

    /*
    console.log("Inside addProps...");
    console.log("sectionKey:", sectionKey);
    console.log("props:", props);
    console.log("core:", core);
    */

    displayNames.update((current) => {
        const invertedOptions = {};
        Object.entries(props).forEach(([displayName, qKey]) => {
            invertedOptions[qKey] = { displayName, sectionKey, core };
        });
        current = {...current, ...invertedOptions};
        return current;
    });
};

export function getDisplayNames() {
    return get(displayNames);
}