<script>
    import { logout } from "../../network/user";
    import { Icon } from "svelte-awesome";
    import {faHome, faUser, faBell, faGear, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
    import {getBaseNavigationUrl} from '../../utils/urls';
    import { getUser, session } from "../../store/session";
    import { onDestroy } from "svelte";
    import {addToHistory, selectedOption} from "../../store/navigation";
    import AppModal from "./AppModal.svelte";
    import ChangePassword from "../account/ChangePassword.svelte";

    let logoMargin = "0em";
    let hasUnviewedNotifications = false;
    let numPatientsNeedingReview = 0;
    let isResetModalOpen = false;

    // Subscribe to session within a reactive statement
    const unsubscribe = session.subscribe(() => {
        // Update the variable whenever session changes
        hasUnviewedNotifications = !getUser().has_viewed_notifications;
        numPatientsNeedingReview = getUser().num_patients_needing_review;
    });

    // Unsubscribe when the component is destroyed
    onDestroy(() => {
        unsubscribe();
    });

    function selectOption(option) {
        addToHistory(option);
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }

    const closeResetModal = () => {
        console.log("close reset");
        isResetModalOpen = false;
    };
    const openResetModal = () => {
        console.log("open reset");
        isResetModalOpen = true;
    };
</script>
<!-- Header -->
<nav class="navbar">
    <h3 class="navbar-title">
        <span style="color: #233C6B;">Precision</span> <span style="color: #9FCE67;">Decision</span>
    </h3>
    <div on:click={()=>{selectOption('dashboard')}} style="cursor: pointer" aria-hidden="true">
        <Icon data ="{faHome}" scale="1.75" style="margin-left: 15px; color: #233C6B"/>
    </div>
    <div class="navbar-spacer"></div>
    {#if numPatientsNeedingReview > 0}
        <div class="dropdown">
            <span class="icon">
                <Icon data ="{faTriangleExclamation}" scale="1.75" style="margin-left: 15px; color: red"/>
                <span class="review-dot">{numPatientsNeedingReview}</span>
            </span>
            <div class="user-dropdown">
                <span class="user-dropdown-link" aria-hidden="true" on:click={() => selectOption('patient-management')}>
                    {#if numPatientsNeedingReview === 1}
                        {numPatientsNeedingReview} Patient Needs Review
                    {:else}
                        {numPatientsNeedingReview} Patients Need Review
                    {/if}
                </span>
            </div>
        </div>
    {/if}
    {#if getUser().is_superuser}
        <div style="cursor: pointer">
            <div class="dropdown">
                <Icon data ="{faGear}" scale="1.75" style="margin-left: 15px; color: #233C6B"/>
                <div class="user-dropdown">
                    <a href="/admin-ui" target="_blank" style="text-decoration: none; color: #233C6B">
                        <span class="user-dropdown-link">Administration Console</span>
                    </a>
                </div>
            </div>
        </div>
    {/if}
    <div class="dropdown">
        <Icon data ="{faUser}" scale="1.75" style="margin-left: 15px; color: #233C6B"/>
        <div class="user-dropdown">
            <span class="user-dropdown-link" aria-hidden="true" on:click={() => openResetModal()}>Change Password</span>
        </div>
    </div>
    <div class="dropdown">
        <span class="icon">
            <Icon data ="{faBell}" scale="1.75" style="margin-left: 15px; color: #233C6B"/>
            {#if hasUnviewedNotifications}
                <span class="notification-dot"></span>
            {/if}
        </span>
        <div class="user-dropdown">
            <span class="user-dropdown-link" aria-hidden="true" on:click={() => selectOption('notifications')}>View Notifications</span>
        </div>
    </div>
    <span class="navbar-link" on:click={logout} aria-hidden="true">
    Logout
  </span>
</nav>

<AppModal isOpen={isResetModalOpen} width={"500px"} on:close={closeResetModal}>
    <div class="contents">
        <ChangePassword {closeResetModal} />
    </div>
</AppModal>

<style>
    .navbar {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        background-color: #FFFFFF;
        color: #233C6B;
        z-index: 9999999;
    }

    .navbar-link {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        line-height: 1.5;
        color: #233C6B;
        text-decoration: none;
        cursor: pointer;
    }

    .navbar-link:hover {
        text-decoration: None;
    }

    .navbar-title {
        font-stretch: condensed;
        font-size: 25px;
        margin-left: 10px;
        cursor: default;
    }

    .navbar-spacer {
        flex-grow: 1;
    }
    .notification-dot {
        position: absolute;
        right: 0;
        width: 12px;
        height: 12px;
        background-color: red;
        border-radius: 50%;
    }
    .review-dot {
        position: absolute;
        bottom: 25px;
        left: 27px;
        width: 20px;
        height: 20px;
        background-color: red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: white;
        border: 1px solid white;
    }
    .icon {
        position: relative;
    }
    .dropdown {
        position: relative;
        display: inline-block;
        z-index: 1000;
        margin-right: 55px;
    }

    .user-dropdown {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
        left: -35px;
    }

    .dropdown:hover .user-dropdown{
        display: block;
    }

    .user-dropdown span:hover {
        background-color: white;
    }

    .dropdown:hover {
        display: block;
    }
    .user-dropdown-link{
        padding: 8px 6px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        width: 6vw;
    }
</style>