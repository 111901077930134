<script>
    import {Icon} from "svelte-awesome";
    import {
        faAngleDown,
        faAngleUp,
        faBan,
        faCircleCheck,
        faTriangleExclamation,
        faXmark
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {
        allTrialfilters,
        contentsMatches,
        historical_match_params,
        markerMatchesMap,
        matchTableSetting,
        negMarkers,
        openMatchResults,
        pane,
        resTherapies,
        selectedCase, selectedCaseUUID,
        senFTTTherapies,
        senOTTTherapies,
        sigMarkers,
        vusMarkers
    } from "../../store/patientMatch";
    import {createEventDispatcher} from 'svelte';
    import {HSplitPane} from "svelte-split-pane";
    import {getAlteration, getAnnotationMatch, getProteinEffects, updateMarker} from "../../network/patient_match";
    import {getDefaultInstitution} from "../../store/session";
    import Loader from "../shared/Loader.svelte";

    const dispatch = createEventDispatcher();

    let rightBiomarkerDetailPaneState = 'closed';
    let interpretationDropdown = false;
    let genomicDropdown = false;
    let freqDropdown = false;
    let narDropdown = false;
    let annDropdown = false;
    let linkDropdown = false;
    let editSigDropdown = false;
    let selectedMarker = {};
    let singleCardMarker = {};
    let openGenieDropdowns = [];
    let openInternalDropdowns = [];
    let additionalDetailsLoading = false;
    let additionalMarkerDetails = {};
    let alterationDetailsLoading = false;
    let alterationDetails = {};

    let view = 'table';  // Default view is table
    let sortCards = 'alphabetical';

    let sigSortSelected = '';
    let vusSortSelected = '';
    let negSortSelected = '';

    let sigReversed = false;
    let vusReversed = false;
    let negReversed = false;

    let isLoading = false;


    function gatherMarkerDetails(marker) {
        additionalDetailsLoading = true;
        interpretationDropdown = false;
        genomicDropdown = false;
        freqDropdown = false;
        narDropdown = false;
        annDropdown = false;
        linkDropdown = false;
        editSigDropdown = false;
        additionalMarkerDetails = {}; // Resetting the object before fetching new data
        alterationDetailsLoading = true;
        alterationDetails = [];

        let csra = '';
        if (selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.csra) {
            csra = selectedMarker.variant_hgvs.csra;
        }
        let hgvs_g = '';
        if (selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.hgvs_g) {
            hgvs_g = selectedMarker.variant_hgvs.hgvs_g;
        }

        let mutation_hash = marker.marker_display;
        let alterations = marker.relevant_alterations;

        let batch = hgvs_g ? hgvs_g : mutation_hash;
        let build = 'GRCh37';
        if (csra) {
            if (csra.split("|")[-1] === "GRCh38") {
                build = 'GRCh38';
            }
        }
        getAnnotationMatch(batch, build)
            .then(function (annotationsMatch) {
                additionalMarkerDetails.annotationsMatch = annotationsMatch[mutation_hash];
                return getProteinEffects(mutation_hash);
            })
            .then(function (proteinEffects) {
                additionalMarkerDetails.proteinEffects = proteinEffects.results;
                additionalDetailsLoading = false;
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
                additionalDetailsLoading = false;
            });

        if (alterations.length > 0) {
            alterations.forEach(alt => {
                getAlteration(alt)
                    .then((altReponse) => {
                        alterationDetails = [...alterationDetails, altReponse];
                        alterationDetailsLoading = false;
                    })
                    .catch((error) => {
                        alterationDetailsLoading = false;
                        console.error("Error loading alteration details:", error);
                    });
            });
        }
    }


    $: sigMarkerList = Object.values($sigMarkers);
    $: vusMarkerList = Object.values($vusMarkers);
    $: negMarkerList = Object.values($negMarkers);
    $: changesDetected = $historical_match_params.unsaved_updated_fields.selected_alterations;
    $: unsavedMarkers = Object.entries($historical_match_params.updated_fields).filter(([key, value]) => !value.saved);
    $: assayMap = $selectedCase.assay_names.reduce((assay, name, index) => {assay[name] = index + 1; return assay;}, {});

    async function saveBiomarkerChanges() {
        isLoading = true;
        let promises = []
        unsavedMarkers.forEach(([marker_display, marker_data]) => {
            marker_data.uuid.forEach(uuid => {
                promises.push(updateMarker(uuid, !marker_data.value));
            })
        });
        await Promise.all(promises);

        historical_match_params.update(params => {
            const updated_fields = Object.entries(params.updated_fields).map(([key, value]) => {
                if (value.field === "selected_alterations") {
                    return [key, { ...value, saved: true }];
                }
                return [key, value];
            });

            const updatedFieldsObj = Object.fromEntries(updated_fields);

            let unsaved_updated_fields = { ...params.unsaved_updated_fields };
            unsaved_updated_fields['selected_alterations'] = false;

            let recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                updated_fields: updatedFieldsObj,
                unsaved_updated_fields,
                recalculate_match
            };
        });

        isLoading = false;
    }

    let assayNames = '';
    let labReportedDates = '';

    if ($selectedCase) {
        labReportedDates = $selectedCase.lab_reported_dates.join(', ');
        assayNames = $selectedCase.assay_names.join(', ');
    }

    function setView(newView) {
        view = newView;
        selectedMarker = {};//reset selectedMarker
        rightBiomarkerDetailPaneState = 'closed';
    }

    function sortCardsBy(sortBy) {
        sortCards = sortBy;
        sigMarkerList = sortMarkers(sigMarkerList, sortBy);
        vusMarkerList = sortMarkers(vusMarkerList, sortBy);
        negMarkerList = sortMarkers(negMarkerList, sortBy);
    }

    function sortMarkers(markers, category) {
        const markersList = markers.slice().sort((a, b) => a.marker_display.localeCompare(b.marker_display));
        switch (category) {
        case 'alphabetical':
            return markersList;
        case 'in house biomarker':
            return markersList.slice().sort((a, b) => {
                const aLength = $markerMatchesMap[a.marker_display]?.instTrials.length ?? -1;
                const bLength = $markerMatchesMap[b.marker_display]?.instTrials.length ?? -1;
                return bLength - aLength;
            });
        case 'outside bio trials':
            return markersList.slice().sort((a, b) => {
                const aLength = $markerMatchesMap[a.marker_display]?.outsideTrials.length ?? -1;
                const bLength = $markerMatchesMap[b.marker_display]?.outsideTrials.length ?? -1;
                return bLength - aLength;
            });
        default:
            return markersList;
        }
    }

    function generateCosmicUrl(selectedMarker) {
        const baseUrl = 'https://cancer.sanger.ac.uk/cosmic/search?q=';
        const query = selectedMarker.marker_display.replace(/ /g, '+');
        return `${baseUrl}${encodeURIComponent(query)}`;
    }

    function generateOncoKBUrl() {
        const baseUrl = 'http://oncokb.org/#/';
        const gene = alterationDetails[0].gene ? `gene/${alterationDetails[0].gene}` : 'genes';
        const detailWithAAChange = alterationDetails.find(detail => detail.aa_change);
        const aaChange = detailWithAAChange ? `/variant/${detailWithAAChange.aa_change}` : '';
        return `${baseUrl}${gene}${aaChange}`;
    }

    function generateCBioPortalUrl() {
        const baseUrl = 'https://www.cbioportal.org/ln?q=';
        return `${baseUrl}${encodeURIComponent(alterationDetails[0].gene)}:MUT`;
    }

    function generateMasterMindLink() {
        const baseUrl = 'https://mastermind.genomenon.com';
        const gene = alterationDetails[0].gene ? `gene=${alterationDetails[0].gene}` : 'genes';
        return `${baseUrl}/articles?${gene}`;
    }

    function generateVICCGeneLink() {
        return `https://search.cancervariants.org/#gene=${encodeURIComponent(alterationDetails[0].gene)}`;
    }

    function generateVICCVariantLink(selectedMarker) {
        const gChangeString = selectedMarker.representations ? selectedMarker.representations.find(rep => rep.includes('g.')) : '';
        const g_change = gChangeString ? gChangeString.split('g.')[1] : '';
        const detailWithAAChange = alterationDetails.find(detail => detail.aa_change);
        const variant = `${alterationDetails[0].gene} ${detailWithAAChange ? detailWithAAChange.aa_change : g_change}`;
        return `https://search.cancervariants.org/#variant=${encodeURIComponent(variant)}`;
    }

    function generateVarseakLink(selectedMarker) {
        const gChangeString = selectedMarker.representations ? selectedMarker.representations.find(rep => rep.includes('g.')) : '';
        const g_change = gChangeString ? gChangeString.split('g.')[1] : '';
        const detailWithPStart = alterationDetails.find(detail => detail.p_start);
        if (!alterationDetails[0].gene || !detailWithPStart || !g_change) {
            const baseUrl = "https://varseak.bio/ssp.php?";
            return `${baseUrl}gene=${alterationDetails[0].gene}`;
        }

        const baseUrl = "https://varseak.bio/ssp.php?";
        return `${baseUrl}gene=${alterationDetails[0].gene}&transcript=${alterationDetails[0].id}&variant=&hgvs=${g_change}`;


    }

    function generateVarsomeLink(selectedMarker) {
        const chrRep = selectedMarker.representations ? selectedMarker.representations.find(rep => rep.includes('chr')) : '';
        let chromosome;
        if (chrRep) {
            chromosome = chrRep.match(/chr(\d+)/);
        }
        chromosome = chromosome ? chromosome[1] : null;
        const detailWithPStart = alterationDetails.find(detail => detail.aa_change);
        if (!chromosome || !detailWithPStart) {
            return [];
        }

        const baseUrl = "http://varsome.com/variant/hg19";
        const modes = ["germline", "somatic"];

        return modes.map(mode => {
            return `${baseUrl}/${chromosome}-${detailWithPStart.p_start}-${alterationDetails[0].ref || ''}-${alterationDetails[0].name || ''}?annotation-mode=${mode}`;
        });
    }

    function generateGoogleLink() {
        const detailWithAAChange = alterationDetails.find(detail => detail.aa_change);
        const query = `${alterationDetails[0].gene} ${detailWithAAChange ? detailWithAAChange.aa_change : ''}`;
        return `https://www.google.com/search?q=${encodeURIComponent(query)}`;

    }

    function getDomain(selectedMarker) {
        if (!selectedMarker || !selectedMarker.uniprot || !selectedMarker.uniprot[0] || !selectedMarker.uniprot[0].features__mstring) {
            return '';
        }

        const domain = selectedMarker.uniprot[0].features__mstring.find(features => features.type === 'domain');
        if (domain) {
            return `${parseInt(domain.end) - parseInt(domain.start)} ${domain.description}`;
        }

        return '';
    }

    function getTestComparison(marker) {
        const caseAssays = $selectedCase.assay_names;
        let testComparison = {"detected": [], "not_detected": [], "not_tested": []};
        caseAssays.forEach(assay => {
            let status = marker.testComparison[assay] ? marker.testComparison[assay] : "not_tested";
            if (status === "Detected") {
                testComparison["detected"] = [...testComparison["detected"], assay];
            } else if (status === "Not Detected") {
                testComparison["not_detected"] = [...testComparison["not_detected"], assay];
            } else {
                testComparison["not_tested"] = [...testComparison["not_tested"], assay];
            }
        });
        return testComparison;
    }

    function toggleGenieDropdown(viewLocation, index) {
        let identifier = viewLocation + String(index);
        openGenieDropdowns[identifier] = !openGenieDropdowns[identifier];
    }

    function toggleInternalDropdown(viewLocation, index) {
        let identifier = viewLocation + String(index);
        openInternalDropdowns[identifier] = !openInternalDropdowns[identifier];
    }

    function sortTableBy(category, type) {
        if (type === 'sig') {
            if (sigSortSelected === category) {
                sigReversed = !sigReversed;
            } else {
                sigReversed = false;
            }
            sigSortSelected = category;
            if (category === 'marker_display') {
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => a.marker_display.localeCompare(b.marker_display));
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => a.marker_display.localeCompare(b.marker_display));
                }
            } else if (category === 'inst') {
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => {
                        const aLength = $markerMatchesMap[a.marker_display]?.instTrials.length ?? -1;
                        const bLength = $markerMatchesMap[b.marker_display]?.instTrials.length ?? -1;
                        return bLength - aLength;
                    });
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => {
                        const aLength = $markerMatchesMap[a.marker_display]?.instTrials.length ?? -1;
                        const bLength = $markerMatchesMap[b.marker_display]?.instTrials.length ?? -1;
                        return bLength - aLength;
                    });
                }
            } else if (category === 'outside') {
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => {
                        const aLength = $markerMatchesMap[a.marker_display]?.outsideTrials.length ?? -1;
                        const bLength = $markerMatchesMap[b.marker_display]?.outsideTrials.length ?? -1;
                        return bLength - aLength;
                    });
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => {
                        const aLength = $markerMatchesMap[a.marker_display]?.outsideTrials.length ?? -1;
                        const bLength = $markerMatchesMap[b.marker_display]?.outsideTrials.length ?? -1;
                        return bLength - aLength;
                    });
                }
            } else if (category === 'therapies') {
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => {
                        const aLength = $senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length +
                            $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length +
                            $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length ?? -1;
                        const bLength = $senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length +
                            $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length +
                            $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length ?? -1;
                        return bLength - aLength;
                    });
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => {
                        const aLength = $senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length +
                            $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length +
                            $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(a.marker_display)).length ?? -1;
                        const bLength = $senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length +
                            $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length +
                            $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(b.marker_display)).length ?? -1;
                        return bLength - aLength;
                    });
                }
            } else if (category === 'freq') {
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => {
                        const aLength = a.internalHits + (a.genie_data?.[0]?.solid_count__int ?? 0) + (a.genie_data?.[0]?.count__int ?? 0);
                        const bLength = b.internalHits + (b.genie_data?.[0]?.solid_count__int ?? 0) + (b.genie_data?.[0]?.count__int ?? 0);
                        return bLength - aLength;
                    });
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => {
                        const aLength = a.internalHits + (a.genie_data?.[0]?.solid_count__int ?? 0) + (a.genie_data?.[0]?.count__int ?? 0);
                        const bLength = b.internalHits + (b.genie_data?.[0]?.solid_count__int ?? 0) + (b.genie_data?.[0]?.count__int ?? 0);
                        return bLength - aLength;
                    });
                }
            }
            else if (category === 'discordant'){
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => a.discordant_interpretation.toString().localeCompare(b.discordant_interpretation.toString()));
                } else {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => a.discordant_interpretation.toString().localeCompare(b.discordant_interpretation.toString()));
                }
            }
            else if (category === 'metrics'){
                if (sigReversed) {
                    sigMarkerList = sigMarkerList.slice().sort((b, a) => {
                        const totalA = countMarkerMetaPopulated(a.time_course);
                        const totalB = countMarkerMetaPopulated(b.time_course);
                        return totalB - totalA;
                    });
                }
                else {
                    sigMarkerList = sigMarkerList.slice().sort((a, b) => {
                        const totalA = countMarkerMetaPopulated(a.time_course);
                        const totalB = countMarkerMetaPopulated(b.time_course);
                        return totalB - totalA;
                    });
                }
            }
        } else if (type === 'vus') {
            if (vusSortSelected === category) {
                vusReversed = !vusReversed;
            } else {
                vusReversed = false;
            }
            vusSortSelected = category;
            if (category === 'marker_display') {
                if (vusReversed) {
                    vusMarkerList = vusMarkerList.slice().sort((b, a) => a.marker_display.localeCompare(b.marker_display));
                } else {
                    vusMarkerList = vusMarkerList.slice().sort((a, b) => a.marker_display.localeCompare(b.marker_display));
                }
            } else if (category === 'freq') {
                if (vusReversed) {
                    vusMarkerList = vusMarkerList.slice().sort((b, a) => {
                        const aLength = a.internalHits + (a.genie_data?.[0]?.solid_count__int ?? 0) + (a.genie_data?.[0]?.count__int ?? 0);
                        const bLength = b.internalHits + (b.genie_data?.[0]?.solid_count__int ?? 0) + (b.genie_data?.[0]?.count__int ?? 0);
                        return bLength - aLength;
                    });
                } else {
                    vusMarkerList = vusMarkerList.slice().sort((a, b) => {
                        const aLength = a.internalHits + (a.genie_data?.[0]?.solid_count__int ?? 0) + (a.genie_data?.[0]?.count__int ?? 0);
                        const bLength = b.internalHits + (b.genie_data?.[0]?.solid_count__int ?? 0) + (b.genie_data?.[0]?.count__int ?? 0);
                        return bLength - aLength;
                    });
                }
            }
            else if (category === 'discordant'){
                if (vusReversed) {
                    vusMarkerList = vusMarkerList.slice().sort((a, b) => a.discordant_interpretation.toString().localeCompare(b.discordant_interpretation.toString()));
                } else {
                    vusMarkerList = vusMarkerList.slice().sort((b, a) => a.discordant_interpretation.toString().localeCompare(b.discordant_interpretation.toString()));
                }
            }
            else if (category === 'metrics'){
                if (vusReversed){
                    vusMarkerList = vusMarkerList.slice().sort((b, a) => {
                        const totalA = countMarkerMetaPopulated(a.time_course);
                        const totalB = countMarkerMetaPopulated(b.time_course);
                        return totalB - totalA;
                    });
                }
                else {
                    vusMarkerList = vusMarkerList.slice().sort((a, b) => {
                        const totalA = countMarkerMetaPopulated(a.time_course);
                        const totalB = countMarkerMetaPopulated(b.time_course);
                        return totalB - totalA;
                    });
                }
            }
        } else if (type === 'neg') {
            if (negSortSelected === category) {
                negReversed = !negReversed;
            } else {
                negReversed = false;
            }
            sigSortSelected = category;
            if (category === 'marker_display') {
                if (negReversed) {
                    negMarkerList = negMarkerList.slice().sort((b, a) => a.marker_display.localeCompare(b.marker_display));
                } else {
                    negMarkerList = negMarkerList.slice().sort((a, b) => a.marker_display.localeCompare(b.marker_display));
                }
            }
        }
    }

    function countMarkerMetaPopulated(timeCourses){
        return timeCourses.reduce((totalCount, tc) => {
            const count = ['vaf', 'copy_number', 'tmb_score__string', 'cfdna_percentage', 'fold_change'].reduce((count, field) => {
                return count + (tc[field] !== undefined && tc[field] !== null && tc[field] !== 0 ? 1 : 0);
            }, 0);
            return totalCount + count;
        }, 0);
    }

    function getCurrentTime() {
        return new Date().toLocaleTimeString();
    }

    function handleSignificanceSubmit(marker) {
        let selectedValue = document.querySelector('input[name="significance"]:checked').value;

        let isUncertain = selectedValue === "true";

        if (marker.is_variant_of_uncertain_significance !== isUncertain) {
            marker.is_variant_of_uncertain_significance = isUncertain;
            if (isUncertain) {
                sigMarkers.update(markers => {
                    const updated = {...markers};
                    delete updated[marker.marker_display];
                    return updated;

                });
                // Switch altered_sig flag to track alterations that need to be added/removed from selected_alterations model
                marker.altered_sig = !marker.altered_sig;
                let markerUuids = marker.time_course.map(tc => tc.uuid);
                if (marker.altered_sig) {
                    historical_match_params.update(params => {
                        const updated_fields = params.updated_fields;
                        updated_fields[marker.marker_display] = {
                            "uuid":markerUuids,
                            "field": "selected_alterations",
                            "value": !isUncertain,
                            "timeStamp": getCurrentTime(),
                            "saved": false
                        };
                        let unsaved_updated_fields = params.unsaved_updated_fields;
                        unsaved_updated_fields['selected_alterations'] = marker.altered_sig;
                        let recalculate_match = params.recalculate_match;
                        recalculate_match = Object.values(updated_fields).length > 0;
                        return {
                            ...params,
                            updated_fields,
                            unsaved_updated_fields,
                            recalculate_match
                        };
                    });
                } else {
                    historical_match_params.update(params => {
                        const updated_fields = params.updated_fields;
                        delete updated_fields[marker.marker_display];
                        let recalculate_match = params.recalculate_match;
                        recalculate_match = Object.values(updated_fields).length > 0;
                        let unsaved_updated_fields = params.unsaved_updated_fields;
                        unsaved_updated_fields['selected_alterations'] = Object.values(updated_fields).some(field => field.field === "selected_alterations");
                        return {
                            ...params,
                            updated_fields,
                            recalculate_match,
                            unsaved_updated_fields
                        };
                    });
                }
                vusMarkers.update(markers => {
                    return {...markers, [marker.marker_display]: marker};

                });
            } else {
                vusMarkers.update(markers => {
                    const updated = {...markers};
                    delete updated[marker.marker_display];
                    return updated;

                });
                // Switch altered_sig flag to track alterations that need to be added/removed from selected_alterations model
                marker.altered_sig = !marker.altered_sig;
                let markerUuids = marker.time_course.map(tc => tc.uuid);
                if (marker.altered_sig) {
                    historical_match_params.update(params => {
                        const updated_fields = params.updated_fields;
                        updated_fields[marker.marker_display] = {
                            "uuid": markerUuids,
                            "field": "selected_alterations",
                            "value": !isUncertain,
                            "timeStamp": getCurrentTime(),
                            "saved": false
                        };
                        let unsaved_updated_fields = params.unsaved_updated_fields;
                        unsaved_updated_fields['selected_alterations'] = true;
                        let recalculate_match = params.recalculate_match;
                        recalculate_match = Object.values(updated_fields).length > 0;
                        return {
                            ...params,
                            updated_fields,
                            unsaved_updated_fields,
                            recalculate_match
                        };
                    });
                } else {
                    historical_match_params.update(params => {
                        const updated_fields = params.updated_fields;
                        delete updated_fields[marker.marker_display];
                        let recalculate_match = params.recalculate_match;
                        recalculate_match = Object.values(updated_fields).length > 0;
                        let unsaved_updated_fields = params.unsaved_updated_fields;
                        unsaved_updated_fields['selected_alterations'] = Object.values(updated_fields).some(field => field.field === "selected_alterations");
                        return {
                            ...params,
                            updated_fields,
                            recalculate_match,
                            unsaved_updated_fields
                        };
                    });
                }
                sigMarkers.update(markers => {
                    return {...markers, [marker.marker_display]: marker};
                });
            }
        }
    }

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

</script>

<HSplitPane leftPaneSize={rightBiomarkerDetailPaneState==='closed' ? '100%' : '70%'}
            rightPaneSize={rightBiomarkerDetailPaneState==='closed' ? '0%' : '30%'}
            minRightPaneSize={rightBiomarkerDetailPaneState==='closed' ? '0%' : '20%'}>
    <left slot="left">
        <div>
            <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px; width: 95%;">
                <div></div>
                <div style="display: flex; align-items: center; justify-content: flex-start">
                    <h1 style="margin: 0;">Biomarker Summary</h1>
                    <div style="cursor: pointer;" on:click={saveBiomarkerChanges}>
                        {#if changesDetected}
                            <button class='save-button' style="margin-left: 15px;">
                                Save Changes
                            </button>
                        {/if}
                    </div>
                </div>
                <div on:click={() => pane.set("backToSummary")}>
                    <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
                </div>
            </div>
            {#if isLoading}
                <div style="width:100vw; height:50vh; align-items: center; align-content: center;">
                    <Loader/>
                </div>

            {:else}
                <div style="height: 55vh; margin-left:25px; margin-right:25px; overflow-x: scroll;">

                    <div style="margin: 0 25px; display: flex; flex-direction: row; justify-content: space-evenly">
                        <label><input type="checkbox" on:change={() => setView('table')} checked={view === 'table'}/> Table
                            View</label>
                        <label><input type="checkbox" on:change={() => setView('card')} checked={view === 'card'}/> Card
                            View</label>
                        {#if ($selectedCase.assay_names && $selectedCase.assay_names.length > 1)}
                            <label><input type="checkbox" on:change={() => setView('comparison')}
                                          checked={view === 'comparison'}/>Test Comparison View</label>
                        {/if}
                    </div>

                    {#if view === 'table'}
                        <h3 style="text-align: left">Significant Alterations ({sigMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                    on:click={() => {sortTableBy('marker_display', 'sig')}}>
                                    {#if (sigSortSelected === "marker_display")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Variant
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Mutation Type</th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Assay</th>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                    on:click={() => {sortTableBy('inst', 'sig')}}>
                                    {#if (sigSortSelected === "inst")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Inst Trial
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;;"
                                    on:click={() => {sortTableBy('outside', 'sig')}}>
                                    {#if (sigSortSelected === "outside")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Trials
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;;"
                                    on:click={() => {sortTableBy('therapies', 'sig')}}>
                                    {#if (sigSortSelected === "therapies")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Therapies
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                    on:click={() => {sortTableBy('metrics', 'sig')}}>
                                    {#if (sigSortSelected === "metrics")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Metrics
                                </th>
                                {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                    <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                        on:click={() => {sortTableBy('discordant', 'sig')}}>
                                        {#if (sigSortSelected === "discordant")}
                                            <span>{sigReversed ? '▲' : '▼'}</span>
                                        {/if}
                                        Discordant Interpretation
                                    </th>
                                {/if}
                                <th style="border: 1px solid black; padding:10px; cursor: pointer"
                                    on:click={() => {sortTableBy('freq', 'sig')}}>
                                    {#if (sigSortSelected === "freq")}
                                        <span>{sigReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Frequency
                                </th>
                                <th style="border: 1px solid black; padding:10px;">Explore Details</th>
                                <th style="border: 1px solid black; padding:10px;">Card View</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each sigMarkerList as marker, index}
                                <tr style="background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div class="tooltip-container">
                                            {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                                <span class="tooltip-text">Unsaved</span>
                                            {/if}
                                        </div>
                                        {marker.marker_display}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">{marker.mutation_type || ""}</td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each marker.time_course as tc, index}
                                            {#if Object.keys(assayMap).length > 1}
                                                {#if index > 0}<br/>{/if}
                                                {tc.assay_name} ({assayMap[tc.assay_name]})
                                            {:else}
                                                {tc.assay_name}
                                            {/if}
                                        {/each}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;" aria-hidden="true" on:click={() => {
                                        pane.set('main');
                                        openMatchResults.set(true);
                                        matchTableSetting.set('instTrialsOnly');
                                        allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[], "DrugCategory":[]});}}>
                                        <p class="hover-underline">{$markerMatchesMap[marker.marker_display]?.instTrials.length || 0}</p>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <p class="hover-underline" aria-hidden="true" on:click={() => {
                                        pane.set('main');
                                        openMatchResults.set(true);
                                        matchTableSetting.set('outsideTrialsOnly');
                                        allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[], "DrugCategory":[]});}}>
                                            {$markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0}
                                        </p>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#if ($senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length > 0)}
                                            <p class="hover-underline" aria-hidden="true" on:click={() => {
                                            pane.set('main');
                                            openMatchResults.set(true);
                                            matchTableSetting.set('senFTTTherapies');
                                            allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[], "DrugCategory":[]});
                                        }}>
                                                {$senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                Sensitive
                                            </p>
                                        {/if}
                                        {#if ($resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length > 0)}
                                            <p class="hover-underline" aria-hidden="true" on:click={() => {
                                            pane.set('main');
                                            openMatchResults.set(true);
                                            matchTableSetting.set('resTherapies');
                                            allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[], "DrugCategory":[]});
                                        }}>
                                                {$resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                Resistant
                                            </p>
                                        {/if}
                                        {#if ($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length > 0)}
                                            <p class="hover-underline" aria-hidden="true" on:click={() => {
                                            pane.set('main');
                                            openMatchResults.set(true);
                                            matchTableSetting.set('senOTTTherapies');
                                            allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[], "DrugCategory":[]});
                                        }}>
                                                {$senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                Other Tumor Type
                                            </p>
                                        {/if}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div style="display:flex; flex-direction: column">
                                            {#each marker.time_course as tc, index}
                                                {#if Object.keys(assayMap).length > 1}
                                                    {#if (tc.vaf || tc.copy_number || tc.tmb_score__string || tc.cfdna_percentage || tc.fold_change)}
                                                        {#if (tc.vaf)}<p><strong>VAF: </strong>{tc.vaf} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.copy_number)}<p><strong>Display Copy
                                                            Number: </strong>{tc.copy_number} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.tmb_score__string)}<p><strong>Display TMB
                                                            Score: </strong>{tc.tmb_score__string} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.cfdna_percentage)}
                                                            {#if (marker.test_mode === "CNV")}
                                                                <p><strong>Ratio: </strong>{tc.cfdna_percentage}  ({assayMap[tc.assay_name]})</p>
                                                            {:else if (marker.test_mode === "SNV")}
                                                                <p><strong>cfDNA: </strong>{tc.cfdna_percentage}  ({assayMap[tc.assay_name]})</p>
                                                            {/if}
                                                        {/if}
                                                        {#if (tc.fold_change)}<p><strong>Fold Change: </strong>{tc.fold_change}
                                                            ({assayMap[tc.assay_name]})</p>{/if}
                                                    {/if}
                                                {:else}
                                                    {#if (tc.vaf || tc.copy_number || tc.tmb_score__string || tc.cfdna_percentage || tc.fold_change)}
                                                        {#if (tc.vaf)}<p><strong>VAF: </strong>{tc.vaf}</p>{/if}
                                                        {#if (tc.copy_number)}<p><strong>Display Copy
                                                            Number: </strong>{tc.copy_number}</p>{/if}
                                                        {#if (tc.tmb_score__string)}<p><strong>Display TMB
                                                            Score: </strong>{tc.tmb_score__string}</p>{/if}
                                                        {#if (tc.cfdna_percentage)}
                                                            {#if (marker.test_mode === "CNV")}
                                                                <p><strong>Ratio: </strong>{tc.cfdna_percentage}</p>
                                                            {:else if (marker.test_mode === "SNV")}
                                                                <p><strong>cfDNA: </strong>{tc.cfdna_percentage}</p>
                                                            {/if}
                                                        {/if}
                                                        {#if (tc.fold_change)}<p><strong>Fold Change: </strong>{tc.fold_change}</p>{/if}
                                                    {/if}
                                                {/if}
                                            {/each}
                                        </div>
                                    </td>
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <td style="border: 1px solid black; padding:10px;">
                                        <span style="cursor: pointer"
                                              on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker); interpretationDropdown=true}}>{marker.discordant_interpretation || 'False'}</span>
                                        </td>
                                    {/if}
                                    <td style="border: 1px solid black; padding:10px; width: 300px;">
                                        {#if (marker.genie_data.length > 0)}
                                            <p style="margin: 0;"><strong>GENIE:</strong></p>
                                            <p style="margin: 0 0 0 10px">
                                                <strong>Count: </strong>{marker.genie_data[0].count__int || "N/A"}<br>
                                                <strong>Solid Count: </strong>{marker.genie_data[0].solid_count__int || "N/A"}<br>
                                                <span style="cursor:pointer;" on:click={() => {toggleGenieDropdown("sig",index)}}>
                                                    <span>{openGenieDropdowns["sig" + String(index)] ? '▲' : '▼'}</span> View Disease Detail
                                                </span>
                                            </p>
                                            {#if openGenieDropdowns["sig" + String(index)]}
                                                <p style="margin: 0 0 0 15px">
                                                    <strong>Disease: </strong><br>
                                                    {#if (marker.genie_data[0].diseases__mstring.length > 0)}
                                                        <ul style="margin: 0">
                                                            {#each marker.genie_data[0].diseases__mstring as diseaseStr}
                                                                <li>
                                                                    {diseaseStr}
                                                                </li>
                                                            {/each}
                                                        </ul>
                                                    {/if}
                                                </p>
                                            {/if}
                                        {/if}
                                        {#if marker.internalHits && marker.internalHits !== 0}
                                            <strong>{getDefaultInstitution()}:<br/></strong>
                                            <p style="margin: 0 0 0 15px">
                                                <strong>Count:</strong>{marker.internalHits}<br/>
                                                <strong>Solid Count:</strong>{marker.internalSolidCount}<br/>
                                                <span style="margin: 0; cursor:pointer;" on:click={() => {toggleInternalDropdown("sig",index)}}>
                                                    <span>{openInternalDropdowns["sig" + String(index)] ? '▲' : '▼'}</span> View Disease Detail
                                                </span>
                                            </p>
                                            {#if (openInternalDropdowns["sig" + String(index)])}<p style="margin: 0 0 0 25px">
                                                <strong>Disease: </strong><br>
                                                {#if (Object.entries(marker.internalDiseases).length > 0)}
                                                    <ul style="margin: 0">
                                                        {#each Object.entries(marker.internalDiseases) as [diseaseStr, diseaseCount]}
                                                            <li>
                                                                {diseaseStr} = {diseaseCount}
                                                            </li>
                                                        {/each}
                                                    </ul>
                                                {/if}
                                            </p>{/if}
                                        {/if}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px; width:50px;">
                                        <div on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            <p class="hover-underline">Detail View</p>
                                        </div>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div on:click={() => {singleCardMarker=marker; setView('single_card')}}>
                                            <img src="static/images/exploreAllResultsIcon.png"
                                                 class="icon-png" alt="">
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                        <h3 style="text-align: left">Uncertain Alterations ({vusMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer"
                                    on:click={() => {sortTableBy('marker_display', 'vus')}}>
                                    {#if (vusSortSelected === "marker_display")}
                                        <span>{vusReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Variant
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Mutation Type</th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Assay</th>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                    on:click={() => {sortTableBy('metrics', 'vus')}}>
                                    {#if (vusSortSelected === "metrics")}
                                        <span>{vusReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Metrics
                                </th>
                                {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                    <th style="border: 1px solid black; padding:10px; cursor: pointer"
                                        on:click={() => {sortTableBy('discordant', 'vus')}}>
                                        {#if (vusSortSelected === "discordant")}
                                            <span>{vusReversed ? '▲' : '▼'}</span>
                                        {/if}
                                        Discordant Interpretation
                                    </th>
                                {/if}
                                <th style="border: 1px solid black; padding:10px; cursor: pointer"
                                    on:click={() => {sortTableBy('freq', 'vus')}}>
                                    {#if (vusSortSelected === "freq")}
                                        <span>{vusReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Frequency
                                </th>
                                <th style="border: 1px solid black; padding:10px;">Explore Details</th>
                                <th style="border: 1px solid black; padding:10px;">Card View</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each vusMarkerList as marker, index}
                                <tr style="background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div class="tooltip-container">
                                            {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                                <span class="tooltip-text">Unsaved</span>
                                            {/if}
                                        </div>
                                        {marker.marker_display}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">{marker.mutation_type || ""}</td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each marker.time_course as tc, index}
                                            {#if Object.keys(assayMap).length > 1}
                                                {#if index > 0}<br/>{/if}
                                                {tc.assay_name} ({assayMap[tc.assay_name]})
                                            {:else}
                                                {tc.assay_name}
                                            {/if}
                                        {/each}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div style="display:flex; flex-direction: column">
                                            {#each marker.time_course as tc, index}
                                                {#if Object.keys(assayMap).length > 1}
                                                    {#if (tc.vaf || tc.copy_number || tc.tmb_score__string || tc.cfdna_percentage || tc.fold_change)}
                                                        {#if (tc.vaf)}<p><strong>VAF: </strong>{tc.vaf} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.copy_number)}<p><strong>Display Copy
                                                            Number: </strong>{tc.copy_number} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.tmb_score__string)}<p><strong>Display TMB
                                                            Score: </strong>{tc.tmb_score__string} ({assayMap[tc.assay_name]})</p>{/if}
                                                        {#if (tc.cfdna_percentage)}
                                                            {#if (marker.test_mode === "CNV")}
                                                                <p><strong>Ratio: </strong>{tc.cfdna_percentage} ({assayMap[tc.assay_name]})</p>
                                                            {:else if (marker.test_mode === "SNV")}
                                                                <p><strong>cfDNA: </strong>{tc.cfdna_percentage} ({assayMap[tc.assay_name]})</p>
                                                            {/if}
                                                        {/if}
                                                        {#if (tc.fold_change)}<p><strong>Fold Change: </strong>{tc.fold_change}
                                                            ({assayMap[tc.assay_name]})</p>{/if}
                                                    {/if}
                                                {:else}
                                                    {#if (tc.vaf || tc.copy_number || tc.tmb_score__string || tc.cfdna_percentage || tc.fold_change)}
                                                        {#if (tc.vaf)}<p><strong>VAF: </strong>{tc.vaf}</p>{/if}
                                                        {#if (tc.copy_number)}<p><strong>Display Copy
                                                            Number: </strong>{tc.copy_number}</p>{/if}
                                                        {#if (tc.tmb_score__string)}<p><strong>Display TMB
                                                            Score: </strong>{tc.tmb_score__string}</p>{/if}
                                                        {#if (tc.cfdna_percentage)}
                                                            {#if (marker.test_mode === "CNV")}
                                                                <p><strong>Ratio: </strong>{tc.cfdna_percentage}</p>
                                                            {:else if (marker.test_mode === "SNV")}
                                                                <p><strong>cfDNA: </strong>{tc.cfdna_percentage}</p>
                                                            {/if}
                                                        {/if}
                                                        {#if (tc.fold_change)}<p><strong>Fold Change: </strong>{tc.fold_change}</p>{/if}
                                                    {/if}
                                                {/if}
                                            {/each}
                                        </div>
                                    </td>
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <td style="border: 1px solid black; padding:10px;">
                                        <span style="cursor: pointer"
                                              on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker); interpretationDropdown=true}}>{marker.discordant_interpretation || 'False'}</span>
                                        </td>
                                    {/if}
                                    <td style="border: 1px solid black; padding:10px; width: 300px;">
                                        {#if (marker.genie_data.length > 0)}
                                            <p style="margin: 0;"><strong>GENIE:</strong></p>
                                            <p style="margin: 0 0 0 10px">
                                                <strong>Count: </strong>{marker.genie_data[0].count__int || "N/A"}<br>
                                                <strong>Solid
                                                    Count: </strong>{marker.genie_data[0].solid_count__int || "N/A"}<br>
                                                <span style="margin: 0; cursor:pointer;"
                                                      on:click={() => {toggleGenieDropdown("vus",index)}}>
                                                <span>{openGenieDropdowns["vus" + String(index)] ? '▲' : '▼'}</span> View Disease Detail
                                            </span>
                                            </p>
                                            {#if openGenieDropdowns["vus" + String(index)]}
                                                <p style="margin: 0 0 0 15px">
                                                    <strong>Disease: </strong><br>
                                                    {#if (marker.genie_data[0].diseases__mstring.length > 0)}
                                                        <ul style="margin: 0">
                                                            {#each marker.genie_data[0].diseases__mstring as diseaseStr}
                                                                <li>
                                                                    {diseaseStr}
                                                                </li>
                                                            {/each}
                                                        </ul>
                                                    {/if}
                                                </p>
                                            {/if}
                                        {/if}
                                        {#if marker.internalHits && marker.internalHits !== 0}
                                            <strong>{getDefaultInstitution()}:<br/></strong>
                                            <p style="margin: 0 0 0 15px">
                                                <strong>Count:</strong>{marker.internalHits}<br/>
                                                <strong>Solid Count:</strong>{marker.internalSolidCount}<br/>
                                                <span style="margin: 0; cursor:pointer;" on:click={() => {toggleInternalDropdown("vus",index)}}>
                                                    <span>{openInternalDropdowns["vus" + String(index)] ? '▲' : '▼'}</span> View Disease Detail
                                                </span>
                                            </p>
                                            {#if (openInternalDropdowns["vus" + String(index)])}<p style="margin: 0 0 0 25px">
                                                <strong>Disease: </strong><br>
                                                {#if (Object.entries(marker.internalDiseases).length > 0)}
                                                    <ul style="margin: 0">
                                                        {#each Object.entries(marker.internalDiseases) as [diseaseStr, diseaseCount]}
                                                            <li>
                                                                {diseaseStr} = {diseaseCount}
                                                            </li>
                                                        {/each}
                                                    </ul>
                                                {/if}
                                            </p>{/if}
                                        {/if}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px; width:50px;">
                                        <div on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            <p class="hover-underline">Detail View</p>
                                        </div>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div on:click={() => {singleCardMarker=marker; setView('single_card')}}>
                                            <img src="static/images/exploreAllResultsIcon.png"
                                                 class="icon-png" alt="">
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                        <h3 style="text-align: left">Pertinent Negatives ({negMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px; cursor:pointer;"
                                    on:click={() => {sortTableBy('marker_display', 'neg')}}>
                                    {#if (negSortSelected === "marker_display")}
                                        <span>{negReversed ? '▲' : '▼'}</span>
                                    {/if}
                                    Variant
                                </th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Mutation Type</th>
                                <th style="border: 1px solid black; padding:10px; cursor: pointer">Assay</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each negMarkerList as marker}
                                <tr style="background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <td style="border: 1px solid black; padding:10px;">{marker.marker_display}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">{marker.mutation_type || ""}</td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each marker.time_course as tc, index}
                                            {#if index > 0}<br/>{/if}
                                            {tc.assay_name} ({assayMap[tc.assay_name]})
                                        {/each}
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else if view === 'card'}
                        <h3 style="display:flex; justify-content: flex-start">Sort</h3>
                        <div style="margin: 0 25px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 50vw;">
                            <label style="flex: 0 1 auto; text-align: left;">
                                <input type="checkbox" on:change={() => sortCardsBy('alphabetical')} checked={sortCards === 'alphabetical'}/>
                                Alphabetical
                            </label>
                            <label style="flex: 0 1 auto; text-align: left;">
                                <input type="checkbox" on:change={() => sortCardsBy('in house biomarker')} checked={sortCards === 'in house biomarker'}/>
                                Actionable Trials - In House
                            </label>
                            <label style="flex: 0 1 auto; text-align: left;">
                                <input type="checkbox" on:change={() => sortCardsBy('outside bio trials')} checked={sortCards === 'outside bio trials'}/>
                                Actionable Trials - Outside
                            </label>
                            <label style="flex: 0 1 auto; text-align: left;">
                                <input type="checkbox" on:change={() => sortCardsBy('freq')} checked={sortCards === 'freq'}/> Highest
                                Frequency
                            </label>
                        </div>

                        <h3> Significant Alterations</h3>
                        <div style="display: grid; grid-template-columns: repeat({rightBiomarkerDetailPaneState === 'open' ? 2 : 3}, 1fr); gap: 10px;">
                            {#each sigMarkerList as marker}
                                <div style="width: 400px; border: 1px solid black; border-radius: 10px; padding: 10px; margin: 10px; background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <div class="tooltip-container">
                                        {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                            <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                  style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                            <span class="tooltip-text">Unsaved</span>
                                        {/if}
                                    </div>
                                    <strong>{marker.marker_display}</strong>
                                    <table style="width: 100%; border-collapse: collapse;">
                                        <thead>
                                        <tr style="text-align: center;">
                                            <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTrials');
                                                allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[]});}}>
                                                {#if (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0)}
                                                    <Icon data="{faCircleCheck}" scale="1.75" style="color:#61e70d;" />
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTherapies');
                                                therapyFilters.set({"Biomarkers":[marker.marker_display]})}}>
                                                {#if (($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)}
                                                    <img src="static/images/therapyIcon.png" class="icon-png" alt="">
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        <img src="static/images/discordance_icon.png" class="icon-png" alt=""
                                                             on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker); interpretationDropdown=true}}>
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                <img src="static/images/lowFreqIcon.png" class="icon-png" alt="">
                                            </td>
                                            <td style="text-align: center;">
                                                <img src="static/images/exploreMoreIcon.png" class="icon-png" alt=""
                                                     on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="text-align: center; vertical-align: top; font-size: 12px;">
                                            <td>
                                                {$markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0} Trials<br>{$markerMatchesMap[marker.marker_display]?.instTrials.length || 0} {getDefaultInstitution()}
                                            </td>
                                            <td>
                                                {$senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                level 1<br>Therapies
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        Discordant<br>Interpretation
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                Rarely Seen
                                            </td>
                                            <td style="text-align: center;">
                                                Explore More
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div style="text-align: left">
                                        {#if (((($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)) || (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0))}
                                            <p><strong style="color:green">Actionable</strong> in Patients Tumor Types and
                                                Other
                                                Tumor Types</p>
                                        {:else}
                                            <p><strong style="color:red">Not Actionable</strong> not in Patients Tumor Types
                                                and
                                                Other Tumor Types</p>
                                        {/if}
                                        {#if ($selectedCase.assay_names && $selectedCase.assay_names.length > 1)}
                                            <p><strong
                                                    style="color:green">Detected </strong> {getTestComparison(marker).detected.join(', ') || ''}
                                            </p>
                                            <p><strong style="color:red">Not
                                                Detected </strong> {getTestComparison(marker).not_detected.join(', ') || ''}
                                            </p>
                                            <p><strong style="color:gold">Not
                                                Tested </strong> {getTestComparison(marker).not_tested.join(', ') || ''}</p>
                                        {:else}
                                            <p><strong>Assay </strong> {$selectedCase.assay_names.join(', ') || ''}</p>
                                        {/if}

                                        {#if marker.jax_ckb && marker.jax_ckb.effect}
                                            <p>
                                                <strong>JAX-CKB: </strong> {toTitleCase(marker.jax_ckb.effect.replace(/_/g, " ")).replace("Of", "of") || ''}
                                            </p>
                                        {/if}
                                        {#if (marker.clinvar.length > 0)}
                                            <p>
                                                <strong>Clinvar:</strong> {marker.clinvar[0].CLNSIG__mstring}
                                                <a href={`https://www.ncbi.nlm.nih.gov/clinvar/variation/${marker.clinvar[0].variant_ID__string}`}
                                                   target="_blank" rel="noopener noreferrer">
                                                    {marker.clinvar[0].variant_ID__string}
                                                </a>
                                            </p>
                                        {/if}
                                        {#if marker.gene_type}
                                            <p><strong>Gene Type: </strong>{marker.gene_type}</p>
                                        {/if}
                                        {#if marker.cancerhotspots.length > 0}
                                            {#if marker.cancerhotspots[0].is_hotspot__boolean}
                                                <p><strong>Hotspot: </strong>Yes</p>
                                            {:else}
                                                <p><strong>Hotspot: </strong>FALSE</p>
                                            {/if}
                                        {/if}
                                    </div>
                                </div>
                            {/each}
                        </div>
                        <h3> Variants of Uncertain Significance</h3>
                        <div style="display: grid; grid-template-columns: repeat({rightBiomarkerDetailPaneState === 'open' ? 2 : 3}, 1fr); gap: 10px;">
                            {#each vusMarkerList as marker}
                                <div style="width: 400px; border: 1px solid black; border-radius: 10px; padding: 10px; margin: 10px;">
                                    <div class="tooltip-container">
                                        {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                            <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                  style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                            <span class="tooltip-text">Unsaved</span>
                                        {/if}
                                    </div>
                                    <strong>{marker.marker_display}</strong>
                                    <table style="width: 100%; border-collapse: collapse;">
                                        <thead>
                                        <tr style="text-align: center;">
                                            <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTrials');
                                                allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[marker.marker_display],"Diseases":[],"Phase":[],"Drugs":[]});}}>
                                                {#if (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0)}
                                                    <Icon data="{faCircleCheck}" scale="1.75" style="color:#61e70d;"/>
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTherapies');
                                                therapyFilters.set({"Biomarkers":[marker.marker_display]})}}>
                                                {#if (($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)}
                                                    <img src="static/images/therapyIcon.png" class="icon-png" alt="">
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        <img src="static/images/discordance_icon.png" class="icon-png" alt=""
                                                             on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker); interpretationDropdown=true}}>
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                <img src="static/images/lowFreqIcon.png" class="icon-png" alt="">
                                            </td>
                                            <td style="text-align: center;">
                                                <img src="static/images/exploreMoreIcon.png" class="icon-png" alt=""
                                                     on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="text-align: center; vertical-align: top; font-size: 12px;">
                                            <td>
                                                {$markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0}
                                                Trials<br>{$markerMatchesMap[marker.marker_display]?.instTrials.length || 0} {getDefaultInstitution()}
                                            </td>
                                            <td>
                                                {$senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                level 1<br>Therapies
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        Discordant<br>Interpretation
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                Rarely Seen
                                            </td>
                                            <td style="text-align: center;">
                                                Explore More
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div style="text-align: left">
                                        {#if (((($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)) || (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0))}
                                            <p><strong style="color:green">Actionable</strong> in Patients Tumor Types and
                                                Other
                                                Tumor Types</p>
                                        {:else}
                                            <p><strong style="color:red">Not Actionable</strong> not in Patients Tumor Types
                                                and
                                                Other Tumor Types</p>
                                        {/if}
                                        {#if ($selectedCase.assay_names && $selectedCase.assay_names.length > 1)}
                                            <p><strong
                                                    style="color:green">Detected </strong> {getTestComparison(marker).detected.join(', ') || ''}
                                            </p>
                                            <p><strong style="color:red">Not
                                                Detected </strong> {getTestComparison(marker).not_detected.join(', ') || ''}
                                            </p>
                                            <p><strong style="color:gold">Not
                                                Tested </strong> {getTestComparison(marker).not_tested.join(', ') || ''}</p>
                                        {:else}
                                            <p><strong>Assay </strong> {$selectedCase.assay_names.join(', ') || ''}</p>
                                        {/if}
                                        {#if marker.jax_ckb && marker.jax_ckb.effect}
                                            <p>
                                                <strong>JAX-CKB: </strong> {toTitleCase(marker.jax_ckb.effect.replace(/_/g, " ")).replace("Of", "of") || ''}
                                            </p>
                                        {/if}
                                        {#if (marker.clinvar.length > 0)}
                                            <p><strong>Clinvar:</strong> {marker.clinvar[0].variant_ID__string}
                                                - {marker.clinvar[0].CLNSIG__mstring}</p>
                                        {/if}
                                        {#if marker.gene_type}
                                            <p><strong>Gene Type: </strong>{marker.gene_type}</p>
                                        {/if}
                                        {#if marker.cancerhotspots.length > 0}
                                            {#if marker.cancerhotspots[0].is_hotspot__boolean}
                                                <p><strong>Hotspot: </strong>Yes</p>
                                            {:else}
                                                <p><strong>Hotspot: </strong>No</p>
                                            {/if}
                                        {/if}
                                    </div>
                                </div>
                            {/each}
                        </div>
                        <h3> Pertinent Negatives</h3>
                        <div style="display: grid; grid-template-columns: repeat({rightBiomarkerDetailPaneState === 'open' ? 2 : 3}, 1fr); gap: 10px;">
                            {#each negMarkerList as marker}
                                <div style="width: 400px; border: 1px solid black; border-radius: 10px; padding: 10px; margin: 10px;">
                                    <strong>{marker.marker_display}</strong>
                                    <table style="width: 100%; border-collapse: collapse;">
                                        <thead>
                                        <tr style="text-align: center;">
                                            <td>
                                                {#if (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0)}
                                                    <Icon data="{faCircleCheck}" scale="1.75" style="color:#61e70d;"/>
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTherapies');
                                                therapyFilters.set({"Biomarkers":[marker.marker_display]})}}>
                                                {#if (($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)}
                                                    <img src="static/images/therapyIcon.png" class="icon-png" alt="">
                                                {:else}
                                                    <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                                {/if}
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        <img src="static/images/discordance_icon.png" class="icon-png" alt=""
                                                             on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker); interpretationDropdown=true}}>
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                <img src="static/images/lowFreqIcon.png" class="icon-png" alt="">
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="text-align: center; vertical-align: top; font-size: 12px;">
                                            <td>
                                                {$markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0}
                                                Trials<br>{$markerMatchesMap[marker.marker_display]?.instTrials.length || 0} {getDefaultInstitution()}
                                            </td>
                                            <td>
                                                {$senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length}
                                                level 1<br>Therapies
                                            </td>
                                            {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                                {#if (marker.discordant_interpretation || false)}
                                                    <td style="text-align: center;">
                                                        Discordant<br>Interpretation
                                                    </td>
                                                {/if}
                                            {/if}
                                            <td style="text-align: center;">
                                                Rarely Seen
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div style="text-align: left">
                                        {#if (((($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(marker.marker_display)).length) > 0)) || (($markerMatchesMap[marker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[marker.marker_display]?.instTrials.length || 0) > 0))}
                                            <p><strong style="color:green">Actionable</strong> in Patients Tumor Types and
                                                Other
                                                Tumor Types</p>
                                        {:else}
                                            <p><strong style="color:red">Not Actionable</strong> not in Patients Tumor Types
                                                and
                                                Other Tumor Types</p>
                                        {/if}
                                        <p><strong
                                                style="color:green">Detected </strong> {getTestComparison(marker).detected.join(', ') || ''}
                                        </p>
                                        <p><strong style="color:red">Not
                                            Detected </strong> {getTestComparison(marker).not_detected.join(', ') || ''}</p>
                                        <p><strong style="color:gold">Not
                                            Tested </strong> {getTestComparison(marker).not_tested.join(', ') || ''}</p>
                                        {#if marker.jax_ckb && marker.jax_ckb.effect}
                                            <p>
                                                <strong>JAX-CKB: </strong> {toTitleCase(marker.jax_ckb.effect.replace(/_/g, " ")).replace("Of", "of") || ''}
                                            </p>
                                        {/if}
                                        {#if (marker.clinvar.length > 0)}
                                            <p><strong>Clinvar:</strong> {marker.clinvar[0].variant_ID__string}
                                                - {marker.clinvar[0].CLNSIG__mstring}</p>
                                        {/if}
                                        {#if marker.gene_type}
                                            <p><strong>Gene Type: </strong>{marker.gene_type}</p>
                                        {/if}
                                        {#if marker.cancerhotspots.length > 0}
                                            {#if marker.cancerhotspots[0].is_hotspot__boolean}
                                                <p><strong>Hotspot: </strong>Yes</p>
                                            {:else}
                                                <p><strong>Hotspot: </strong>No</p>
                                            {/if}
                                        {/if}
                                    </div>
                                </div>
                            {/each}
                        </div>
                    {:else if view === 'comparison'}
                        <h3 style="text-align: left">Significant Alterations ({sigMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px;">Variant</th>
                                <th style="border: 1px solid black; padding:10px;">Assay/Status</th>
                                <th style="border: 1px solid black; padding:10px;">Explore Details</th>
                                <th style="border: 1px solid black; padding:10px;">Card View</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each sigMarkerList as marker}
                                <tr style="background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div class="tooltip-container">
                                            {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                                <span class="tooltip-text">Unsaved</span>
                                            {/if}
                                        </div>
                                        {marker.marker_display}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each getTestComparison(marker).detected || [] as detectedAssay}
                                            <p><strong>{detectedAssay}: </strong> Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_detected || [] as notDetectedAssay}
                                            <p><strong>{notDetectedAssay}: </strong> Not Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_tested || [] as notTestedAssay}
                                            <p><strong>{notTestedAssay}: </strong> Not Tested</p>
                                        {/each}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px; width:50px;">
                                        <div on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            <p class="hover-underline">Detail View</p>
                                        </div>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div on:click={() => {singleCardMarker=marker; setView('single_card')}}>
                                            <img src="static/images/exploreAllResultsIcon.png"
                                                 class="icon-png" alt="">
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                        <h3 style="text-align: left">Uncertain Alterations ({vusMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px;">Variant</th>
                                <th style="border: 1px solid black; padding:10px;">Assay/Status</th>
                                <th style="border: 1px solid black; padding:10px;">Explore Details</th>
                                <th style="border: 1px solid black; padding:10px;">Card View</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each vusMarkerList as marker}
                                <tr style="background-color: {selectedMarker && selectedMarker.marker_display && marker.marker_display === selectedMarker.marker_display ? '#ffecb3' : 'transparent'}">
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div class="tooltip-container">
                                            {#if (unsavedMarkers.some(([key, value]) => key === marker.marker_display))}
                                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                                <span class="tooltip-text">Unsaved</span>
                                            {/if}
                                        </div>
                                        {marker.marker_display}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each getTestComparison(marker).detected || [] as detectedAssay}
                                            <p><strong>{detectedAssay}: </strong> Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_detected || [] as notDetectedAssay}
                                            <p><strong>{notDetectedAssay}: </strong> Not Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_tested || [] as notTestedAssay}
                                            <p><strong>{notTestedAssay}: </strong> Not Tested</p>
                                        {/each}
                                    </td>
                                    <td style="border: 1px solid black; padding:10px; width:50px;">
                                        <div on:click={() => {rightBiomarkerDetailPaneState='open'; selectedMarker=marker; gatherMarkerDetails(marker)}}>
                                            <p class="hover-underline">Detail View</p>
                                        </div>
                                    </td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        <div on:click={() => {singleCardMarker=marker; setView('single_card')}}>
                                            <img src="static/images/exploreAllResultsIcon.png"
                                                 class="icon-png" alt="">
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                        <h3 style="text-align: left">Pertinent Negatives ({negMarkerList.length || 0})</h3>
                        <table style="border: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid black; padding:10px;">Variant</th>
                                <th style="border: 1px solid black; padding:10px;">Assay/Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each negMarkerList as marker}
                                <tr>
                                    <td style="border: 1px solid black; padding:10px;">{marker.marker_display}</td>
                                    <td style="border: 1px solid black; padding:10px;">
                                        {#each getTestComparison(marker).detected || [] as detectedAssay}
                                            <p><strong>{detectedAssay}: </strong> Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_detected || [] as notDetectedAssay}
                                            <p><strong>{notDetectedAssay}: </strong> Not Detected</p>
                                        {/each}
                                        {#each getTestComparison(marker).not_tested || [] as notTestedAssay}
                                            <p><strong>{notTestedAssay}: </strong> Not Tested</p>
                                        {/each}
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else if view === 'single_card'}
                        <div>
                            <div style="width: 425px; display: flex; justify-content: flex-end; cursor: pointer;"
                                 on:click={() => {setView("table")}}>
                                <Icon data="{faCircleXmark}" scale="1.75" style="color:#3A63B0;"/>
                            </div>
                            <div style="width: 400px; border: 1px solid black; border-radius: 10px; padding: 10px; margin: 10px;">
                                <div class="tooltip-container">
                                    {#if (unsavedMarkers.some(([key, value]) => key === singleCardMarker.marker_display))}
                                        <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                              style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                        <span class="tooltip-text">Unsaved</span>
                                    {/if}
                                </div>
                                <strong>{singleCardMarker.marker_display}</strong>
                                <table style="width: 100%; border-collapse: collapse;">
                                    <thead>
                                    <tr style="text-align: center;">
                                        <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTrials');
                                                allTrialfilters.set({"NCTID":[],"ProtocolID":[],"Biomarkers":[singleCardMarker.marker_display],"Diseases":[],"Phase":[],"Drugs":[]});}}>
                                            {#if (($markerMatchesMap[singleCardMarker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[singleCardMarker.marker_display]?.instTrials.length || 0) > 0)}
                                                <Icon data="{faCircleCheck}" scale="1.75" style="color:#61e70d;"/>
                                            {:else}
                                                <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                            {/if}
                                        </td>
                                        <td on:click={() => {
                                                pane.set('main');
                                                openMatchResults.set(true);
                                                matchTableSetting.set('allTherapies');
                                                therapyFilters.set({"Biomarkers":[singleCardMarker.marker_display]})}}>
                                            {#if (($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length) > 0)}
                                                <img src="static/images/therapyIcon.png" class="icon-png" alt="">
                                            {:else}
                                                <Icon data="{faBan}" scale="1.75" style="color:red"/>
                                            {/if}
                                        </td>
                                        {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                            {#if (singleCardMarker.discordant_interpretation || false)}
                                                <td style="text-align: center;">
                                                    <img src="static/images/discordance_icon.png" class="icon-png" alt=""
                                                         on:click={() => {rightBiomarkerDetailPaneState='open'; singleCardMarker=singleCardMarker; gatherMarkerDetails(singleCardMarker); interpretationDropdown=true}}>
                                                </td>
                                            {/if}
                                        {/if}
                                        <td style="text-align: center;">
                                            <img src="static/images/lowFreqIcon.png" class="icon-png" alt="">
                                        </td>
                                        <td style="text-align: center;">
                                            <img src="static/images/exploreMoreIcon.png" class="icon-png" alt=""
                                                 on:click={() => {rightBiomarkerDetailPaneState='open'; singleCardMarker=singleCardMarker; gatherMarkerDetails(singleCardMarker)}}>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="text-align: center; vertical-align: top; font-size: 12px;">
                                        <td>
                                            {$markerMatchesMap[singleCardMarker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[singleCardMarker.marker_display]?.instTrials.length || 0}
                                            Trials<br>{$markerMatchesMap[singleCardMarker.marker_display]?.instTrials.length || 0} {getDefaultInstitution()}
                                        </td>
                                        <td>
                                            {$senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length}
                                            level 1<br>Therapies
                                        </td>
                                        {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                            {#if (singleCardMarker.discordant_interpretation || false)}
                                                <td style="text-align: center;">
                                                    Discordant<br>Interpretation
                                                </td>
                                            {/if}
                                        {/if}
                                        <td style="text-align: center;">
                                            Rarely Seen
                                        </td>
                                        <td style="text-align: center;">
                                            Explore More
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style="text-align: left">
                                    {#if (((($senOTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $resTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length + $senFTTTherapies.filter(therapy => therapy.trigger_alteration_display.includes(singleCardMarker.marker_display)).length) > 0)) || (($markerMatchesMap[singleCardMarker.marker_display]?.outsideTrials.length || 0 + $markerMatchesMap[singleCardMarker.marker_display]?.instTrials.length || 0) > 0))}
                                        <p><strong style="color:green">Actionable</strong> in Patients Tumor Types and
                                            Other
                                            Tumor Types</p>
                                    {:else}
                                        <p><strong style="color:red">Not Actionable</strong> not in Patients Tumor Types
                                            and
                                            Other Tumor Types</p>
                                    {/if}
                                    <p><strong
                                            style="color:green">Detected </strong> {getTestComparison(singleCardMarker).detected.join(', ') || ''}
                                    </p>
                                    <p><strong style="color:red">Not
                                        Detected </strong> {getTestComparison(singleCardMarker).not_detected.join(', ') || ''}
                                    </p>
                                    <p><strong style="color:gold">Not
                                        Tested </strong> {getTestComparison(singleCardMarker).not_tested.join(', ') || ''}
                                    </p>
                                    {#if singleCardMarker.jax_ckb}
                                        <p>
                                            <strong>JAX-CKB: </strong> {singleCardMarker.jax_ckb.effect || ''} {singleCardMarker.jax_ckb.description || ''}
                                        </p>
                                    {/if}
                                    {#if (singleCardMarker.clinvar.length > 0)}
                                        <p>
                                            <strong>Clinvar:</strong> {singleCardMarker.clinvar[0].CLNSIG__mstring}
                                            <a href={`https://www.ncbi.nlm.nih.gov/clinvar/variation/${singleCardMarker.clinvar[0].variant_ID__string}`}
                                               target="_blank" rel="noopener noreferrer">
                                                {singleCardMarker.clinvar[0].variant_ID__string}
                                            </a>
                                        </p>
                                    {/if}
                                    {#if singleCardMarker.gene_type}
                                        <p><strong>Gene Type: </strong>{singleCardMarker.gene_type}</p>
                                    {/if}
                                    {#if singleCardMarker.cancerhotspots.length > 0}
                                        {#if singleCardMarker.cancerhotspots[0].is_hotspot__boolean}
                                            <p><strong>Hotspot: </strong>Yes</p>
                                        {:else}
                                            <p><strong>Hotspot: </strong>No</p>
                                        {/if}
                                    {/if}
                                </div>
                            </div>
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    </left>
    <right slot="right">
        {#if (rightBiomarkerDetailPaneState === 'open')}
            <div style="text-align: left; margin-left: 25px; margin-right: 25px; max-height:67vh; overflow: scroll">
                <div style="display:flex; justify-content: space-between; align-items: center;">
                    <h3>{selectedMarker.marker_display}</h3>
                    <div style="cursor:pointer;"
                         on:click={() => {rightBiomarkerDetailPaneState = "closed"; selectedMarker={}}}>
                        <Icon data="{faCircleXmark}" scale="1.75" style="color:#3A63B0;"/>
                    </div>
                </div>
                {#if (selectedMarker.discordant_interpretation || false)}
                    <p style="margin-left: 15px; margin-top: -15px;">
                        Vendor: {selectedMarker.vendor_significance}<br>
                        GO: {selectedMarker.vie_significance}
                    </p>
                {:else}
                    <p style="margin-left: 15px; margin-top: -15px;">
                        {#if (!selectedMarker.is_negative_alt && !selectedMarker.is_variant_of_uncertain_significance)}
                            Clinically Significant
                        {:else if (selectedMarker.is_variant_of_uncertain_significance)}
                            Variant of Uncertain Significance
                        {/if}
                    </p>
                {/if}
                {#if (selectedMarker.discordant_interpretation || false)}
                    <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                         on:click={()=>{interpretationDropdown = !interpretationDropdown}}>
                        <h3 style="margin-right:10px">GO Interpretation Logic</h3>
                        {#if interpretationDropdown}
                            <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                        {:else}
                            <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                        {/if}
                    </div>
                {/if}
                {#if interpretationDropdown}
                    <div style="margin-left: 25px;">
                        <p>
                            <strong>GO Reason</strong> {selectedMarker.final_interpretation_reason}
                        </p>
                        <p>
                            <strong>Interpretation Path</strong> {selectedMarker.interpretation_path}
                        </p>
                    </div>
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{editSigDropdown = !editSigDropdown}}>
                    <h3 style="margin-right:10px">Edit Significance</h3>
                    {#if editSigDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if editSigDropdown}
                    <div class="significance-edit">
                        <div class="sig-radio-btn">
                            <label>
                                <input type="radio" name="significance" value="false"
                                       checked={!selectedMarker.is_variant_of_uncertain_significance}> Significant
                            </label>
                            <label>
                                <input type="radio" name="significance" value="true"
                                       checked={selectedMarker.is_variant_of_uncertain_significance}> Uncertain
                            </label>
                        </div>
                        <div class="sig-submit-btn">

                            <button type="submit" on:click={()=>{handleSignificanceSubmit(selectedMarker)}}> Submit
                            </button>

                        </div>
                    </div>
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{genomicDropdown = !genomicDropdown}}>
                    <h3 style="margin-right:10px">Genomic Details</h3>
                    {#if genomicDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if genomicDropdown}
                    <div style="margin-left: 25px;">
                        {#if selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.hgvs_g}
                            <p>
                                <strong>HGVS_G: </strong>{selectedMarker.variant_hgvs.hgvs_g}
                            </p>
                        {/if}
                        {#if selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.canonical_transcript && selectedMarker.variant_hgvs.canonical_transcript.hgvs_c}
                            <p>
                                <strong>HGVS_C: </strong>{selectedMarker.variant_hgvs.canonical_transcript.hgvs_c}
                            </p>
                        {/if}
                        {#if selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.canonical_transcript && selectedMarker.variant_hgvs.canonical_transcript.hgvs_p}
                            <p>
                                <strong>HHGVS_P: </strong>{selectedMarker.variant_hgvs.canonical_transcript.hgvs_p}
                            </p>
                        {/if}
                        {#if selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.canonical_transcript && selectedMarker.variant_hgvs.canonical_transcript.tx_ac}
                            <p>
                                <strong>Canonical
                                    Transcript: </strong>{selectedMarker.variant_hgvs.canonical_transcript.tx_ac}
                            </p>
                        {/if}
                        {#if selectedMarker.variant_hgvs && selectedMarker.variant_hgvs.csra}
                            <p>
                                <strong>CSRA: </strong>{selectedMarker.variant_hgvs.csra}
                            </p>
                        {/if}
                    </div>
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{freqDropdown = !freqDropdown}}>
                    <h3 style="margin-right:10px">Frequency</h3>
                    {#if freqDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if freqDropdown}
                    <div style="margin-left: 25px;">
                        <p><strong>GENIE:</strong></p>
                        {#if (selectedMarker.genie_data.length > 0)}
                            <p style="margin: 0 0 0 10px">
                                <strong>Count: </strong>{selectedMarker.genie_data[0].count__int || "N/A"}<br>
                                <strong>Solid
                                    Count: </strong>{selectedMarker.genie_data[0].solid_count__int || "N/A"}<br>
                                <strong>Disease: </strong><br>
                                {#if (selectedMarker.genie_data[0].diseases__mstring.length > 0)}
                                    <ul style="margin: 0">
                                        {#each selectedMarker.genie_data[0].diseases__mstring as diseaseStr}
                                            <li>
                                                {diseaseStr}
                                            </li>
                                        {/each}
                                    </ul>
                                {/if}
                            </p>
                        {/if}
                        {#if selectedMarker.internalHits && selectedMarker.internalHits !== 0}
                            <p><strong>{getDefaultInstitution()}:</strong> {selectedMarker.internalHits}</p>
                        {/if}
                    </div>
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{narDropdown = !narDropdown}}>
                    <h3 style="margin-right:10px">Narrative Content</h3>
                    {#if narDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if narDropdown}
                    <div style="margin-left: 25px;">
                        {#if $contentsMatches[selectedMarker.marker_display]}
                            {#if $contentsMatches[selectedMarker.marker_display]["Gene Description"]}
                                <p><strong>Gene
                                    Description: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Gene Description"]}
                                </p>
                            {/if}
                            {#if $contentsMatches[selectedMarker.marker_display]["Mutation Description"]}
                                <p><strong>Mutation
                                    Description: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Mutation Description"]}
                                </p>
                            {/if}
                            {#if $contentsMatches[selectedMarker.marker_display]["Mutation Location"]}
                                <p><strong>Mutation
                                    Location: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Mutation Location"]}
                                </p>
                            {/if}
                            {#if $contentsMatches[selectedMarker.marker_display]["Pathway Snippet"]}
                                <p>
                                    <strong>Pathway: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Pathway Snippet"]}
                                </p>
                            {/if}
                            {#if $contentsMatches[selectedMarker.marker_display]["Gene Mutation Prevalence"]}
                                <p><strong>Gene
                                    Prevalance: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Gene Mutation Prevalence"]}
                                </p>
                            {/if}
                            {#if $contentsMatches[selectedMarker.marker_display]["Variant Mutation Prevalence"]}
                                <p><strong>Mutation
                                    Frequency: </strong> {@html $contentsMatches[selectedMarker.marker_display]["Variant Mutation Prevalence"]}
                                </p>
                            {/if}
                        {/if}
                    </div>
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{annDropdown = !annDropdown}}>
                    <h3 style="margin-right:10px">Anotations </h3>
                    {#if annDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if annDropdown}
                    {#if (additionalDetailsLoading)}
                        <Loader/>
                    {:else}
                        <div style="margin-left: 25px;">
                            <p><strong>Clinvar:</strong></p>
                            {#if (selectedMarker.clinvar.length > 0)}
                                <p style="margin-left: 25px;">
                                    {selectedMarker.clinvar[0].variant_ID__string}
                                    - {selectedMarker.clinvar[0].CLNSIG__mstring}
                                </p>
                            {/if}
                            <p><strong>GNOMAD:</strong></p>
                            {#if additionalMarkerDetails.annotationsMatch && additionalMarkerDetails.annotationsMatch['annotations'] && additionalMarkerDetails.annotationsMatch['annotations']['GNOMAD'] && additionalMarkerDetails.annotationsMatch['annotations']['GNOMAD'].length > 0 && additionalMarkerDetails.annotationsMatch['annotations']['GNOMAD'][0]['AF__float']}
                                <p style="margin-left: 25px;">{additionalMarkerDetails.annotationsMatch['annotations']['GNOMAD'][0]['AF__float'] || 0.0}</p>
                            {:else}
                                <p style="margin-left: 25px;">0.0</p>
                            {/if}
                            <p><strong>JAX-CKB: </strong></p>
                            {#each additionalMarkerDetails.proteinEffects as pe}
                                {#if (pe.data_set === "JAX-CKB")}
                                    <p style="margin-left: 25px;">
                                        <strong>{pe.effect || ''}</strong> {pe.description || ''}</p>
                                {/if}
                            {/each}
                            <p><strong>Gene Type: </strong></p>
                            {#if selectedMarker.gene_type}
                                <p style="margin-left: 25px;">{selectedMarker.gene_type}</p>
                            {/if}
                            <p><strong>Hotspot: </strong></p>
                            {#if selectedMarker.cancerhotspots.length > 0}
                                {#if selectedMarker.cancerhotspots[0].is_hotspot__boolean}
                                    <p style="margin-left: 25px;">Yes</p>
                                {:else}
                                    <p style="margin-left: 25px;">FALSE</p>
                                {/if}
                            {/if}
                            <p><strong>Uniprot: </strong></p>
                            {#if selectedMarker.uniprot.length > 0}
                                <p style="margin-left: 25px;">
                                    <strong>Protein:</strong> {selectedMarker.uniprot[0].protein_full_name__string}</p>
                                <p style="margin-left: 25px;"><strong>Domain:</strong> {getDomain(selectedMarker)}</p>
                            {/if}
                        </div>
                    {/if}
                {/if}
                <div style="display:flex; justify-content: start; align-items: center; cursor: pointer"
                     on:click={()=>{linkDropdown = !linkDropdown}}>
                    <h3 style="margin-right:10px">Links</h3>
                    {#if linkDropdown}
                        <Icon data="{faAngleUp}" style="color:#3A63B0; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faAngleDown}" style="color:#3A63B0; cursor:pointer;"/>
                    {/if}
                </div>
                {#if linkDropdown}
                    {#if (alterationDetailsLoading)}
                        <Loader/>
                    {:else}
                        <div style="margin-left: 25px;">
                            <p><a href={generateCosmicUrl(selectedMarker)}
                                  target="_blank">COSMIC</a></p>
                            {#if alterationDetails[0].test_mode === "SNV"}
                                <p><a href={generateOncoKBUrl()}
                                      target="_blank">OncoKB</a></p>
                            {/if}
                            {#if alterationDetails[0].gene}
                                <p><a href={generateCBioPortalUrl(selectedMarker)}
                                      target="_blank">cBioPortal</a></p>
                            {/if}
                            {#if alterationDetails[0].gene}
                                <p><a href={generateMasterMindLink()}
                                      target="_blank">Mastermind</a></p>
                            {/if}
                            {#if alterationDetails[0].gene}
                                <p><a href={generateVICCGeneLink()}
                                      target="_blank">VICC Meta-Knowledgebse - Gene</a></p>
                                <p><a href={generateVICCVariantLink(selectedMarker)}
                                      target="_blank">VICC Meta-Knowledgebse - Variant</a></p>
                            {/if}
                            <p><a href={generateVarseakLink(selectedMarker)}
                                  target="_blank">Varseak</a></p>
                            <p><a href={generateVarsomeLink(selectedMarker)}
                                  target="_blank">Varsome</a></p>
                            <p><a href={generateGoogleLink()}
                                  target="_blank">Google</a></p>
                        </div>
                    {/if}
                {/if}
            </div>
        {/if}
    </right>
</HSplitPane>

<style>


    .icon-png {
        width: 40px;
        cursor: pointer;
    }

    .save-button {
        margin-right: 25px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .marker-string h3,
    .marker-string p {
        display: inline-block;
        margin: 0;
        padding-right: 10px;
    }

    .hover-underline {
        cursor: pointer;
        text-decoration: none;
    }

    .hover-underline:hover {
        text-decoration: underline;
        color: #233C6B;
    }

    .biomarker-detail-pane {
        padding: 20px;
    }

    table {
        text-align: left;
    }

    .significance-edit {
        display: flex;
        align-items: center;
    }

    .sig-radio-btn {

    }

    .sig-submit-btn {
        margin-left: 10px;
    }

    .tooltip-container {
        position: relative;
        display: inline-block;
    }

    .tooltip-text {
        visibility: hidden;
        width: 80px; /* Adjust width as needed */
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -40px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-container:hover .tooltip-icon {
        color: #e79a45;
    }

</style>