<script>
    import {onMount} from 'svelte';
    import {
        getNotifications,
        updateHasViewedNotifications,
        getFilteredAlerts,
        getAlertStatistics
    } from "../../network/user";
    import Loader from "../shared/Loader.svelte";
    import {getUser, session} from "../../store/session";
    import {Icon} from "svelte-awesome";
    import {faFilter, faCircleLeft, faBars, faCaretDown, faCaretLeft} from '@fortawesome/free-solid-svg-icons';
    import {faCircleXmark} from '@fortawesome/free-regular-svg-icons';
    import NotificationPatientTable from './NotificationPatientTable.svelte';
    import Modal from "../Modal.svelte";
    import {getCasesWithUuids} from "../../network/cases";
    import {CASE_FIELDS} from "../../utils/constants";
    import {patientInfoOptions, setDefaultOptions} from '../../store/patientInfoOptions.js';
    import OlderNotificationsTable from "./OlderNotificationsTable.svelte";
    import {HSplitPane} from "svelte-split-pane";
    import NotificationFilterSideBar from "./NotificationFilterSideBar.svelte";
    import {inFilterView, searchQueries} from "../../store/notificationSearchQueries";
    import {
        mainPaneWidth,
        additionalDetailsPaneWidth,
        additionalDetailsPaneContent,
        additionalDetailKey
    } from "../../store/notificationNavigation";
    import AllFilteredNotificationPatientTable from "./AllFilteredNotificationPatientTable.svelte";

    let uuids = '';
    let date = '';
    let name = '';
    let id = '';
    let uuid_to_alert_info_dict = '';
    let queue_status = '';
    let notifications_summary = {};
    let notifications = [];
    let isLoading = false;
    let mostRecentNotifications = [];
    let notificationNamesSet = new Set();
    let isNotificationView = true;
    let ordering = '';
    let filtered_ordering = '';
    let caseExportModal;
    let statExportModal;
    let exportData = [];
    let leftPaneWidth = "0%";
    let rightPaneWidth = "100%";
    let hideLeftNav = true;
    let filteredNotificationData = [];
    let alertCount = 0;
    let patientCount = 0;
    let isFilterView;
    let isAllFilteredPatientView = false;
    let isAllFilterExport = false;
    let selectedNotification = null;
    let statsLoading = false;
    let selectedAlertStatistics = {};
    let cachedAlertStatistics = {};
    let selectedNotificationPatientList = [];
    let dateFilteredSelectedNotificationPatientList = [];
    let lastXNotifications = [];
    let startDateRange;
    let endDateRange;
    let lastNumNotifications;
    let inputError = false;
    let cachedNotificationPatients = [];
    let selectedNotificationPatients = {};
    let fetchingNotificationPatients = false;
    let patientNameInput = '';
    let filteredPatients = [];
    let displayPatientNameDropdown = false;
    let displayPatientTable = false;
    let selectedPatient = '';
    let exportDataLoading = false;
    let noPatientsIdentified = false;
    let showNotTrackedReasons = false;
    let notTrackedReasons = [];
    const presetNonTrackOptions = ['age', 'biomarker ineligibility', 'cancer type', 'cardiac', 'medical condition', 'medical history',
        'number of prior therapies', 'performance status inadequate', 'stage of disease'];

    $: isFilterView = $inFilterView;

    $: if ($inFilterView === false) {
        fetchNotifications();
    }

    async function fetchNotifications() {
        isLoading = true;
        notifications_summary = await getNotifications(getUser().id);
        notifications = notifications_summary.results;
        notifications.sort((a, b) => new Date(b.created) - new Date(a.created));
        notifications.forEach(obj => {
            if (!notificationNamesSet.has(obj.name)) {
                notificationNamesSet.add(obj.name);
                mostRecentNotifications.push(obj);
            }
        });
        sortMostRecentNotifications();
        isLoading = false;
    }

    patientInfoOptions.subscribe(value => {
        exportData = value;
    });

    // Function to toggle dropdown visibility
    function toggleDropdown(index) {
        let selectedDropdownState = notifications[index].showDropdown;
        notifications.forEach((notification, i) => {
            notification.showDropdown = false;
        });
        if (selectedDropdownState === undefined) {
            notifications[index].showDropdown = true;
        } else {
            notifications[index].showDropdown = !selectedDropdownState;
        }
    }

    async function fetchStats(alertId) {
        if (cachedAlertStatistics[alertId]) {
            selectedAlertStatistics = cachedAlertStatistics[alertId];
        } else {
            statsLoading = true;
            selectedAlertStatistics = await getAlertStatistics(getUser().id, alertId);
            cachedAlertStatistics[alertId] = selectedAlertStatistics;
            statsLoading = false;
        }
        notTrackedReasons = Object.entries(selectedAlertStatistics.not_tracked_reasons);
    }

    function openAdditionalDetailPane(index, notification, detail) {
        // opening any new detail pane should clear the previous results
        clearAdditionalDetailPane(false);
        selectedNotification = notification;
        getTimeRangePatients();
        if (detail === 'stat') {
            fetchStats(notification.id);
        }
        if (detail === 'find') {
            getPatientNamesFromNotification(notification);
        }
        additionalDetailKey.set(detail + notification.id);
        notifications[index].showDropdown = false;
        mainPaneWidth.set('70%');
        additionalDetailsPaneWidth.set('30%');
        additionalDetailsPaneContent.set(detail);
    }

    function formatParameters(parameters) {
        let params = [];
        let filters = parameters.filters;
        filters.forEach(filter => {
            if ("range" === filter.type) {
                let param = `${filter.operator} of ${filter.field} ${filter.values[0].value} to ${filter.values[1].value}`;
                params = [...params, param];
            } else {
                filter.values.forEach(value => {
                    let param = `${filter.operator} of ${value.value}`;
                    params = [...params, param];
                });
            }
        });
        return params.toString().replaceAll(",", ", ");
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString('en-US', options);
    }

    async function openCaseTable(notification) {
        if (notification.case_uuids.split(",")[0] !== '') {
            setDefaultOptions();
            uuids = notification.case_uuids;
            date = formatDate(notification.created);
            id = notification.id;
            name = notification.name;
            queue_status = notification.queue_status;
            isNotificationView = false;
            handleLeftNavForPatientView();
            clearAdditionalDetailPane(true);
        }
    }

    async function openFilteredCaseTable(notification) {
        if (notification.case_uuids.length > 0) {
            isAllFilteredPatientView = false;
            setDefaultOptions();
            uuids = notification.case_uuids.join(",");
            uuid_to_alert_info_dict = notification.uuid_to_alert_info_dict;
            name = notification.name;
            date = '';
            queue_status = '';
            isNotificationView = false;
            handleLeftNavForPatientView();
            clearAdditionalDetailPane(true);
        }
    }

    async function openAllFilteredCaseTable() {
        if (filteredNotificationData.length > 0) {
            setDefaultOptions();
            isNotificationView = false;
            isAllFilteredPatientView = true;
            handleLeftNavForPatientView();
            clearAdditionalDetailPane(true);
        }
    }

    async function openCaseExportModal(notification) {
        if (notification.case_uuids.split(",")[0] !== '') {
            setDefaultOptions();
            uuids = notification.case_uuids;
            isAllFilterExport = false;
            caseExportModal.open();
        }
    }

    async function openFilteredCaseExportModal(notification) {
        if (notification.case_uuids.length > 0) {
            setDefaultOptions();
            uuids = notification.case_uuids.join(",");
            isAllFilterExport = false;
            caseExportModal.open();
        }
    }

    async function openAllCasesExportModal() {
        if (selectedNotificationPatientList && selectedNotificationPatientList.length > 0) {
            setDefaultOptions();
            uuids = selectedNotificationPatientList.join(',');
            isAllFilterExport = false;
            caseExportModal.open();
        }
    }

    async function openDateRangeCasesExportModal() {
        if (dateFilteredSelectedNotificationPatientList && dateFilteredSelectedNotificationPatientList.length > 0) {
            setDefaultOptions();
            uuids = dateFilteredSelectedNotificationPatientList.join(',');
            isAllFilterExport = false;
            caseExportModal.open();
        }
    }

    async function openPatientCaseExportModal(patient) {
        setDefaultOptions();
        uuids = selectedNotificationPatients[patient];
        isAllFilterExport = false;
        caseExportModal.open();
    }

    async function openLastNumberNotificationExportModal() {
        if (lastXNotifications && lastXNotifications.length > 0) {
            setDefaultOptions();
            uuids = lastXNotifications.join(',');
            isAllFilterExport = false;
            caseExportModal.open();
        }
    }

    function sortNotificationsByDate(notifications) {
        return notifications.sort((a, b) => new Date(b.created) - new Date(a.created));
    }

    async function openAllFilteredCaseExportModal() {
        if (getPatientCount(filteredNotificationData) > 0) {
            setDefaultOptions();
            uuids = getAllCaseUuids(filteredNotificationData);
            isAllFilterExport = true;
            caseExportModal.open();
        }
    }

    function sortNotifications(property) {
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchNotifications();
    }

    function sortFilteredNotifications(property) {
        if (property === filtered_ordering || `-${property}` === filtered_ordering) {
            if (filtered_ordering.startsWith('-')) {
                filtered_ordering = '';
            } else {
                filtered_ordering = `-${property}`;
            }
        } else {
            filtered_ordering = property;
        }
        fetchFilterNotifications();
    }

    function handleLeftNav() {
        hideLeftNav = !hideLeftNav;
        leftPaneWidth = hideLeftNav ? "0%" : "21%";
        rightPaneWidth = hideLeftNav ? "100%" : "79%";
    }

    function handleLeftNavForPatientView() {
        hideLeftNav = true;
        leftPaneWidth = hideLeftNav ? "0%" : "21%";
        rightPaneWidth = hideLeftNav ? "100%" : "79%";
    }

    function clearAdditionalDetailPane(closePane) {
        if (closePane) {
            selectedNotification = null;
            additionalDetailKey.set('');
            additionalDetailsPaneContent.set('');
            additionalDetailsPaneWidth.set('0');
            mainPaneWidth.set('100%');
        }
        startDateRange = '';
        endDateRange = '';
        lastNumNotifications = '';
        inputError = false;
        showNotTrackedReasons = false;
        clearFindPatientDetails();
    }

    function clearFindPatientDetails() {
        patientNameInput = '';
        displayPatientTable = false;
        filteredPatients = [];
        displayPatientNameDropdown = false;
        selectedNotificationPatients = {};
        selectedPatient = '';
    }

    function sortMostRecentNotifications() {
        if (ordering !== '') {
            if (ordering === 'name') {
                mostRecentNotifications.sort((a, b) => a.name.localeCompare(b.name));
            } else if (ordering === '-name') {
                mostRecentNotifications.sort((a, b) => b.name.localeCompare(a.name));
            } else if (ordering === 'created') {
                mostRecentNotifications.sort((a, b) => new Date(b.created) - new Date(a.created));
            } else if (ordering === '-created') {
                mostRecentNotifications.sort((a, b) => new Date(a.created) - new Date(b.created));
            } else if (ordering === 'most_recent_num_results' || ordering === 'total_statuses') {
                mostRecentNotifications.sort((a, b) => getNumCases(a.case_uuids) - getNumCases(b.case_uuids));
            } else if (ordering === '-most_recent_num_results' || ordering === '-total_statuses') {
                mostRecentNotifications.sort((a, b) => getNumCases(b.case_uuids) - getNumCases(a.case_uuids));
            }
        } else {
            mostRecentNotifications.sort((a, b) => new Date(b.created) - new Date(a.created));
        }
    }

    function sortAllFilteredNotifications() {
        if (filtered_ordering !== '') {
            if (filtered_ordering === 'name') {
                filteredNotificationData.sort((a, b) => a.name.localeCompare(b.name));
            } else if (filtered_ordering === '-name') {
                filteredNotificationData.sort((a, b) => b.name.localeCompare(a.name));
            } else if (filtered_ordering === 'num_cases') {
                filteredNotificationData.sort((a, b) => a.case_uuids.length - b.case_uuids.length);
            } else if (filtered_ordering === '-num_cases') {
                filteredNotificationData.sort((a, b) => b.case_uuids.length - a.case_uuids.length);
            }
        }
    }

    function getNumCases(case_uuids) {
        if (case_uuids.split(",")[0] !== '') {
            return case_uuids.split(",").length;
        } else {
            return 0;
        }
    }

    function getMatchingParameters(c) {
        if (c.alert_triggers.length !== 0) {
            let trigger = c.alert_triggers.find(item => item.alert_id === id);
            if (trigger !== undefined) {
                let finalString = "";
                if (trigger.disease_triggers !== "[]") {
                    finalString = finalString + "<b>Diseases:</b> " +
                        trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.alteration_triggers !== "[]") {
                    finalString = finalString + "<br><b>Alterations:</b> " +
                        trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.gene_triggers !== "[]") {
                    finalString = finalString + "<br><b>Genes:</b> " +
                        trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                return finalString;
            }
        }
        return "";
    }

    function getInfoFromCase(c, field) {
        let value = 'N/A';
        if (field in c) {
            value = c[field];
        } else if (c['meta'] !== null && field in c['meta']) {
            value = c['meta'][field];
        } else if (c['patient_information'] !== null && field in c['patient_information'][0]) {
            value = c['patient_information'][0][field];
        }

        if (Array.isArray(value)) {
            if (value.every(value => value === null)) {
                return "N/A";
            } else if (field === 'oncologic_treatments') {
                let drugs = [];
                value.forEach((treatment) => {
                    if (!drugs.find(item => item.drugs === treatment.drugs)) {
                        drugs = [...drugs, treatment.drugs];
                    }
                });
                return Array.from(new Set(drugs)).join(", ");
            } else {
                return Array.from(new Set(value)).join(", ");
            }
        } else {
            if (value === null) {
                return "N/A";
            } else {
                return value;
            }
        }
    }

    function getCase(uuid, cases) {
        return cases.find(caseObj => caseObj.uuid === uuid);
    }

    function clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    const onCaseExport = async () => {
        let response;
        let results;
        exportDataLoading = true;
        if (isAllFilterExport) {
            response = await getCasesWithUuids(CASE_FIELDS, uuids, 1, 1000);
            let case_results = response.results;
            results = [];
            filteredNotificationData.forEach(data => {
                data.case_uuids.forEach(uuid => {
                    let case_info = clone(getCase(uuid, case_results));
                    case_info["alert_name"] = data.uuid_to_alert_info_dict[String(uuid)].notification_name;
                    case_info["notification_date"] = data.uuid_to_alert_info_dict[String(uuid)].notification_date;
                    results = [...results, case_info];
                });
            });
        } else {
            response = await getCasesWithUuids(CASE_FIELDS, uuids, 1, 1000);
            results = response.results;
        }
        await exportCasesToCSV(results);
        caseExportModal.close();
        exportDataLoading = false;
    };

    function generateCSVCaseContent(data) {
        // Define the headers for the CSV
        let headers;
        if (isAllFilterExport) {
            headers = [
                "Full Name",
                "Alert Name",
                "Notification Date",
                "Patient MRN",
                "Ordering Physician",
                "Assay Names",
                "Lab Reported Dates",
                "Matching Parameters"
            ];
        } else {
            headers = [
                "Full Name",
                "Patient MRN",
                "Ordering Physician",
                "Assay Names",
                "Lab Reported Dates",
                "Matching Parameters"
            ];
        }
        exportData.forEach((option) => {
            if (option.visible) {
                headers = [...headers, option.name];
            }
        });

        // Convert each patient's lab results into CSV rows
        const rows = [];
        data.forEach((c) => {
            let row_info;
            if (isAllFilterExport) {
                let full_name = c.patient_name;
                let alert_name = c.alert_name;
                let notification_date = c.notification_date;
                let patient_mrn = c.patient_mrn;
                let ordering_physician = c.patient_information[0].ordering_physician;
                let assay_names = c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
                let reported_dates = Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
                let matching_parameters = getMatchingParameters(c);
                row_info = [
                    full_name || "",
                    alert_name || "",
                    notification_date || "",
                    patient_mrn || "",
                    ordering_physician || "",
                    assay_names || "",
                    reported_dates || "",
                    matching_parameters || ""];
            } else {
                let full_name = c.patient_name;
                let patient_mrn = c.patient_mrn;
                let ordering_physician = c.patient_information[0].ordering_physician;
                let assay_names = c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
                let reported_dates = Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
                let matching_parameters = getMatchingParameters(c);
                row_info = [
                    full_name || "",
                    patient_mrn || "",
                    ordering_physician || "",
                    assay_names || "",
                    reported_dates || "",
                    matching_parameters || ""];
            }
            exportData.forEach((option) => {
                if (option.visible) {
                    row_info = [...row_info, getInfoFromCase(c, option.field)];
                }
            });
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    async function exportCasesToCSV(data) {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVCaseContent(data))}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = "cases.csv";
        link.click();
    }

    const onStatExport = async () => {
        exportDataLoading = true;
        await exportStatToCSV();
        statExportModal.close();
        exportDataLoading = false;
    };

    function generateCSVStatContent() {
        // Define the headers for the CSV
        let headers = [
            "Notification Name",
            "Total Number of Identified Patients",
            "Number of Times Generated",
            "Average Patients Identified Per Notification",
            "Number of Tracked Patients",
            "Number of Waiting Patients",
            "Number of Needs Review Patients",
            "Number of Candidates",
            "Number of Not Tracked Patients",
            "Not Tracked Reason",
            "Not Tracked Reason Count"
        ];

        // Convert each patient's lab results into CSV rows
        const rows = [];
        let first_row_info = [
            selectedAlertStatistics.alert_config_name,
            selectedAlertStatistics.total_patients_identitifed.toString(),
            selectedAlertStatistics.times_generated.toString(),
            selectedAlertStatistics.avg_patient_per_notification.toString(),
            selectedAlertStatistics.number_tracked.toString(),
            selectedAlertStatistics.number_is_waiting.toString(),
            selectedAlertStatistics.number_needs_review.toString(),
            selectedAlertStatistics.number_candidates.toString(),
            selectedAlertStatistics.number_not_tracked.toString()
        ]
        if (notTrackedReasons.length > 0) {
            if (presetNonTrackOptions.includes(notTrackedReasons[0][0])) {
                first_row_info = [...first_row_info, notTrackedReasons[0][0], notTrackedReasons[0][1].toString()];
            } else {
                first_row_info = [...first_row_info, "Alternate Reason", notTrackedReasons[0][0]];
            }
        }
        rows.push(first_row_info);

        if (notTrackedReasons.length > 1) {
            notTrackedReasons.slice(1).forEach((entry) => {
                let row_info = [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    ""
                ];
                if (presetNonTrackOptions.includes(entry[0])) {
                    row_info = [...row_info, entry[0], entry[1].toString()];
                } else {
                    row_info = [...row_info, "Alternate Reason", entry[0]];
                }
                rows.push(row_info);
            });
        }

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    async function exportStatToCSV() {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVStatContent())}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = `${selectedAlertStatistics.alert_config_name}_stats.csv`;
        link.click();
    }

    function getPatientCount(data) {
        let uniqueUUIDs = new Set();

        data.forEach(item => {
            item.case_uuids.forEach(uuid => {
                uniqueUUIDs.add(uuid);
            });
        });

        return Array.from(uniqueUUIDs).length;
    }

    function getAllCaseUuids(data) {
        let uniqueUUIDs = new Set();

        data.forEach(item => {
            item.case_uuids.forEach(uuid => {
                uniqueUUIDs.add(uuid);
            });
        });

        return Array.from(uniqueUUIDs).join(",");
    }

    async function fetchFilterNotifications() {
        isLoading = true;
        filteredNotificationData = await getFilteredAlerts(getUser().id, $searchQueries);
        sortAllFilteredNotifications();
        alertCount = filteredNotificationData.length;
        patientCount = getPatientCount(filteredNotificationData);
        isLoading = false;
        inFilterView.update(storeValue => {
            storeValue = true;
            return storeValue;
        });
    }

    async function fetchResetNotifications() {
        searchQueries.update(storeValue => {
            storeValue.full_name = '';
            storeValue.pos_markers = '';
            storeValue.vus_markers = '';
            storeValue.neg_markers = '';
            storeValue.notification_start_date = '';
            storeValue.notification_end_date = '';
            storeValue.ordering_physician = '';
            storeValue.genes = '';
            storeValue.data_sets = '';
            return storeValue;
        });
        inFilterView.update(storeValue => {
            storeValue = false;
            return storeValue;
        });
        await fetchNotifications();
    }

    async function fetchClearFilters() {
        searchQueries.update(storeValue => {
            storeValue.full_name = '';
            storeValue.pos_markers = '';
            storeValue.vus_markers = '';
            storeValue.neg_markers = '';
            storeValue.notification_start_date = '';
            storeValue.notification_end_date = '';
            storeValue.ordering_physician = '';
            storeValue.genes = '';
            storeValue.data_sets = '';
            return storeValue;
        });
    }

    onMount(() => {
        fetchClearFilters();
        inFilterView.set(false);
        fetchNotifications();
        if (!getUser().has_viewed_notifications) {
            updateHasViewedNotifications();
            session.update(storeValue => {
                storeValue.user.has_viewed_notifications = true;
                return storeValue;
            });
        }
        clearAdditionalDetailPane(true);
    });

    function getNotificationsByName(name, selectedNotification) {
        return notifications.filter(o => o.name === name && o !== selectedNotification);
    }

    function filterNotificationsByDateRange(notifications, startDate, endDate) {
        return notifications.filter(o => {
            const createdDate = new Date(o.created);
            return (!startDate || createdDate >= startDate) && (!endDate || createdDate <= endDate);
        });
    }

    function extractPatientUUIDs(notifications) {
        let patientUUIDs = [];
        notifications.forEach(notification => {
            if (notification.case_uuids) {
                patientUUIDs = [...patientUUIDs, ...notification.case_uuids.split(',')];
            }
        });
        return patientUUIDs;
    }

    function getAllIdentifiedPatients(selectedNotification) {
        // Reset selectedNotificationPatientList
        selectedNotificationPatientList = [];
        let allNotifications = getNotificationsByName(selectedNotification.name, selectedNotification);
        allNotifications = [...allNotifications, selectedNotification];
        selectedNotificationPatientList = extractPatientUUIDs(allNotifications);
        return selectedNotificationPatientList.length;
    }

    function getTimeRangePatients() {
        // Reset dateFilteredSelectedNotificationPatientList
        dateFilteredSelectedNotificationPatientList = [];

        // Parse the start and end range dates
        const startDate = startDateRange ? new Date(startDateRange) : null;
        const endDate = endDateRange ? new Date(endDateRange) : null;

        // Filter notifications based on the date range
        let allNotifications = getNotificationsByName(selectedNotification.name, selectedNotification);
        allNotifications = filterNotificationsByDateRange([...allNotifications, selectedNotification], startDate, endDate);
        dateFilteredSelectedNotificationPatientList = extractPatientUUIDs(allNotifications);
        return dateFilteredSelectedNotificationPatientList.length;
    }

    function getlastXNotifications() {
        if (lastNumNotifications) {
            let allNotifications = getNotificationsByName(selectedNotification.name, selectedNotification);
            allNotifications = [...allNotifications, selectedNotification];
            allNotifications = sortNotificationsByDate(allNotifications);


            // Ensure lastNumNotifications is a valid number and slice the notifications
            if (lastNumNotifications && lastNumNotifications > 0) {
                allNotifications = allNotifications.slice(0, lastNumNotifications);
            }
            lastXNotifications = extractPatientUUIDs(allNotifications);
            return lastXNotifications.length;
        } else {
            lastXNotifications = [];
        }
    }


    function handleIntegerInput(event) {
        const value = event.target.value;
        if (/^\d+$/.test(value) || value === '') {
            inputError = false;
            lastNumNotifications = value;
        } else {
            inputError = true;
        }
    }

    async function getPatientNamesFromNotification(selectedNotification) {
        selectedNotificationPatients = {}; // Reset list
        fetchingNotificationPatients = true;
        selectedNotificationPatientList = [];

        if (!cachedNotificationPatients[selectedNotification.id]) {
            let allNotifications = getNotificationsByName(selectedNotification.name, selectedNotification);
            allNotifications = [...allNotifications, selectedNotification];
            selectedNotificationPatientList = extractPatientUUIDs(allNotifications);
            let caseUuids = selectedNotificationPatientList.join(',');
            if (caseUuids !== '') {
                noPatientsIdentified = false;
                let response = await getCasesWithUuids("patient_name,uuid", caseUuids, 1, 1000);
                response.results.forEach(caseResult => {
                    selectedNotificationPatients[caseResult.patient_name] = caseResult.uuid;
                });
                cachedNotificationPatients[selectedNotification.id] = selectedNotificationPatients;
            }
            else{
                noPatientsIdentified = true;
            }
        } else {
            selectedNotificationPatients = cachedNotificationPatients[selectedNotification.id];
        }
        fetchingNotificationPatients = false;
    }


    function filterPatients() {
        if (patientNameInput) {
            filteredPatients = Object.keys(selectedNotificationPatients)
                .filter(name => name.toLowerCase().includes(patientNameInput.toLowerCase()));
            displayPatientNameDropdown = filteredPatients.length > 0;
        } else {
            filteredPatients = [];
            displayPatientNameDropdown = false;
        }
    }

    function handleInput(event) {
        patientNameInput = event.target.value;
        filterPatients();
    }

    function selectPatient(name) {
        patientNameInput = name;
        displayPatientNameDropdown = false;
    }

    function populatePatientTable() {
        displayPatientTable = true;
        selectedPatient = patientNameInput;
    }

    function updateSearchFromAdditionalDetails(patient_name, notification) {
        setDefaultOptions();
        uuids = selectedNotificationPatients[patient_name];
        id = notification.id;
        name = notification.name;
        isNotificationView = false;
        handleLeftNavForPatientView();
        clearAdditionalDetailPane(true);
    }

    function handleShowNotTrackedinfoClick() {
        showNotTrackedReasons = !showNotTrackedReasons;
    }
</script>
<style>
    .container {
        display: flex;
        height: calc(80vh - 51px);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
        z-index: 999;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr {
        background-color: white;
    }

    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .summary {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .params {
        width: 30%;
    }

    .icon-container {
        display: inline-flex;
        align-items: center;
        background-color: white;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }

    .table-top {
        display: flex;
        align-items: center;
    }

    .right-icon {
        z-index: 999;
        cursor: pointer;
        display: flex;
    }

    .count-box {
        display: inline-block;
        color: black;
        font-size: 18px;
        font-weight: bold;
        margin-left: 50px;
    }

    .view-all-box {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        display: inline-block;
        color: black;
        font-size: 18px;
        font-weight: bold;
        margin-left: 200px;
        background-color: #f0f0f0;
    }

    .export-all-box {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        display: inline-block;
        color: black;
        font-size: 18px;
        font-weight: bold;
        margin-left: 50px;
        background-color: #f0f0f0;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        font-weight: normal;
    }

    .dropdown-toggle {
        cursor: pointer;
        padding: 5px 10px;
        background-color: transparent;
    }

    .dropdown-menu {
        position: absolute;
        top: 50px;
        left: -100px;
        width: 175px;
        z-index: 2;
        border: 1px solid #ccc;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .dropdown-menu-item {
        cursor: pointer;
        padding: 5px 10px;
    }

    .dropdown-menu-item:hover {
        background-color: #f0f0f0;
    }

    .icon-png {
        width: 25px;
        cursor: pointer;
    }

    .icon-png-large {
        width: 40px;
        cursor: pointer;
    }

    .export-icon-png-large {
        width: 50px;
        cursor: pointer;
    }

    .additional-header {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .stat-tr {
        border: none;
    }

    .stat-td-label {
        border: none;
        width: 200px;
    }

    .stat-td {
        border: none;
        vertical-align: top;
        margin-top: 0;
    }

    .prev-th {
        background-color: transparent;
        color: black;
    }

    .search-button {
        background-color: #9d9d9f;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .clear-results {
        background-color: #9d9d9f;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .date-range {
        height: 35px;
    }

    .input-container-dropdown {
        z-index: 99;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 150px;
        overflow-y: auto;
        background-color: white;
        width: 250px;
    }

    .patient-input-item {
        padding: 8px 12px;
        cursor: pointer;
    }

    .patient-input-item:hover {
        background-color: #f0f0f0;
    }

    .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

</style>
<div class="container">
    {#if (hideLeftNav)}
        <HSplitPane bind:leftPaneSize={$mainPaneWidth} bind:rightPaneSize={$additionalDetailsPaneWidth}>
            <left slot="left">
                {#if isNotificationView}
                    {#if isFilterView}
                        <div class="summary">
                            <div class="table-top">
                                <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                                    {#if hideLeftNav}
                                        <p style="margin-bottom: 0">Apply Filters</p>
                                        <Icon data="{faFilter}" scale="1.5"
                                              style="color: #304e82; margin-top: 0"/>
                                    {:else}
                                        <p style="margin-bottom: 0">Close Filter Pane</p>
                                        <Icon data="{faCircleLeft}" scale="1.5"
                                              style="color: #c5c5c7; margin-top: 0"/>
                                    {/if}
                                </div>
                                <div class="count-box">
                                    Alert Count: {alertCount}
                                </div>
                                <div class="count-box">
                                    Patient Count: {patientCount}
                                </div>
                                <div class="view-all-box" on:click={() => openAllFilteredCaseTable()}
                                     aria-hidden="true">
                                    View All
                                </div>
                                <div class="export-all-box" on:click={() => openAllFilteredCaseExportModal()}
                                     aria-hidden="true">
                                    Export All
                                </div>
                            </div>
                            <div class="scrollable-table">
                                {#if isLoading}
                                    <Loader/>
                                {:else}
                                    <table>
                                        <thead>
                                        <tr>
                                            <th on:click={() => sortFilteredNotifications('name')}>
                                                Name
                                                {#if filtered_ordering === 'name'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if filtered_ordering === '-name'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th> Parameters</th>
                                            <th> Shared By</th>
                                            <th on:click={() => sortFilteredNotifications('num_cases')}>
                                                Matching Results
                                                {#if filtered_ordering === 'num_cases'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if filtered_ordering === '-num_cases'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th> View / Export Cases</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {#each filteredNotificationData as alert}
                                            <tr>
                                                <td> {alert.name} </td>
                                                <td class="params"> {formatParameters(alert.alert_parameters)} </td>
                                                <td> {alert.shared_by} </td>
                                                <td>{alert.case_uuids.length}</td>
                                                <td>
                                                    <div class="icon-container">
                                                        <div on:click={() => openFilteredCaseTable(alert)}
                                                             aria-hidden="true" style="margin-right: 30px">
                                                            <img src="static/images/viewCurrentResultsIcon.png"
                                                                 class="icon-png-large" alt="">
                                                        </div>
                                                        <div on:click={() => openFilteredCaseExportModal(alert)}
                                                             aria-hidden="true">
                                                            <img src="static/images/exportCurrentResultsIcon.png"
                                                                 class="export-icon-png-large" alt="">
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        {/each}
                                        </tbody>
                                    </table>
                                {/if}
                            </div>
                        </div>
                    {:else}
                        <div class="summary">
                            <div class="table-top">
                                <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                                    {#if hideLeftNav}
                                        <p style="margin-bottom: 0">Apply Filters</p>
                                        <Icon data="{faFilter}" scale="1.5"
                                              style="color: #304e82; margin-top: 0"/>
                                    {:else}
                                        <p style="margin-bottom: 0">Close Filter Pane</p>
                                        <Icon data="{faCircleLeft}" scale="1.5"
                                              style="color: #c5c5c7; margin-top: 0"/>
                                    {/if}
                                </div>
                            </div>
                            <div class="scrollable-table">
                                {#if isLoading}
                                    <Loader/>
                                {:else}
                                    <table>
                                        <thead>
                                        <tr>
                                            <th on:click={() => sortNotifications('name')}>
                                                Name
                                                {#if ordering === 'name'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if ordering === '-name'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th>Parameters</th>
                                            <th>Shared By</th>
                                            <th on:click={() => sortNotifications('created')}>
                                                Notification Date
                                                {#if ordering === 'created'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if ordering === '-created'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th on:click={() => sortNotifications('most_recent_num_results')}>
                                                Current Matching Results
                                                {#if ordering === 'most_recent_num_results'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if ordering === '-most_recent_num_results'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th on:click={() => sortNotifications('total_statuses')} style="width: 10%">
                                                Queue Status
                                                {#if ordering === 'total_statuses'}
                                                    &#9650; <!-- Upward arrow -->
                                                {:else if ordering === '-total_statuses'}
                                                    &#9660; <!-- Downward arrow -->
                                                {/if}
                                            </th>
                                            <th> View/Export Current Results
                                            </th>
                                            <th> Explore All Results</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {#each mostRecentNotifications as notification, i}
                                            <tr style="font-weight: {$additionalDetailsPaneContent !== '' && selectedNotification  && selectedNotification.id === notification.id? 'bold' : 'normal'}">
                                                <td>{notification.name}</td>
                                                <td class="params">{formatParameters(notification.alert_parameters)}</td>
                                                {#if notification.shared_by && notification.shared_by !== getUser().username}
                                                    <td>{notification.shared_by}</td>
                                                {:else}
                                                    <td>{''}</td>
                                                {/if}
                                                <td>{formatDate(notification.created)}</td>
                                                {#if notification.case_uuids.split(",")[0] !== ''}
                                                    <td>{notification.case_uuids.split(",").length} Cases</td>
                                                {:else}
                                                    <td>{0} Cases</td>
                                                {/if}
                                                <td>
                                                    {#if notification.queue_status.total_statuses !== 0}
                                                        <div>{notification.queue_status.total_statuses_decided}/{notification.queue_status.total_statuses} Reviewed</div>
                                                    {/if}
                                                    {#if notification.queue_status.total_statuses_needs_more_review !== 0}
                                                        <div>{notification.queue_status.total_statuses_needs_more_review} Needs More Review</div>
                                                    {/if}
                                                </td>
                                                <td>
                                                    <div class="icon-container">
                                                        <div on:click={() => openCaseTable(notification)}
                                                             aria-hidden="true" style="margin-right: 30px">
                                                                <span title="View">
                                                                    <img src="static/images/viewCurrentResultsIcon.png"
                                                                         class="icon-png-large" alt="">
                                                                </span>
                                                        </div>
                                                        <div on:click={() => openCaseExportModal(notification)}
                                                             aria-hidden="true">
                                                            <div title="Export">
                                                                <img src="static/images/exportCurrentResultsIcon.png"
                                                                     class="export-icon-png-large" alt=""></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <div class="dropdown-toggle" aria-hidden="true"
                                                             on:click={() => {toggleDropdown(i)}}>
                                                            <img src="static/images/exploreAllResultsIcon.png"
                                                                 class="icon-png-large" alt="">
                                                        </div>
                                                        {#if (notifications[i].showDropdown)}
                                                            <ul class="dropdown-menu">
                                                                <li style="font-weight: bold;">Notifications
                                                                    Options
                                                                </li>
                                                                <li class="dropdown-menu-item"
                                                                    on:click={() => openAdditionalDetailPane(i, notification, "stat")} aria-hidden="true">
                                                                    <img src="static/images/viewStatisticsIcon.png"
                                                                         class="icon-png" alt=""> View
                                                                    Statistics
                                                                </li>
                                                                <li class="dropdown-menu-item"
                                                                    on:click={() => openAdditionalDetailPane(i, notification, "prev")} aria-hidden="true">
                                                                    <img src="static/images/viewPreviousIcon.png"
                                                                         class="icon-png" alt=""> View Previous
                                                                </li>
                                                                <li class="dropdown-menu-item"
                                                                    on:click={() => openAdditionalDetailPane(i, notification, "export")} aria-hidden="true">
                                                                    <img src="static/images/exportCurrentResultsIcon.png"
                                                                         class="icon-png" alt=""> Export
                                                                    Previous
                                                                </li>
                                                                <li class="dropdown-menu-item"
                                                                    on:click={() => openAdditionalDetailPane(i, notification, "find")} aria-hidden="true">
                                                                    <img src="static/images/findPatientIcon.png"
                                                                         class="icon-png" alt=""> Find Patient
                                                                </li>
                                                            </ul>
                                                        {/if}
                                                    </div>
                                                </td>
                                            </tr>
                                        {/each}
                                        </tbody>
                                    </table>
                                {/if}
                            </div>
                        </div>
                    {/if}
                {:else}
                    {#if isAllFilteredPatientView}
                        <div class="table">
                            <AllFilteredNotificationPatientTable {filteredNotificationData}
                                                                 bind:isNotificationView={isNotificationView}/>
                        </div>
                    {:else}
                        <div class="table">
                            <NotificationPatientTable {uuids} {date} {name} {id} {uuid_to_alert_info_dict} {queue_status}
                                                      bind:isNotificationView={isNotificationView}/>
                        </div>
                    {/if}
                {/if}
            </left>
            <right slot="right">
                {#key $additionalDetailKey}
                    {#if $additionalDetailsPaneContent === "stat"}
                        {#if statsLoading}
                            <Loader/>
                        {:else}
                            <div class="additional-header">
                                <h3 style="margin-right: 20px;">{selectedNotification.name} Statistics</h3>
                                <div class="right-icon">
                                    <div on:click={() => statExportModal.open()}
                                         aria-hidden="true">
                                        <div title="Export">
                                            <img src="static/images/exportCurrentResultsIcon.png"
                                                 class="export-icon-png-large" alt="">
                                        </div>
                                    </div>
                                    <span on:click={()=> {clearAdditionalDetailPane(true)}}
                                          aria-hidden="true">
                                            <Icon data="{faCircleXmark}" scale="2" style="color: #3A63B0; margin-top: 8px; margin-left: 20px"/>
                                        </span>
                                </div>
                            </div>
                            <table style="margin-right: 20px;">
                                <tbody>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Total Number of Patients Identified
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.total_patients_identitifed}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Times Generated
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.times_generated}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Average Patients Identified Per Notification
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.avg_patient_per_notification}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Tracked Patients
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.number_tracked}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Waiting Patients
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.number_is_waiting}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Needs Review Patients
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.number_needs_review}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Candidates
                                    </td>
                                    <td class="stat-td">
                                        <strong>{selectedAlertStatistics.number_candidates}</strong>
                                    </td>
                                </tr>
                                <tr class="stat-tr">
                                    <td class="stat-td-label">
                                        Number of Not Tracked Patients
                                    </td>
                                    <td class="stat-td">
                                        <div style="display: flex">
                                            <strong>{selectedAlertStatistics.number_not_tracked}</strong>
                                            {#if showNotTrackedReasons}
                                                <div on:click={() => handleShowNotTrackedinfoClick()} aria-hidden="true">
                                                    <Icon data="{faCaretDown}" scale="1.5" style="margin-top: 0; margin-left: 40px"/>
                                                </div>
                                            {:else}
                                                <div on:click={() => handleShowNotTrackedinfoClick()} aria-hidden="true">
                                                    <Icon data="{faCaretLeft}" scale="1.5" style="margin-top: 0; margin-left: 40px"/>
                                                </div>
                                            {/if}
                                        </div>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {#if showNotTrackedReasons}
                                <table style="width: 525px; margin-left: 30px">
                                    <thead>
                                    <tr>
                                        <th style="background-color: white; color: black; border: 1px solid black">Not Tracked Reason</th>
                                        <th style="background-color: white; color: black; border: 1px solid black">Number of Occurrences</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {#each notTrackedReasons as [reason, count]}
                                        <tr>
                                            {#if presetNonTrackOptions.includes(reason)}
                                                <td style="border: 1px solid black">{reason}</td>
                                                <td style="border: 1px solid black">{count}</td>
                                            {:else}
                                                <td style="border: 1px solid black">Alternate Reason</td>
                                                <td style="border: 1px solid black">{reason}</td>
                                            {/if}
                                        </tr>
                                    {/each}
                                    </tbody>
                                </table>
                            {/if}
                        {/if}
                    {:else if $additionalDetailsPaneContent === "prev"}
                        <div class="additional-header">
                            <h3 style="margin-right: 20px;">View Previous Notifications</h3>
                            <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                            </div>
                        </div>
                        <div style="margin-left:10px; width: 95%; max-height: 68vh; overflow: scroll">
                            <OlderNotificationsTable {notifications} {selectedNotification}
                                                     bind:isNotificationView={isNotificationView}
                                                     bind:uuids={uuids}
                                                     bind:date={date}
                                                     bind:name={name} bind:id={id}/>
                        </div>
                    {:else if $additionalDetailsPaneContent === "export"}
                        <div class="additional-header">
                            <h3 style="margin-right: 20px;">Export Previous Notification</h3>
                            <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                            </div>
                        </div>
                        <div style="height: 50vh; display: flex; flex-direction:column; justify-content: space-between; text-align: left; margin-left: 25px">
                            <div>
                                <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                    <div style="margin-top: 10px;">
                                        <strong>Export All Identified Patients</strong>
                                        ({getAllIdentifiedPatients(selectedNotification)})
                                    </div>
                                    <div>
                                        <img src="static/images/exportCurrentResultsIcon.png"
                                             class="icon-png-large" alt=""
                                             on:click={() => {openAllCasesExportModal()}} aria-hidden="true">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <strong>Export Patients Across Time
                                    Range</strong>({dateFilteredSelectedNotificationPatientList.length})
                                <div style="margin-top: 25px; margin-left: 15px;">
                                    <div style="display: flex;">
                                        <div class="input-label" style="margin-right: 100px">From</div>
                                        <div class="input-label">To</div>
                                    </div>
                                    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                        <div style="align-items: flex-end; margin-top: auto;">
                                            <input type="date" class="date-range" bind:value={startDateRange}/>
                                            <input type="date" class="date-range" bind:value={endDateRange}/>
                                        </div>
                                        <div style="align-items: flex-end; margin-top: auto;">
                                            <button class="search-button"
                                                    on:click={()=> {getTimeRangePatients();}}>
                                                Search
                                            </button>
                                        </div>
                                        <div style="align-items: flex-end; margin-top: auto;">
                                            <img src="static/images/exportCurrentResultsIcon.png"
                                                 class="icon-png-large" alt=""
                                                 on:click={() => {openDateRangeCasesExportModal()}} aria-hidden="true">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <strong>Export the last number of notifications</strong>
                                ({lastXNotifications.length})
                                <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                    <div style="margin-top: 25px; margin-left: 15px;">
                                        <input type="text" bind:value={lastNumNotifications}
                                               on:input={handleIntegerInput}/>
                                    </div>
                                    <div style="align-items: flex-end; margin-top: auto;">
                                        <button class="clear-results"
                                                on:click={()=>{lastNumNotifications = ''; getlastXNotifications()}}>
                                            Clear
                                        </button>
                                    </div>
                                    <div style="align-items: flex-end; margin-top: auto;">
                                        <button class="search-button" on:click={()=>{getlastXNotifications()}}>
                                            Search
                                        </button>
                                    </div>
                                    <div style="align-items: flex-end; margin-top: auto;">
                                        <img src="static/images/exportCurrentResultsIcon.png"
                                             class="icon-png-large" alt=""
                                             on:click={() => {openLastNumberNotificationExportModal()}} aria-hidden="true">
                                    </div>
                                </div>
                                {#if (inputError)}<p style="color:red;">Input must be an Integer!</p>{/if}
                            </div>
                        </div>
                    {:else if $additionalDetailsPaneContent === "find"}
                        {#if (fetchingNotificationPatients)}
                            <Loader/>
                        {:else}
                            <div class="additional-header">
                                <h3 style="margin-right: 20px;">Find Patient</h3>
                                <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                                </div>
                            </div>
                            <div style="height: 50vh; display: flex; flex-direction:column; text-align: left; margin-left: 25px">
                                {#if (!noPatientsIdentified)}
                                    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                        <div style="margin-top: 25px; margin-left: 15px;">
                                            <div style="display:flex;">
                                                <strong>Patient Name</strong>
                                            </div>
                                            <div style="display: flex; margin-top: 10px;">
                                                <input type="text" placeholder="Type to search..."
                                                       value={patientNameInput} on:input={handleInput}/>
                                            </div>
                                        </div>
                                        <div style="align-items: flex-end; margin-top: auto;">
                                            <button class="clear-results"
                                                    on:click={() => {clearFindPatientDetails()}}>
                                                Clear
                                            </button>
                                        </div>
                                        <div style="align-items: flex-end; margin-top: auto;">
                                            <button class="search-button" on:click={() => {populatePatientTable()}}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    {#if displayPatientNameDropdown}
                                        <div class="input-container-dropdown">
                                            {#each filteredPatients as patient}
                                                <div class="patient-input-item"
                                                     on:click={() => {selectPatient(patient);}} aria-hidden="true">
                                                    {patient}
                                                </div>
                                            {/each}
                                        </div>
                                    {/if}
                                    {#if (displayPatientTable)}
                                        <table style="margin-top:25px">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Patient Name
                                                </th>
                                                <th>
                                                    View
                                                </th>
                                                <th>
                                                    Export
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    {selectedPatient}
                                                </td>
                                                <td>
                                                    <img src="static/images/viewCurrentResultsIcon.png"
                                                         class="icon-png" alt=""
                                                         on:click={() => {updateSearchFromAdditionalDetails(selectedPatient, selectedNotification)}} aria-hidden="true">
                                                </td>
                                                <td>
                                                    <img src="static/images/exportCurrentResultsIcon.png"
                                                         class="icon-png" alt=""
                                                         on:click={() => {openPatientCaseExportModal(selectedPatient)}} aria-hidden="true">
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    {/if}
                                {:else}
                                    <p>No Patients Identified for this Notification</p>
                                {/if}
                            </div>
                        {/if}
                    {/if}
                {/key}
            </right>
        </HSplitPane>
    {:else}
        <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth} minLeftPaneSize= "20%">
            <left slot="left">
                {#if !hideLeftNav && isNotificationView}
                    <div class="side-bar">
                        <NotificationFilterSideBar {fetchFilterNotifications} {fetchClearFilters}
                                                   {fetchResetNotifications}/>
                    </div>
                {/if}
            </left>
            <right slot="right">
                <HSplitPane bind:leftPaneSize={$mainPaneWidth} bind:rightPaneSize={$additionalDetailsPaneWidth}>
                    <left slot="left">
                        {#if isNotificationView}
                            {#if isFilterView}
                                <div class="summary">
                                    <div class="table-top">
                                        <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                                            {#if hideLeftNav}
                                                <p style="margin-bottom: 0">Apply Filters</p>
                                                <Icon data="{faFilter}" scale="1.5"
                                                      style="color: #304e82; margin-top: 0"/>
                                            {:else}
                                                <p style="margin-bottom: 0">Close Filter Pane</p>
                                                <Icon data="{faCircleLeft}" scale="1.5"
                                                      style="color: #c5c5c7; margin-top: 0"/>
                                            {/if}
                                        </div>
                                        <div class="count-box">
                                            Alert Count: {alertCount}
                                        </div>
                                        <div class="count-box">
                                            Patient Count: {patientCount}
                                        </div>
                                        <div class="view-all-box" on:click={() => openAllFilteredCaseTable()}
                                             aria-hidden="true">
                                            View All
                                        </div>
                                        <div class="export-all-box" on:click={() => openAllFilteredCaseExportModal()}
                                             aria-hidden="true">
                                            Export All
                                        </div>
                                    </div>
                                    <div class="scrollable-table">
                                        {#if isLoading}
                                            <Loader/>
                                        {:else}
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th on:click={() => sortFilteredNotifications('name')}>
                                                        Name
                                                        {#if filtered_ordering === 'name'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if filtered_ordering === '-name'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th> Parameters</th>
                                                    <th> Shared By</th>
                                                    <th on:click={() => sortFilteredNotifications('num_cases')}>
                                                        Matching Results
                                                        {#if filtered_ordering === 'num_cases'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if filtered_ordering === '-num_cases'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th> View / Export Cases</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {#each filteredNotificationData as alert}
                                                    <tr>
                                                        <td> {alert.name} </td>
                                                        <td class="params"> {formatParameters(alert.alert_parameters)} </td>
                                                        <td> {alert.shared_by} </td>
                                                        <td>{alert.case_uuids.length}</td>
                                                        <td>
                                                            <div class="icon-container">
                                                                <div on:click={() => openFilteredCaseTable(alert)}
                                                                     aria-hidden="true" style="margin-right: 30px">
                                                                    <img src="static/images/viewCurrentResultsIcon.png"
                                                                         class="icon-png-large" alt="">
                                                                </div>
                                                                <div on:click={() => openFilteredCaseExportModal(alert)}
                                                                     aria-hidden="true">
                                                                    <img src="static/images/exportCurrentResultsIcon.png"
                                                                         class="export-icon-png-large" alt="">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                {/each}
                                                </tbody>
                                            </table>
                                        {/if}
                                    </div>
                                </div>
                            {:else}
                                <div class="summary">
                                    <div class="table-top">
                                        <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                                            {#if hideLeftNav}
                                                <p style="margin-bottom: 0">Apply Filters</p>
                                                <Icon data="{faFilter}" scale="1.5"
                                                      style="color: #304e82; margin-top: 0"/>
                                            {:else}
                                                <p style="margin-bottom: 0">Close Filter Pane</p>
                                                <Icon data="{faCircleLeft}" scale="1.5"
                                                      style="color: #c5c5c7; margin-top: 0"/>
                                            {/if}
                                        </div>
                                    </div>
                                    <div class="scrollable-table">
                                        {#if isLoading}
                                            <Loader/>
                                        {:else}
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th on:click={() => sortNotifications('name')}>
                                                        Name
                                                        {#if ordering === 'name'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if ordering === '-name'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th>Parameters</th>
                                                    <th>Shared By</th>
                                                    <th on:click={() => sortNotifications('created')}>
                                                        Notification Date
                                                        {#if ordering === 'created'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if ordering === '-created'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th on:click={() => sortNotifications('most_recent_num_results')}>
                                                        Current Matching Results
                                                        {#if ordering === 'most_recent_num_results'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if ordering === '-most_recent_num_results'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th on:click={() => sortNotifications('total_statuses')} style="width: 10%">
                                                        Queue Status
                                                        {#if ordering === 'total_statuses'}
                                                            &#9650; <!-- Upward arrow -->
                                                        {:else if ordering === '-total_statuses'}
                                                            &#9660; <!-- Downward arrow -->
                                                        {/if}
                                                    </th>
                                                    <th> View/Export Current Results
                                                    </th>
                                                    <th> Explore All Results</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {#each mostRecentNotifications as notification, i}
                                                    <tr style="font-weight: {$additionalDetailsPaneContent !== '' && selectedNotification  && selectedNotification.id === notification.id? 'bold' : 'normal'}">
                                                        <td>{notification.name}</td>
                                                        <td class="params">{formatParameters(notification.alert_parameters)}</td>
                                                        {#if notification.shared_by && notification.shared_by !== getUser().username}
                                                            <td>{notification.shared_by}</td>
                                                        {:else}
                                                            <td>{''}</td>
                                                        {/if}
                                                        <td>{formatDate(notification.created)}</td>
                                                        {#if notification.case_uuids.split(",")[0] !== ''}
                                                            <td>{notification.case_uuids.split(",").length} Cases</td>
                                                        {:else}
                                                            <td>{0} Cases</td>
                                                        {/if}
                                                        <td>
                                                            {#if notification.queue_status.total_statuses !== 0}
                                                                <div>{notification.queue_status.total_statuses_decided}/{notification.queue_status.total_statuses} Reviewed</div>
                                                            {/if}
                                                            {#if notification.queue_status.total_statuses_needs_more_review !== 0}
                                                                <div>{notification.queue_status.total_statuses_needs_more_review} Needs More Review</div>
                                                            {/if}
                                                        </td>
                                                        <td>
                                                            <div class="icon-container">
                                                                <div on:click={() => openCaseTable(notification)}
                                                                     aria-hidden="true" style="margin-right: 30px">
                                                                <span title="View">
                                                                    <img src="static/images/viewCurrentResultsIcon.png"
                                                                         class="icon-png-large" alt="">
                                                                </span>
                                                                </div>
                                                                <div on:click={() => openCaseExportModal(notification)}
                                                                     aria-hidden="true">
                                                                    <div title="Export">
                                                                        <img src="static/images/exportCurrentResultsIcon.png"
                                                                             class="export-icon-png-large" alt=""></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="dropdown">
                                                                <div class="dropdown-toggle" aria-hidden="true"
                                                                     on:click={() => {toggleDropdown(i)}}>
                                                                    <img src="static/images/exploreAllResultsIcon.png"
                                                                         class="icon-png-large" alt="">
                                                                </div>
                                                                {#if (notifications[i].showDropdown)}
                                                                    <ul class="dropdown-menu">
                                                                        <li style="font-weight: bold;">Notifications
                                                                            Options
                                                                        </li>
                                                                        <li class="dropdown-menu-item"
                                                                            on:click={() => openAdditionalDetailPane(i, notification, "stat")} aria-hidden="true">
                                                                            <img src="static/images/viewStatisticsIcon.png"
                                                                                 class="icon-png" alt=""> View
                                                                            Statistics
                                                                        </li>
                                                                        <li class="dropdown-menu-item"
                                                                            on:click={() => openAdditionalDetailPane(i, notification, "prev")} aria-hidden="true">
                                                                            <img src="static/images/viewPreviousIcon.png"
                                                                                 class="icon-png" alt=""> View Previous
                                                                        </li>
                                                                        <li class="dropdown-menu-item"
                                                                            on:click={() => openAdditionalDetailPane(i, notification, "export")} aria-hidden="true">
                                                                            <img src="static/images/exportCurrentResultsIcon.png"
                                                                                 class="icon-png" alt=""> Export
                                                                            Previous
                                                                        </li>
                                                                        <li class="dropdown-menu-item"
                                                                            on:click={() => openAdditionalDetailPane(i, notification, "find")} aria-hidden="true">
                                                                            <img src="static/images/findPatientIcon.png"
                                                                                 class="icon-png" alt=""> Find Patient
                                                                        </li>
                                                                    </ul>
                                                                {/if}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                {/each}
                                                </tbody>
                                            </table>
                                        {/if}
                                    </div>
                                </div>
                            {/if}
                        {:else}
                            {#if isAllFilteredPatientView}
                                <div class="table">
                                    <AllFilteredNotificationPatientTable {filteredNotificationData}
                                                                         bind:isNotificationView={isNotificationView}/>
                                </div>
                            {:else}
                                <div class="table">
                                    <NotificationPatientTable {uuids} {date} {name} {id} {uuid_to_alert_info_dict} {queue_status}
                                                              bind:isNotificationView={isNotificationView}/>
                                </div>
                            {/if}
                        {/if}
                    </left>
                    <right slot="right">
                        {#key $additionalDetailKey}
                            {#if $additionalDetailsPaneContent === "stat"}
                                {#if statsLoading}
                                    <Loader/>
                                {:else}
                                    <div class="additional-header">
                                        <h3 style="margin-right: 20px;">{selectedNotification.name} Statistics</h3>
                                        <div class="right-icon">
                                            <div on:click={() => statExportModal.open()}
                                                 aria-hidden="true">
                                                <div title="Export">
                                                    <img src="static/images/exportCurrentResultsIcon.png"
                                                         class="export-icon-png-large" alt="">
                                                </div>
                                            </div>
                                            <span on:click={()=> {clearAdditionalDetailPane(true)}}
                                                  aria-hidden="true">
                                            <Icon data="{faCircleXmark}" scale="2" style="color: #3A63B0; margin-top: 8px; margin-left: 20px"/>
                                        </span>
                                        </div>
                                    </div>
                                    <table style="margin-right: 20px;">
                                        <tbody>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Total Number of Patients Identified
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.total_patients_identitifed}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Times Generated
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.times_generated}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Average Patients Identified Per Notification
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.avg_patient_per_notification}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Tracked Patients
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.number_tracked}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Waiting Patients
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.number_is_waiting}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Needs Review Patients
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.number_needs_review}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Candidates
                                            </td>
                                            <td class="stat-td">
                                                <strong>{selectedAlertStatistics.number_candidates}</strong>
                                            </td>
                                        </tr>
                                        <tr class="stat-tr">
                                            <td class="stat-td-label">
                                                Number of Not Tracked Patients
                                            </td>
                                            <td class="stat-td">
                                                <div style="display: flex">
                                                    <strong>{selectedAlertStatistics.number_not_tracked}</strong>
                                                    {#if showNotTrackedReasons}
                                                        <div on:click={() => handleShowNotTrackedinfoClick()} aria-hidden="true">
                                                            <Icon data="{faCaretDown}" scale="1.5" style="margin-top: 0; margin-left: 40px"/>
                                                        </div>
                                                    {:else}
                                                        <div on:click={() => handleShowNotTrackedinfoClick()} aria-hidden="true">
                                                            <Icon data="{faCaretLeft}" scale="1.5" style="margin-top: 0; margin-left: 40px"/>
                                                        </div>
                                                    {/if}
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {#if showNotTrackedReasons}
                                        <table style="width: 525px; margin-left: 30px">
                                            <thead>
                                            <tr>
                                                <th style="background-color: white; color: black; border: 1px solid black">Not Tracked Reason</th>
                                                <th style="background-color: white; color: black; border: 1px solid black">Number of Occurrences</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {#each notTrackedReasons as [reason, count]}
                                                <tr>
                                                    {#if presetNonTrackOptions.includes(reason)}
                                                        <td style="border: 1px solid black">{reason}</td>
                                                        <td style="border: 1px solid black">{count}</td>
                                                    {:else}
                                                        <td style="border: 1px solid black">Alternate Reason</td>
                                                        <td style="border: 1px solid black">{reason}</td>
                                                    {/if}
                                                </tr>
                                            {/each}
                                            </tbody>
                                        </table>
                                    {/if}
                                {/if}
                            {:else if $additionalDetailsPaneContent === "prev"}
                                <div class="additional-header">
                                    <h3 style="margin-right: 20px;">View Previous Notifications</h3>
                                    <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                                    </div>
                                </div>
                                <div style="margin-left:10px; width: 95%; max-height: 68vh; overflow: scroll">
                                    <OlderNotificationsTable {notifications} {selectedNotification}
                                                             bind:isNotificationView={isNotificationView}
                                                             bind:uuids={uuids}
                                                             bind:date={date}
                                                             bind:name={name} bind:id={id}/>
                                </div>
                            {:else if $additionalDetailsPaneContent === "export"}
                                <div class="additional-header">
                                    <h3 style="margin-right: 20px;">Export Previous Notification</h3>
                                    <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                                    </div>
                                </div>
                                <div style="height: 50vh; display: flex; flex-direction:column; justify-content: space-between; text-align: left; margin-left: 25px">
                                    <div>
                                        <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                            <div style="margin-top: 10px;">
                                                <strong>Export All Identified Patients</strong>
                                                ({getAllIdentifiedPatients(selectedNotification)})
                                            </div>
                                            <div>
                                                <img src="static/images/exportCurrentResultsIcon.png"
                                                     class="icon-png-large" alt=""
                                                     on:click={() => {openAllCasesExportModal()}} aria-hidden="true">
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <strong>Export Patients Across Time
                                            Range</strong>({dateFilteredSelectedNotificationPatientList.length})
                                        <div style="margin-top: 25px; margin-left: 15px;">
                                            <div style="display: flex;">
                                                <div class="input-label" style="margin-right: 100px">From</div>
                                                <div class="input-label">To</div>
                                            </div>
                                            <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                                <div style="align-items: flex-end; margin-top: auto;">
                                                    <input type="date" class="date-range" bind:value={startDateRange}/>
                                                    <input type="date" class="date-range" bind:value={endDateRange}/>
                                                </div>
                                                <div style="align-items: flex-end; margin-top: auto;">
                                                    <button class="search-button"
                                                            on:click={()=> {getTimeRangePatients();}}>
                                                        Search
                                                    </button>
                                                </div>
                                                <div style="align-items: flex-end; margin-top: auto;">
                                                    <img src="static/images/exportCurrentResultsIcon.png"
                                                         class="icon-png-large" alt=""
                                                         on:click={() => {openDateRangeCasesExportModal()}} aria-hidden="true">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        <strong>Export the last number of notifications</strong>
                                        ({lastXNotifications.length})
                                        <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                            <div style="margin-top: 25px; margin-left: 15px;">
                                                <input type="text" bind:value={lastNumNotifications}
                                                       on:input={handleIntegerInput}/>
                                            </div>
                                            <div style="align-items: flex-end; margin-top: auto;">
                                                <button class="clear-results"
                                                        on:click={()=>{lastNumNotifications = ''; getlastXNotifications()}}>
                                                    Clear
                                                </button>
                                            </div>
                                            <div style="align-items: flex-end; margin-top: auto;">
                                                <button class="search-button" on:click={()=>{getlastXNotifications()}}>
                                                    Search
                                                </button>
                                            </div>
                                            <div style="align-items: flex-end; margin-top: auto;">
                                                <img src="static/images/exportCurrentResultsIcon.png"
                                                     class="icon-png-large" alt=""
                                                     on:click={() => {openLastNumberNotificationExportModal()}} aria-hidden="true">
                                            </div>
                                        </div>
                                        {#if (inputError)}<p style="color:red;">Input must be an Integer!</p>{/if}
                                    </div>
                                </div>
                            {:else if $additionalDetailsPaneContent === "find"}
                                {#if (fetchingNotificationPatients)}
                                    <Loader/>
                                {:else}
                                    <div class="additional-header">
                                        <h3 style="margin-right: 20px;">Find Patient</h3>
                                        <div class="right-icon">
                            <span on:click={()=> clearAdditionalDetailPane(true)}
                                  aria-hidden="true">
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #3A63B0; margin-top: 0"/>
                            </span>
                                        </div>
                                    </div>
                                    <div style="height: 50vh; display: flex; flex-direction:column; text-align: left; margin-left: 25px">
                                        {#if (!noPatientsIdentified)}
                                            <div style="display: flex; flex-direction: row; justify-content: space-between; margin-right: 25px;">
                                                <div style="margin-top: 25px; margin-left: 15px;">
                                                    <div style="display:flex;">
                                                        <strong>Patient Name</strong>
                                                    </div>
                                                    <div style="display: flex; margin-top: 10px;">
                                                        <input type="text" placeholder="Type to search..."
                                                               value={patientNameInput} on:input={handleInput}/>
                                                    </div>
                                                </div>
                                                <div style="align-items: flex-end; margin-top: auto;">
                                                    <button class="clear-results"
                                                            on:click={() => {clearFindPatientDetails()}}>
                                                        Clear
                                                    </button>
                                                </div>
                                                <div style="align-items: flex-end; margin-top: auto;">
                                                    <button class="search-button" on:click={() => {populatePatientTable()}}>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                            {#if displayPatientNameDropdown}
                                                <div class="input-container-dropdown">
                                                    {#each filteredPatients as patient}
                                                        <div class="patient-input-item"
                                                             on:click={() => {selectPatient(patient);}} aria-hidden="true">
                                                            {patient}
                                                        </div>
                                                    {/each}
                                                </div>
                                            {/if}
                                            {#if (displayPatientTable)}
                                                <table style="margin-top:25px">
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            Patient Name
                                                        </th>
                                                        <th>
                                                            View
                                                        </th>
                                                        <th>
                                                            Export
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            {selectedPatient}
                                                        </td>
                                                        <td>
                                                            <img src="static/images/viewCurrentResultsIcon.png"
                                                                 class="icon-png" alt=""
                                                                 on:click={() => {updateSearchFromAdditionalDetails(selectedPatient, selectedNotification)}} aria-hidden="true">
                                                        </td>
                                                        <td>
                                                            <img src="static/images/exportCurrentResultsIcon.png"
                                                                 class="icon-png" alt=""
                                                                 on:click={() => {openPatientCaseExportModal(selectedPatient)}} aria-hidden="true">
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            {/if}
                                        {:else}
                                            <p>No Patients Identified for this Notification</p>
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        {/key}
                    </right>
                </HSplitPane>
            </right>
        </HSplitPane>
    {/if}

</div>

<!--Case Export Modal-->
<form on:submit|preventDefault={onCaseExport}>
    <Modal bind:modalFunc={caseExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export cases?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => caseExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>

<!--Notification Statistics Export Modal-->
<form on:submit|preventDefault={onStatExport}>
    <Modal bind:modalFunc={statExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export statistics for {selectedNotification.name}?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => statExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>

{#if (exportDataLoading)}
    <div class="loader-overlay">
        <Loader/>
    </div>
{/if}