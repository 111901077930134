
<script>
    /*
    Modified from https://svelte.dev/repl/5734f123973d4682978427024ca90850?version=3.29.0
    */

    import AutoCompleteItem from "./AutoCompleteItem.svelte";

    export let placeHolder = '';
    /* Suggesting names based on input */
    //full list that could be potentially matched input
    export let allNames = [];
    //dynamic list that stores names matched input
    let suggestedNames = [];
    let strInput;

    const suggestNames = () => {
        let storageArr = [];
        if (inputValue) {
            allNames.forEach(name => {
                if (name.toLowerCase().includes(inputValue.toLowerCase())) {
                    storageArr = [...storageArr, name];
                }
            });
        }
        storageArr.sort((a,b) => a.length - b.length);
        suggestedNames = storageArr.slice(0, 15);
    };

    /* Handling the input */
    export let inputValue = "";

    $: if (!inputValue) {
        suggestedNames = [];
    }

    const setInputVal = (name) => {
        inputValue = name;
        suggestedNames = [];
        strInput.focus();
    };

</script>

<form autocomplete="off">
    <div class="autocomplete">
        <input bind:this={strInput}
               type="text"
               placeholder={placeHolder}
               bind:value={inputValue}
               on:input={suggestNames}>
    </div>

    {#if suggestedNames.length > 0}
        <ul class="autocomplete-items-list">
            {#each suggestedNames as item}
                <AutoCompleteItem SuggestedName="{item}" on:click={() => setInputVal(item)}/>
            {/each}
        </ul>
    {/if}
</form>

<style>
    div.autocomplete {
        width: 300px;
    }

    input {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 16px;
        margin: 0;
    }
    input[type=text] {
        background-color: #f1f1f1;
        width: 100%;
    }

    .autocomplete-items-list {
        position: relative;
        margin: 0;
        padding: 0;
        top: 0;
        width: 297px;
        border: 1px solid #ddd;
        background-color: #ddd;
    }
</style>