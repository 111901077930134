<script>
    import {onMount} from 'svelte';
    import {suggestBiomarkers, gatherFacetInfo, suggestGenes} from "../../network/patient_search";
    import {inFilterView, searchQueries} from '../../store/notificationSearchQueries.js';

    export let fetchFilterNotifications;
    export let fetchResetNotifications;
    export let fetchClearFilters;

    let notificationStartDate = "";
    let notificationEndDate = "";
    let biomarkers = "";
    let selectedBiomarkers = []; // Add a new array for selected biomarkers
    let biomarkersSuggestions = []; // Add a new array for biomarkers suggestions
    let names = '';
    let patientNames = [];
    let selectedNames = [];
    let filteredNames = patientNames;
    let mrns = '';
    let patientMRNs = [];
    let selectedMRNs = [];
    let filteredMRNs = patientMRNs;
    let physicians = '';
    let orderingPhysicians = [];
    let selectedOrderingPhysicians = [];
    let filteredOrderingPhysicians = orderingPhysicians;
    let genes = "";
    let selectedGenes = [];
    let geneSuggestions = [];
    let data_sets = '';
    let dataSets = [];
    let selectedDataSets = [];
    let filteredDataSetOptions = dataSets;

    $: if ($inFilterView === false) {
        selectedNames = [];
        selectedMRNs = [];
        selectedBiomarkers = [];
        notificationStartDate = '';
        notificationEndDate = '';
        selectedGenes = [];
        selectedOrderingPhysicians = [];
        selectedDataSets = [];
    }

    // For example, you can call it when the component is initialized
    onMount(() => {
        fetchPatientFacetInfo();
    });

    const fetchPatientFacetInfo = async () => {
        try {
            const facetInfoResponse = await gatherFacetInfo();

            console.log(facetInfoResponse);
            if (Array.isArray(facetInfoResponse['full_names'])) {
                patientNames = [...new Set(facetInfoResponse['full_names'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['patient_mrns'])) {
                patientMRNs = [...new Set(facetInfoResponse['patient_mrns'].filter(item => item !== '').map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['ordering_physicians'])) {
                orderingPhysicians = [...new Set(facetInfoResponse['ordering_physicians'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['data_sets'])) {
                dataSets = [...new Set(facetInfoResponse['data_sets'].map((item) => item))];
            }
        } catch (error) {
            console.error("Error fetching patient facet info:", error);
        }
    };

    // Define a function to remove a selected biomarker
    const removeBiomarker = (index) => {
        selectedBiomarkers = selectedBiomarkers.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.markers = selectedBiomarkers;
            return storeValue;
        });
    };

    const removeGene = (index) => {
        selectedGenes = selectedGenes.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.genes = selectedGenes;
            return storeValue;
        });
    };

    const fetchBiomarkersSuggestions = async () => {
        try {
            const suggestion_resp = await suggestBiomarkers(biomarkers, 'pos'); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                biomarkersSuggestions = [...new Set(suggestion_resp['results'])];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid biomarkers suggestions response:", suggestion_resp);
                biomarkersSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching biomarkers suggestions:", error);
        }
    };

    const fetchGeneSuggestions = async () => {
        try {
            const suggestion_resp = await suggestGenes(genes); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp)) {
                geneSuggestions = [...new Set(suggestion_resp.map((item) => item))];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid genes suggestions response:", suggestion_resp);
                geneSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching genes suggestions:", error);
        }
    };

    const handleBiomarkersInput = async () => {
        await fetchBiomarkersSuggestions(); // Fetch biomarkers suggestions based on the current 'biomarkers'
    };

    const handleGeneInput = async () => {
        await fetchGeneSuggestions(); // Fetch diagnosis suggestions based on the current 'diagnosis'
    };

    const handleBiomarkersSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedBiomarkers = [...selectedBiomarkers, suggestion];
        biomarkers = ""; // Clear the biomarkers input field
        biomarkersSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.markers = selectedBiomarkers;
            return storeValue;
        });
    };

    const handleGenesSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedGenes = [...selectedGenes, suggestion];
        genes = ""; // Clear the biomarkers input field
        geneSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.genes = selectedGenes;
            return storeValue;
        });
    };

    function handleDataSetsInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDataSetOptions = dataSets.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleOrderingPhysiciansInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredOrderingPhysicians = orderingPhysicians.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleDataSetsSelect(option) {
        selectedDataSets = [...selectedDataSets, option];
        data_sets = '';
        filteredDataSetOptions = dataSets;
        searchQueries.update(storeValue => {
            storeValue.data_sets = selectedDataSets;
            return storeValue;
        });
    }

    function handleOrderingPhysicianSelect(option) {
        selectedOrderingPhysicians = [option];
        physicians = '';
        filteredOrderingPhysicians = orderingPhysicians;
        searchQueries.update(storeValue => {
            storeValue.ordering_physician = selectedOrderingPhysicians;
            return storeValue;
        });
    }

    function removeDataSet(index) {
        selectedDataSets = selectedDataSets.slice(0, index).concat(selectedDataSets.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.data_sets = selectedDataSets;
            return storeValue;
        });
    }

    function removeOrderingPhysician(index) {
        selectedOrderingPhysicians = selectedOrderingPhysicians.slice(0, index).concat(selectedOrderingPhysicians.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.ordering_physician = selectedOrderingPhysicians;
            return storeValue;
        });
    }

    function removeNotificationStartDate() {
        notificationStartDate = '';
        searchQueries.update(storeValue => {
            storeValue.notification_start_date = '';
            return storeValue;
        });
    }

    function removeNotificationEndDate() {
        notificationEndDate = '';
        searchQueries.update(storeValue => {
            storeValue.notification_end_date = '';
            return storeValue;
        });
    }

    function handleOrderingPhysicianKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleOrderingPhysicianSelect(event.target.value);
        }
    }

    function handleNameInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNames = patientNames.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleMRNInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredMRNs = patientMRNs.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNameSelect(option) {
        selectedNames = [option];
        names = '';
        filteredNames = patientNames;
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function handleMRNSelect(option) {
        selectedMRNs = [option];
        mrns = '';
        filteredMRNs = patientMRNs;
        searchQueries.update(storeValue => {
            storeValue.mrn = selectedMRNs;
            return storeValue;
        });
    }

    function removeName(index) {
        selectedNames = selectedNames.slice(0, index).concat(selectedNames.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function removeMRN(index) {
        selectedMRNs = selectedMRNs.slice(0, index).concat(selectedMRNs.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.mrn = selectedMRNs;
            return storeValue;
        });
    }

    function handleNameKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleNameSelect(event.target.value);
        }
    }

    function handleMRNKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleMRNSelect(event.target.value);
        }
    }

    function handleNotificationStartDateSelect(event) {
        const selectedDate = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.notification_start_date = selectedDate;
            return storeValue;
        });
    }

    function handleNotificationEndDateSelect(event) {
        const selectedDate = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.notification_end_date = selectedDate;
            return storeValue;
        });
    }

    function handleSearchSubmit() {
        fetchFilterNotifications();
    }

    function handleSearchClearResults() {
        selectedNames = [];
        selectedMRNs = [];
        selectedBiomarkers = [];
        notificationStartDate = '';
        notificationEndDate = '';
        selectedGenes = [];
        selectedOrderingPhysicians = [];
        selectedDataSets = [];
        fetchResetNotifications();
    }

    function handleSearchResetFilters() {
        selectedNames = [];
        selectedMRNs = [];
        selectedBiomarkers = [];
        notificationStartDate = '';
        notificationEndDate = '';
        selectedGenes = [];
        selectedOrderingPhysicians = [];
        selectedDataSets = [];
        fetchClearFilters();
    }

</script>

<style>
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .search-criteria {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 0;
        margin-left: 20px;
        height: calc(75vh - 50px);
        overflow-x: auto;
    }

    .input-label {
        font-weight: bold;
        margin-top:15px;
    }

    .input-field {
        width: 100%;
        padding: 10px;
    }

    .reported-date-input-field {
        width: 50%;
        padding: 5px;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap; /* Allow values to wrap to the next line */
        max-width: 100vw; /* Ensure it doesn't exceed its parent's width */
    }

    /* Style each selected diagnosis */
    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    /* Style the "x" button for removing selections */
    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    /* Add hover effect for suggestions */
    .suggestions {
        display: none;
        z-index: 1;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    /* Limit the width of the type-ahead container */
    .type-ahead {
        width: 100%; /* Set a maximum width for the type-ahead container */
    }

    .type-ahead:hover .suggestions {
        display: block;
    }

    .search-button {
        background-color: #9d9d9f;
        color: #fff;
    }

    .clear-button {
        background-color: #233C6B;
        color: #fff;
    }

    .reset-button {
        background-color: #d98b95;
        color: #fff;
    }

</style>

<div class="search-criteria">
    <h2>Search Criteria</h2>

    <button class="search-button" on:click={handleSearchSubmit}>Search</button>
    <button class="clear-button" on:click={handleSearchClearResults}>Clear Results</button>
    <button class="reset-button" on:click={handleSearchResetFilters}>Reset Filters</button>

    <div class="input-label">Notification Date Range</div>
    <div class="selected-values-container">
        {#if notificationStartDate !== ''}
            <span class="selected-value">
                {notificationStartDate}
                <span class="remove-selection" on:click={() => removeNotificationStartDate()} aria-hidden="true">x</span>
            </span>
        {/if}
        {#if notificationEndDate !== ''}
            <span class="selected-value">
                {notificationEndDate}
                <span class="remove-selection" on:click={() => removeNotificationEndDate()} aria-hidden="true">x</span>
            </span>
        {/if}
    </div>
    <div style="display: flex">
        <div class="input-label" style="margin-right: 125px">From</div>
        <div class="input-label">To</div>
    </div>
    <div style="display: flex">
        <input type="date" class="reported-date-input-field" bind:value={notificationStartDate} on:change={handleNotificationStartDateSelect}/>
        <input type="date" class="reported-date-input-field" bind:value={notificationEndDate} on:change={handleNotificationEndDateSelect}/>
    </div>

    <div class="input-label">Patient Name</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedNames as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeName(index)} aria-hidden="true">x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={names}
                on:click={handleNameInput}
                on:input={handleNameInput}
                on:keydown={handleNameKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredNames as option (option)}
                <div class="suggestion-item" on:click={() => handleNameSelect(option)} aria-hidden="true">{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Patient MRN</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedMRNs as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeMRN(index)} aria-hidden="true">x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={mrns}
                on:click={handleMRNInput}
                on:input={handleMRNInput}
                on:keydown={handleMRNKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredMRNs as option (option)}
                <div class="suggestion-item" on:click={() => handleMRNSelect(option)} aria-hidden="true">{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Genes</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedGenes as value, index (value)}
                    <span class="selected-value">
                        {value}
                        <span class="remove-selection" on:click={() => removeGene(index)} aria-hidden="true">x</span>
                    </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={genes}
                on:click={handleGeneInput}
                on:input={handleGeneInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each geneSuggestions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handleGenesSelect(suggestion)} aria-hidden="true">{suggestion}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Alterations</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedBiomarkers as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeBiomarker(index)} aria-hidden="true">x</span>
                </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={biomarkers}
                on:click={handleBiomarkersInput}
                on:input={handleBiomarkersInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each biomarkersSuggestions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handleBiomarkersSelect(suggestion)} aria-hidden="true">{suggestion}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Ordering Physician</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedOrderingPhysicians as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeOrderingPhysician(index)} aria-hidden="true">x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={physicians}
                on:click={handleOrderingPhysiciansInput}
                on:input={handleOrderingPhysiciansInput}
                on:keydown={handleOrderingPhysicianKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredOrderingPhysicians as option (option)}
                <div class="suggestion-item" on:click={() => handleOrderingPhysicianSelect(option)} aria-hidden="true">{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Vendor</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedDataSets as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDataSet(index)} aria-hidden="true">x</span>
                </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={data_sets}
                on:click={handleDataSetsInput}
                on:input={handleDataSetsInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredDataSetOptions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handleDataSetsSelect(suggestion)} aria-hidden="true">{suggestion}</div>
            {/each}
        </div>
    </div>


</div>