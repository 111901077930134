<script>
    import PatientTrackingTable from './PatientTrackingTable.svelte';
    import CandidateTable from './CandidateTable.svelte';
    import ArchivedTable from './ArchivedTable.svelte';
    import NotTrackedTable from "./NotTrackedTable.svelte";

    export let activeTab;
</script>

{#if activeTab === 'tab1'}
    <PatientTrackingTable/>
{:else if activeTab === 'tab2'}
    <CandidateTable/>
{:else if activeTab === 'tab3'}
    <ArchivedTable/>
{:else if activeTab === 'tab4'}
    <NotTrackedTable/>
{/if}