<script>
    /* AccordionGroup
    * Contains multiple AccordionSection's which share a context with this component.
    * All other AccordionSections are closed when another is opened
    */
    import { setContext } from 'svelte';
    import { writable } from 'svelte/store';

    const current = writable(false);
    setContext('Accordion', {
        setCurrent: (fn) => {
            $current && $current !== fn && $current();
            current.set(fn);
        },
    });
</script>

<slot/>