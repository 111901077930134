<script>
    import {Icon} from "svelte-awesome";
    import {faPenToSquare, faCircleXmark, faXmark} from "@fortawesome/free-solid-svg-icons";
    import {pane, selectedLabResults, selectedCase} from "../../store/patientMatch";
    import {createEventDispatcher, onMount} from 'svelte';

    const dispatch = createEventDispatcher();

    let editPage = false;

    let showLabels = true;
    let iconTooltip = '';
    let toggleLR = {};

    function toggleEditMode() {
        editPage = !editPage;
    }

    function toggleCollapseItem(lrID) {
        toggleLR[lrID] = !toggleLR[lrID];
    }

    onMount(() => {
        if ($selectedLabResults){
            $selectedLabResults.forEach(sample => {
                sample.lab_results.forEach(lr => {
                    const key = `${sample.id}-${lr.id}`;
                    toggleLR[key] = false;
                });
            });
        }
    });


</script>

<div style="display: flex; flex-direction: column; width: 100vw;">

    <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px; width: 95%;">
        <div></div>
        <div style="display: flex; align-items: center; justify-content: flex-start">
            <h1 style="margin: 0;">Test Information</h1>
            <div style="cursor: pointer;" on:click={toggleEditMode}>
                {#if editPage}
                    <Icon data="{faCircleXmark}" scale="1.75" style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                {:else}
                    <Icon data="{faPenToSquare}" scale="1.75" style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                {/if}
            </div>
        </div>
        <div on:click={() => pane.set("backToSummary")}>
            <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
        </div>
    </div>

    <div style="height: 55vh; overflow-x: scroll;">
        {#each $selectedLabResults as sample, sampleIndex}
            {#each sample.lab_results as lr, lrIndex}
                <table style="width:100%; border-collapse: collapse; cursor:pointer;">
                    <tr>
                        <td style="padding: 10px 10px 10px 5vw; text-align: left;"
                            on:click={() => toggleCollapseItem(`${sample.id}-${lr.id}`)}>
                            <p style="font-size: 16px; font-weight: normal"> <strong>Test {sampleIndex * sample.lab_results.length + lrIndex + 1}:</strong> {lr.assay_display}, {lr.report_id}, {lr.reported}:</p>
                            {#if (toggleLR[`${sample.id}-${lr.id}`])}
                                <div style="margin-left: 25px;">
                                    <p style="font-weight: normal"><strong>Assay Name: </strong>{lr.assay_display}</p>
                                    <p style="font-weight: normal"><strong>OrderID: </strong>{$selectedCase.order_identifier}</p>
                                    <p style="font-weight: normal"><strong>Reported Date: </strong>{lr.reported}</p>
                                    <p style="font-weight: normal"><strong>Report Status: </strong>{lr.report_status}</p>
                                    <p style="font-weight: normal"><strong>Sample Name: </strong>{lr.sample_display}</p>
                                    <p style="font-weight: normal"><strong>Sample Collected Date: </strong>{lr.sample_taken}</p>
                                </div>
                            {/if}
                        </td>
                    </tr>
                </table>
            {/each}
        {/each}
    </div>

    <div style="height:5vh; display: flex; justify-content: flex-end;">
        <button class='save-button' style="visibility: {editPage ? 'visible' : 'hidden'}">Save</button>
    </div>
</div>

<style>
    p{
        font-weight: bold;
        font-size: 14px;
        margin-left: 2em;
    }

    .save-button {
        margin-right: 25px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

</style>

