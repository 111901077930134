<script>
    import queryItem from "../../utils/queryItem";
    import {add} from "../../store/currentAlertQuery";
    import {RADIO_ALL} from "../../utils/constants";
    import MultiSelect from "../MultiSelect.svelte";

    //Field to query on in solr
    export let solrField;
    //How this filter will be named in the UI
    export let displayName;
    //ALL - ANY - NONE query option
    export let filterMode = RADIO_ALL;
    export let core;
    //Possible Field Values
    export let allValues;
    export let queryLabel;


    const addFilter = (data) => {
        const q = queryItem(solrField, data, filterMode, core, `${displayName}: ${data.join(', ')}`);
        add(queryLabel, q)();
    };
</script>

<MultiSelect onAdd={addFilter} options={allValues.map(v => ({ value: v, name: v}))} />