import {derived} from "svelte/store";
import {currentQuery, queryKeys} from "./currentAlertQuery";
import makeQueryPayloadItem from "../utils/makeQueryPayloadItem";

/**
 * A store derived from currentQuery which is used as the filters array when querying solr
 *
 * Quick explanation of a derived store!!!!!
 * @function derived() has two parameters.
 * @param stores: can be a single Store, or in this case a list of Store objs from imports
 * @param fn: The way you see this param declared below is kind of like a pointer I guess. This is how you can actually
 * access the data inside each store in the function.
 * -
 * This derived store kind of listens for updates to the stores passed in as params, and Whenever any of the following
 * things happen, this code will execute with updated values:
 * -
 * currentQuery.update(), currentQuery.set()
 * queryKeys.update(), queryKeys.set()
 * -
 * The return value of this particular store is used in ExportDashboard.svelte, and is a dictionary that is used to
 * save an export or query profile in the UI. (~ line 132)
 */

export default derived([currentQuery, queryKeys], ([$currentQuery, $queryKeys]) => {
    const payload = [];
    for (const key of $queryKeys) {
        const value = $currentQuery[key];
        if (value) {
            if (Array.isArray(value)) {
                payload.push(...value.map(makeQueryPayloadItem));
            } else {
                payload.push(makeQueryPayloadItem(value));
            }
        }
    }
    return payload;
});