/**
 * Generate some random id given some length
 * @param length {Number}
 * @returns {string}
 */
export default function randomString(length = 8) {
    const chars = ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        + 'abcdefghijklmnopqrstuvwxyz'
        + '0123456789');
    let string = '';
    const bytes = new Uint32Array(length);
    // NB: do not use for encryption
    // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues
    window.crypto.getRandomValues(bytes);
    for (let i = 0; i < bytes.length; i += 1) {
        string += chars[bytes[i] % chars.length];
    }
    return string;
}