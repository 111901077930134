<script>
    import {onMount} from 'svelte';
    import {suggestDiagnosis, suggestBiomarkers, gatherAssays, gatherFacetInfo, suggestGenes} from "../../network/patient_search";
    import {faCaretLeft, faCaretDown} from "@fortawesome/free-solid-svg-icons";
    import {Icon} from "svelte-awesome"; // Update the import to match the correct API function for biomarkers suggestions
    import {searchQueries, totalPatients} from '../../store/patientSearchQueries.js';

    export let fetchSearchPatients;
    export let fetchResetPatients;

    export let fetchClearFilters;

    let reportedStartDate = "";
    let reportedEndDate = "";
    let dob = "";
    let gender = "";
    let diagnosis = "";
    let posBiomarkers = "";
    let vusBiomarkers = "";
    let negBiomarkers = "";
    let selectedDiagnoses = [];
    let selectedPosBiomarkers = [];
    let selectedVusBiomarkers = [];
    let selectedNegBiomarkers = [];
    let diagnosisSuggestions = [];
    let posBiomarkersSuggestions = [];
    let vusBiomarkersSuggestions = [];
    let negBiomarkersSuggestions = [];
    let testOptions = [];
    let tests = '';
    let selectedTests = [];
    let filteredTestOptions = testOptions;
    let mrns = '';
    let patientMRNs = [];
    let selectedMRNs = [];
    let filteredMRNOptions = patientMRNs;
    let names = '';
    let patientNames = [];
    let selectedNames = [];
    let filteredNames = patientNames;
    let orderIDs = '';
    let ReportIds = [];
    let selectedReportIds = [];
    let filteredReportIds = ReportIds;
    let physicians = '';
    let orderingPhysicians = [];
    let selectedOrderingPhysicians = [];
    let filteredOrderingPhysicians = orderingPhysicians;
    let genes = "";
    let selectedGenes = [];
    let geneSuggestions = [];
    let statuses = '';
    let reportStatuses = [];
    let selectedReportStatuses = [];
    let filteredReportStatuses = reportStatuses;
    let data_sets = '';
    let dataSets = [];
    let selectedDataSets = [];
    let filteredDataSetOptions = dataSets;

    let showAdditionalFilters = false;

    // For example, you can call it when the component is initialized
    onMount(() => {
        fetchTestOptions();
        fetchPatientFacetInfo();
    });

    const fetchPatientFacetInfo = async () => {
        try {
            const facetInfoResponse = await gatherFacetInfo();

            console.log(facetInfoResponse);
            if (Array.isArray(facetInfoResponse['patient_mrns'])) {
                let total_patients = facetInfoResponse['patient_mrns'].length - 1
                totalPatients.update(storeValue => {
                    storeValue = total_patients.toString();
                    return storeValue;
                });
                patientMRNs = facetInfoResponse['patient_mrns'];
            }
            if (Array.isArray(facetInfoResponse['full_names'])) {
                patientNames = [...new Set(facetInfoResponse['full_names'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['ordering_physicians'])) {
                orderingPhysicians = [...new Set(facetInfoResponse['ordering_physicians'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['report_ids'])) {
                ReportIds = [...new Set(facetInfoResponse['report_ids'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['report_statuses'])) {
                reportStatuses = [...new Set(facetInfoResponse['report_statuses'].map((item) => item))];
            }
            if (Array.isArray(facetInfoResponse['data_sets'])) {
                dataSets = [...new Set(facetInfoResponse['data_sets'].map((item) => item))];
            }
        } catch (error) {
            console.error("Error fetching patient facet info:", error);
        }
    };

    const fetchTestOptions = async () => {
        try {
            const testOptionsResponse = await gatherAssays();

            if (Array.isArray(testOptionsResponse['results'])) {
                // Check if the response is an array
                testOptions = [...new Set(testOptionsResponse['results'].map((item) => item.name))];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid Test options response:", testOptionsResponse);
                testOptions = [];
            }
        } catch (error) {
            console.error("Error fetching Test options:", error);
        }
    };

    // Define a function to remove a selected diagnosis
    const removeDiagnosis = (index) => {
        selectedDiagnoses = selectedDiagnoses.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.diagnosis = selectedDiagnoses;
            return storeValue;
        });
    };

    // Define a function to remove a selected biomarker
    const removePosBiomarker = (index) => {
        selectedPosBiomarkers = selectedPosBiomarkers.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.pos_markers = selectedPosBiomarkers;
            return storeValue;
        });
    };
    const removeVusBiomarker = (index) => {
        selectedVusBiomarkers = selectedVusBiomarkers.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.vus_markers = selectedVusBiomarkers;
            return storeValue;
        });
    };
    const removeNegBiomarker = (index) => {
        selectedNegBiomarkers = selectedNegBiomarkers.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.neg_markers = selectedNegBiomarkers;
            return storeValue;
        });
    };

    const removeGene = (index) => {
        selectedGenes = selectedGenes.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.genes = selectedGenes;
            return storeValue;
        });
    };

    const fetchDiagnosisSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDiagnosis(diagnosis); // Make the API request for diagnosis suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                diagnosisSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
                console.log(diagnosisSuggestions);
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid diagnosis suggestions response:", suggestion_resp);
                diagnosisSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching diagnosis suggestions:", error);
        }
    };

    const fetchPosBiomarkersSuggestions = async () => {
        try {
            const suggestion_resp = await suggestBiomarkers(posBiomarkers, 'pos'); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                posBiomarkersSuggestions = [...new Set(suggestion_resp['results'])];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid biomarkers suggestions response:", suggestion_resp);
                posBiomarkersSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching biomarkers suggestions:", error);
        }
    };
    const fetchVusBiomarkersSuggestions = async () => {
        try {
            const suggestion_resp = await suggestBiomarkers(vusBiomarkers, 'vus'); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                vusBiomarkersSuggestions = [...new Set(suggestion_resp['results'])];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid biomarkers suggestions response:", suggestion_resp);
                vusBiomarkersSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching biomarkers suggestions:", error);
        }
    };
    const fetchNegBiomarkersSuggestions = async () => {
        try {
            const suggestion_resp = await suggestBiomarkers(negBiomarkers, 'neg'); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                negBiomarkersSuggestions = [...new Set(suggestion_resp['results'])];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid biomarkers suggestions response:", suggestion_resp);
                negBiomarkersSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching biomarkers suggestions:", error);
        }
    };

    const fetchGeneSuggestions = async () => {
        try {
            const suggestion_resp = await suggestGenes(genes); // Make the API request for biomarkers suggestions

            if (Array.isArray(suggestion_resp)) {
                geneSuggestions = [...new Set(suggestion_resp.map((item) => item))];
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid genes suggestions response:", suggestion_resp);
                geneSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching genes suggestions:", error);
        }
    };

    const handleDiagnosisInput = async () => {
        await fetchDiagnosisSuggestions(); // Fetch diagnosis suggestions based on the current 'diagnosis'
    };

    const handlePosBiomarkersInput = async () => {
        await fetchPosBiomarkersSuggestions(); // Fetch biomarkers suggestions based on the current 'biomarkers'
    };
    const handleVusBiomarkersInput = async () => {
        await fetchVusBiomarkersSuggestions(); // Fetch biomarkers suggestions based on the current 'biomarkers'
    };
    const handleNegBiomarkersInput = async () => {
        await fetchNegBiomarkersSuggestions(); // Fetch biomarkers suggestions based on the current 'biomarkers'
    };

    const handleGeneInput = async () => {
        await fetchGeneSuggestions(); // Fetch diagnosis suggestions based on the current 'diagnosis'
    };

    const handleDiagnosisSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedDiagnoses' array
        selectedDiagnoses = [suggestion];
        diagnosis = ""; // Clear the diagnosis input field
        diagnosisSuggestions = []; // Clear the diagnosis suggestions list
        searchQueries.update(storeValue => {
            storeValue.diagnosis = selectedDiagnoses;
            return storeValue;
        });
    };

    const handlePosBiomarkersSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedPosBiomarkers = [...selectedPosBiomarkers, suggestion];
        posBiomarkers = ""; // Clear the biomarkers input field
        posBiomarkersSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.pos_markers = selectedPosBiomarkers;
            return storeValue;
        });
    };
    const handleVusBiomarkersSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedVusBiomarkers = [...selectedVusBiomarkers, suggestion];
        vusBiomarkers = ""; // Clear the biomarkers input field
        vusBiomarkersSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.vus_markers = selectedVusBiomarkers;
            return storeValue;
        });
    };
    const handleNegBiomarkersSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedNegBiomarkers = [...selectedNegBiomarkers, suggestion];
        negBiomarkers = ""; // Clear the biomarkers input field
        negBiomarkersSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.neg_markers = selectedNegBiomarkers;
            return storeValue;
        });
    };

    const handleGenesSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedBiomarkers' array
        selectedGenes = [...selectedGenes, suggestion];
        genes = ""; // Clear the biomarkers input field
        geneSuggestions = []; // Clear the biomarkers suggestions list
        searchQueries.update(storeValue => {
            storeValue.genes = selectedGenes;
            return storeValue;
        });
    };

    function handleTestsInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredTestOptions = testOptions.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleReportIdsInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredReportIds = ReportIds.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleDataSetsInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDataSetOptions = dataSets.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleOrderingPhysiciansInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredOrderingPhysicians = orderingPhysicians.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleReportStatusesInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredReportStatuses = reportStatuses.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleTestSelect(option) {
        selectedTests = [...selectedTests, option];
        tests = '';
        filteredTestOptions = testOptions;
        searchQueries.update(storeValue => {
            storeValue.assay_names = selectedTests;
            return storeValue;
        });
    }

    function handleReportIdSelect(option) {
        selectedReportIds = [option];
        orderIDs = '';
        filteredReportIds = ReportIds;
        searchQueries.update(storeValue => {
            storeValue.report_id = selectedReportIds;
            return storeValue;
        });
    }

    function handleDataSetsSelect(option) {
        selectedDataSets = [...selectedDataSets, option];
        data_sets = '';
        filteredDataSetOptions = dataSets;
        searchQueries.update(storeValue => {
            storeValue.data_sets = selectedDataSets;
            return storeValue;
        });
    }

    function handleOrderingPhysicianSelect(option) {
        selectedOrderingPhysicians = [option];
        physicians = '';
        filteredOrderingPhysicians = orderingPhysicians;
        searchQueries.update(storeValue => {
            storeValue.ordering_physician = selectedOrderingPhysicians;
            return storeValue;
        });
    }

    function handleReportStatusSelect(option) {
        selectedReportStatuses = [option];
        statuses = '';
        filteredReportStatuses = reportStatuses;
        searchQueries.update(storeValue => {
            storeValue.report_status = selectedReportStatuses;
            return storeValue;
        });
    }

    function removeTest(index) {
        selectedTests = selectedTests.slice(0, index).concat(selectedTests.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.assay_names = selectedTests;
            return storeValue;
        });
    }

    function removeReportId(index) {
        selectedReportIds = selectedReportIds.slice(0, index).concat(selectedReportIds.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.report_id = selectedReportIds;
            return storeValue;
        });
    }

    function removeDataSet(index) {
        selectedDataSets = selectedDataSets.slice(0, index).concat(selectedDataSets.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.data_sets = selectedDataSets;
            return storeValue;
        });
    }

    function removeOrderingPhysician(index) {
        selectedOrderingPhysicians = selectedOrderingPhysicians.slice(0, index).concat(selectedOrderingPhysicians.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.ordering_physician = selectedOrderingPhysicians;
            return storeValue;
        });
    }

    function removeReportStatus(index) {
        selectedReportStatuses = selectedReportStatuses.slice(0, index).concat(selectedReportStatuses.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.report_status = selectedReportStatuses;
            return storeValue;
        });
    }

    function handleMRNInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredMRNOptions = patientMRNs.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleMRNSelect(option) {
        selectedMRNs = [option];
        mrns = '';
        filteredMRNOptions = patientMRNs;
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function removeMRN(index) {
        selectedMRNs = selectedMRNs.slice(0, index).concat(selectedMRNs.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function removeReportedStartDate() {
        reportedStartDate = '';
        searchQueries.update(storeValue => {
            storeValue.reported_start_date = '';
            return storeValue;
        });
    }

    function removeReportedEndDate() {
        reportedEndDate = '';
        searchQueries.update(storeValue => {
            storeValue.reported_end_date = '';
            return storeValue;
        });
    }

    function removeDOB() {
        dob = '';
        searchQueries.update(storeValue => {
            storeValue.date_of_birth = '';
            return storeValue;
        });
    }

    function removeGender() {
        gender = '';
        searchQueries.update(storeValue => {
            storeValue.gender = '';
            return storeValue;
        });
    }

    function handleMRNKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleMRNSelect(event.target.value);
        }
    }

    function handleReportIdKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleReportIdSelect(event.target.value);
        }
    }

    function handleOrderingPhysicianKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleOrderingPhysicianSelect(event.target.value);
        }
    }

    function handleReportStatusKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleReportStatusSelect(event.target.value);
        }
    }

    function handleNameInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNames = patientNames.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNameSelect(option) {
        selectedNames = [option];
        names = '';
        filteredNames = patientNames;
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function removeName(index) {
        selectedNames = selectedNames.slice(0, index).concat(selectedNames.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function handleNameKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleNameSelect(event.target.value);
        }
    }

    function handleReportedStartDateSelect(event) {
        const selectedDate = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.reported_start_date = selectedDate;
            return storeValue;
        });
    }

    function handleReportedEndDateSelect(event) {
        const selectedDate = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.reported_end_date = selectedDate;
            return storeValue;
        });
    }

    function handleDOBSelect(event) {
        const selectedDate = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.date_of_birth = selectedDate;
            return storeValue;
        });
    }

    function handleGenderSelect(event) {
        const selectedGender = event.target.value;
        searchQueries.update(storeValue => {
            storeValue.gender = selectedGender;
            return storeValue;
        });
    }

    function handleSearchSubmit() {
        fetchSearchPatients();
    }

    function handleSearchResetFilters() {
        selectedNames = [];
        selectedMRNs = [];
        gender = '';
        selectedPosBiomarkers = [];
        selectedVusBiomarkers = [];
        selectedNegBiomarkers = [];
        selectedDiagnoses = [];
        selectedTests = [];
        dob = '';
        reportedStartDate = '';
        reportedEndDate = '';
        selectedGenes = [];
        selectedReportStatuses = [];
        selectedReportIds = [];
        selectedOrderingPhysicians = [];
        selectedDataSets = [];
        fetchClearFilters();
    }

    function handleSearchClearResults() {
        selectedNames = [];
        selectedMRNs = [];
        gender = '';
        selectedPosBiomarkers = [];
        selectedVusBiomarkers = [];
        selectedNegBiomarkers = [];
        selectedDiagnoses = [];
        selectedTests = [];
        dob = '';
        reportedStartDate = '';
        reportedEndDate = '';
        selectedGenes = [];
        selectedReportStatuses = [];
        selectedReportIds = [];
        selectedOrderingPhysicians = [];
        selectedDataSets = [];
        fetchResetPatients();
    }

    function handleAdditionalFiltersClick() {
        showAdditionalFilters = !showAdditionalFilters;
    }
</script>

<style>
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .search-criteria {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 0;
        margin-left: 20px;
        height: calc(75vh - 50px);
        overflow-x: auto;
    }

    .input-label {
        font-weight: bold;
        margin-top:15px;
    }

    .input-field {
        width: 100%;
        padding: 10px;
    }

    .reported-date-input-field {
        width: 50%;
        padding: 5px;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap; /* Allow values to wrap to the next line */
        max-width: 100vw; /* Ensure it doesn't exceed its parent's width */
    }

    /* Style each selected diagnosis */
    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    /* Style the "x" button for removing selections */
    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    /* Add hover effect for suggestions */
    .suggestions {
        display: none;
        z-index: 1;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    /* Limit the width of the type-ahead container */
    .type-ahead {
        width: 100%; /* Set a maximum width for the type-ahead container */
    }

    .type-ahead:hover .suggestions {
        display: block;
    }

    .search-button {
        /*margin-top: 10px;*/
        background-color: #9d9d9f;
        color: #fff;
    }

    .clear-button {
        background-color: #233C6B;
        color: #fff;
    }

    .reset-button {
        background-color: #d98b95;
        color: #fff;
    }

    .additional-fields {
        margin-top: 10px;
        display: flex;
        align-content: center;
    }
</style>

<div class="search-criteria">
    <h2>Search Criteria</h2>

    <button class="search-button" on:click={handleSearchSubmit}>Search</button>
    <button class="clear-button" on:click={handleSearchClearResults}>Clear Results</button>
    <button class="reset-button" on:click={handleSearchResetFilters}>Reset Filters</button>

    <div class="input-label">Patient Name</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedNames as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeName(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={names}
                on:input={handleNameInput}
                on:click={handleNameInput}
                on:keydown={handleNameKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredNames as option (option)}
                <div class="suggestion-item" on:click={() => handleNameSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">MRN</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedMRNs as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeMRN(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={mrns}
                on:input={handleMRNInput}
                on:click={handleMRNInput}
                on:keydown={handleMRNKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredMRNOptions as option (option)}
                <div class="suggestion-item" on:click={() => handleMRNSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Report Date Range</div>
    <div class="selected-values-container">
        {#if reportedStartDate !== ''}
            <span class="selected-value">
                {reportedStartDate}
                <span class="remove-selection" on:click={() => removeReportedStartDate()}>x</span>
            </span>
        {/if}
        {#if reportedEndDate !== ''}
            <span class="selected-value">
                {reportedEndDate}
                <span class="remove-selection" on:click={() => removeReportedEndDate()}>x</span>
            </span>
        {/if}
    </div>
    <div style="display: flex">
        <div class="input-label" style="margin-right: 125px">From</div>
        <div class="input-label">To</div>
    </div>
    <div style="display: flex">
        <input type="date" class="reported-date-input-field" bind:value={reportedStartDate} on:change={handleReportedStartDateSelect}/>
        <input type="date" class="reported-date-input-field" bind:value={reportedEndDate} on:change={handleReportedEndDateSelect}/>
    </div>

    <div class="input-label">Ordering Physician</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedOrderingPhysicians as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeOrderingPhysician(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={physicians}
                on:input={handleOrderingPhysiciansInput}
                on:click={handleOrderingPhysiciansInput}
                on:keydown={handleOrderingPhysicianKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredOrderingPhysicians as option (option)}
                <div class="suggestion-item" on:click={() => handleOrderingPhysicianSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Diagnosis</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedDiagnoses as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDiagnosis(index)}>x</span>
                </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={diagnosis}
                on:input={handleDiagnosisInput}
                on:click={handleDiagnosisInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each diagnosisSuggestions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handleDiagnosisSelect(suggestion)}>{suggestion}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Vendor</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedDataSets as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDataSet(index)}>x</span>
                </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={data_sets}
                on:input={handleDataSetsInput}
                on:click={handleDataSetsInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredDataSetOptions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handleDataSetsSelect(suggestion)}>{suggestion}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Test Name</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedTests as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeTest(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={tests}
                on:input={handleTestsInput}
                on:click={handleTestsInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredTestOptions as option (option)}
                <div class="suggestion-item" on:click={() => handleTestSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Report ID</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedReportIds as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeReportId(index)}>x</span>
            </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={orderIDs}
                on:input={handleReportIdsInput}
                on:click={handleReportIdsInput}
                on:keydown={handleReportIdKeyDown}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each filteredReportIds as option (option)}
                <div class="suggestion-item" on:click={() => handleReportIdSelect(option)}>{option}</div>
            {/each}
        </div>
    </div>

    <div class="input-label">Positive Alterations</div>
    <div class="type-ahead">
        <div class="selected-values-container">
            {#each selectedPosBiomarkers as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removePosBiomarker(index)}>x</span>
                </span>
            {/each}
        </div>
        <input
                type="text"
                class="input-field"
                bind:value={posBiomarkers}
                on:input={handlePosBiomarkersInput}
                on:click={handlePosBiomarkersInput}
                placeholder="Type to search..."
        />
        <div class="suggestions">
            {#each posBiomarkersSuggestions as suggestion (suggestion)}
                <div class="suggestion-item" on:click={() => handlePosBiomarkersSelect(suggestion)}>{suggestion}</div>
            {/each}
        </div>
    </div>
    <div class="additional-fields">
        <div style="margin-right: 15px;">Additional Options</div>
        {#if showAdditionalFilters}
            <div on:click={() => handleAdditionalFiltersClick()} aria-hidden="true">
                <Icon data="{faCaretDown}" scale="1.5" style="margin-top: 0"/>
            </div>
        {:else}
            <div on:click={() => handleAdditionalFiltersClick()} aria-hidden="true">
                <Icon data="{faCaretLeft}" scale="1.5" style="margin-top: 0"/>
            </div>
        {/if}
    </div>

    {#if showAdditionalFilters}
        <div class="input-label">Genes</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedGenes as value, index (value)}
                    <span class="selected-value">
                        {value}
                        <span class="remove-selection" on:click={() => removeGene(index)}>x</span>
                    </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={genes}
                    on:input={handleGeneInput}
                    on:click={handleGeneInput}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each geneSuggestions as suggestion (suggestion)}
                    <div class="suggestion-item" on:click={() => handleGenesSelect(suggestion)}>{suggestion}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Uncertain Alterations</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedVusBiomarkers as value, index (value)}
                    <span class="selected-value">
                        {value}
                        <span class="remove-selection" on:click={() => removeVusBiomarker(index)}>x</span>
                    </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={vusBiomarkers}
                    on:input={handleVusBiomarkersInput}
                    on:click={handleVusBiomarkersInput}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each vusBiomarkersSuggestions as suggestion (suggestion)}
                    <div class="suggestion-item" on:click={() => handleVusBiomarkersSelect(suggestion)}>{suggestion}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Negative Alterations</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedNegBiomarkers as value, index (value)}
                    <span class="selected-value">
                        {value}
                        <span class="remove-selection" on:click={() => removeNegBiomarker(index)}>x</span>
                    </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={negBiomarkers}
                    on:input={handleNegBiomarkersInput}
                    on:click={handleNegBiomarkersInput}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each negBiomarkersSuggestions as suggestion (suggestion)}
                    <div class="suggestion-item" on:click={() => handleNegBiomarkersSelect(suggestion)}>{suggestion}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Date of Birth</div>
        <div class="selected-values-container">
            {#if dob !== ''}
            <span class="selected-value">
                {dob}
                <span class="remove-selection" on:click={() => removeDOB()}>x</span>
            </span>
            {/if}
        </div>
        <input type="date" class="input-field" bind:value={dob} on:change={handleDOBSelect}/>

        <div class="input-label">Gender</div>
        <div class="selected-values-container">
            {#if gender !== ''}
            <span class="selected-value">
                {gender}
                <span class="remove-selection" on:click={() => removeGender()}>x</span>
            </span>
            {/if}
        </div>
        <select class="input-field" bind:value={gender} on:change={handleGenderSelect}>
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Unknown">Unknown</option>
        </select>

        <div class="input-label">Report Status</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedReportStatuses as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeReportStatus(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={statuses}
                    on:input={handleReportStatusesInput}
                    on:click={handleReportStatusesInput}
                    on:keydown={handleReportStatusKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredReportStatuses as option (option)}
                    <div class="suggestion-item" on:click={() => handleReportStatusSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>
    {/if}
</div>
