import {getDisplayNames} from "../store/displayNames";
import queryItem from "./queryItem";
import {createCurrentQueryWithItems} from "../store/currentAlertQuery";


/**
 * populate currentQuery from selected saved query
 * @param alert {Object} a saved alert config
 */
export function createQueryFromExistingAlertParams(alert) {
    const queryItems = [];
    const displayNames = getDisplayNames();
    alert.alert_parameters.filters.forEach(filter => {
        if (filter.values.length > 0) {
            if (!displayNames[filter.field]) {
                // it's possible that a field from an old query has become restricted, if so skip it
                return;
            }
            let {sectionKey, displayName} = displayNames[filter.field];

            let value = filter.values.length > 1 ? filter.values.map(v => v.value) : filter.values[0].value;
            let qi;

            let display;
            if (filter.display) {
                display = filter.display;
            } else if (filter.type === 'term') {
                display = `${displayName}: ${value}`;
            } else if (filter.field === 'age_in_months') {
                display = `${displayName}: ${value[0] / 12} - ${value[1] / 12}`;
            } else {
                display = `${displayName}: ${value[0]} - ${value[1]}`;
            }

            if (filter.type === 'term') {
                qi = queryItem(filter.field, value, filter.operator, filter.core, filter.display, filter.type);
            } else {
                // else queryItem is range
                if (filter.field === 'age_in_months') {
                    qi = queryItem(filter.field, value, filter.operator, filter.core, display, filter.type);
                } else {
                    qi = queryItem(filter.field, value, filter.operator, filter.core, display, filter.type);
                }
            }
            queryItems.push({ sectionKey, queryItem: qi });
        }
    });
    createCurrentQueryWithItems(queryItems);
}