<script>
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
    import PatientTrackingHeader from "../components/patient_tracking/PatientTrackingHeader.svelte";
    import PatientTrackingPage from "../components/patient_tracking/PatientTrackingPage.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{PatientTrackingHeader}" mainContent={PatientTrackingPage}/>
</div>