<script>
    import { createEventDispatcher } from 'svelte';

    let activeTab = "tab1";
    const dispatch = createEventDispatcher();

    function setActiveTab(tab) {
        activeTab = tab;
        dispatch('tabChange', tab);
    }
</script>

<div class="container">
    <button class="button" class:active={activeTab === 'tab1'} on:click={() => setActiveTab('tab1')}>Active Alerts</button>
    <button class="button" class:active={activeTab === 'tab2'} on:click={() => setActiveTab('tab2')}>Paused Alerts</button>
    <button class="button" class:active={activeTab === 'tab3'} on:click={() => setActiveTab('tab3')}>Archived Alerts</button>
</div>

<style>
    .container {
        display: flex;
        justify-content: space-between;
    }

    button {
        flex: 1;
        padding: 10px;
        cursor: pointer;
        background-color: #f2f2f2;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        font-size: 16px;
        font-weight: bold;
    }

    .active {
        background-color: #9d9d9f;
        color: white;
    }
</style>