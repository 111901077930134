import { getToken } from "../store/session";

const defaultParams = {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
};

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

if (!isProduction) {
    defaultParams.origin = 'http://localhost:8059';
    defaultParams.credentials = 'include';
}

const BASE_URL = configureConnectionURLs();
const METHODS = ['GET', 'POST', 'OPTIONS', 'PATCH', 'PUT', 'DELETE', 'HEAD', 'CONNECT', 'TRACE'];

function configureConnectionURLs() {
    let hostInformation = window.location.host;
    let host = hostInformation;
    let sPROTOCOL = "https";
    if (hostInformation.includes("localhost") ||
            hostInformation.includes("127.0.0.1") ||
            hostInformation.includes("192.168") ||
            hostInformation.includes("10.0.1")) {
        host = host.replace("8059", "8000");
        sPROTOCOL = "http";
    }
    return `${sPROTOCOL}://${host}`;
}

const createRequest = (method, body = null, content_type = null) => {
    console.assert(METHODS.includes(method.toUpperCase()), `Invalid HTTP request method: "${method}"`);
    const token = getToken();
    const request = {
        method: method ?? "GET",
        headers,
        ...defaultParams,
    };
    if (body) {
        // check if the body is FormData
        if (body instanceof FormData) {
            request.body = body;
            // remove content-type so browser can set it automatically
            // it should be multipart/form-data and also contain boundary
            delete request.headers['Content-Type'];
        } else {
            request.body = JSON.stringify(body);
            request.headers['Content-Type'] = 'application/json';
            request.headers['Accept'] = content_type ? content_type : 'application/json';
        }
    }
    if (token) {
        request.headers.Authorization = `Token ${token}`;
    }
    return request;
};


export const callApi = async (path, method, body, content_type = null) => {
    // check that the path is valid by splitting on '/', if valid there should only be 1 string that is of length 0
    console.assert(
        !path.includes('//'),
        `a "//" appears in your request: "${path}", check that your query construction is correct`
    );

    const request = createRequest(method, body, content_type);

    return await fetch(BASE_URL + path, request)
        .then(async response => {
            if (!response.ok) {
                throw response;
            }
            return response;
        })
        .catch(async response => {
            console.error(response);
            return response;
        });
};