import {getFacets} from "../network/analytics/facets";

/**
 * Fetch facet options and return them
 * @param facet {String}: facet to query
 * @param asObject {Boolean}: set to true to return the raw object from the query, it returns Object.keys(results) otherwise
 * @returns {Promise<string[]|*[]>}
 */
export default async function fetchFacetOptions(facet, asObject) {
    let response = await getFacets(facet);
    if (response.ok) {
        const results = await response.json();
        // can be array for genomic and immune markers
        if (Array.isArray(results)) {
            let fields = {};
            for (const result of results) {
                fields = {
                    ...fields,
                    ...processFacetTypes(result, facet, asObject)
                };
            }
            return fields;
        } else if (results?.types?.[facet] && Object.keys(results.types[facet]).length) {
            return processFacetTypes(results, facet, asObject);
        }
    } else {
        console.error(`Could not fetch facet '${facet}'`);
        return [];
    }
    return [];
}

/**
 *
 * @param result
 * @param facet
 * @param asObject
 * @returns {string[]}
 */
function processFacetTypes(result, facet, asObject) {
    let processed;
    if (asObject) {
        processed = result.types[facet];
    } else {
        processed = Object.keys(result.types[facet]);
    }
    return processed;
}

export function filter_and_relabel(raw_characteristics, facets_config) {
    // Returns a new object after applying tweaks specified in the
    // facets_config spreadsheet.
    let characteristics = {};
    let values_to_labels = {};

    facets_config.map(function (o) {
        let val = o["solrField"];
        let label = o["displayName"];
        values_to_labels[val] = label;
    });

    // Make a list of just the keys that we want.
    let filterDataValues = facets_config.map(function (o) { return o["solrField"]; });

    // Iterate through all the keys we got back from the database, keep
    // just the ones we want, and switch out the labels.
    // For example, replace "Order Type" with "Order Name" for UH.
    Object.keys(raw_characteristics).forEach(function (k) {

        let data_value = raw_characteristics[k];
        let custom_label = values_to_labels[data_value];

        // We only want a subset of the data values...
        if (filterDataValues.includes(data_value)) {
            characteristics[custom_label] = data_value;
        }
    });

    return characteristics;
}