import {callApi} from "./rest";

const ROOT_URL_CONSULT_CASES = '/consult/cases';

export const getCasesWithUuids = async (fields, uuids, currentPage, selectedPageSize) => {
    let url = `${ROOT_URL_CONSULT_CASES}?fields=${fields}&page=${currentPage}&page_size=${selectedPageSize}`;
    uuids.split(",").forEach((uuid) => {
        url = url + `&uuid=${uuid}`;
    });
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get cases");
    }
};


export const getInProcessCases = async () => {
    const fields = 'id,uuid,status,order_identifier,pdf_report,iiq_status,patient_mrn';

    let url = `${ROOT_URL_CONSULT_CASES}?fields=${fields}&iiq_status=In Process`;

    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get cases");
    }
};


export const fromPDFUpload = async (file, orderID, patientID) =>{
    const url = `${ROOT_URL_CONSULT_CASES}/from-pdf-upload`;

    const requestBody = new FormData();

    requestBody.append('file_url', file);
    requestBody.append('order_identifier', orderID)
    requestBody.append('identifier', patientID)

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};