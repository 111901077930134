<script>
    const year = new Date().getFullYear();
    function goToWebsite() {
        window.open('https://www.genomoncology.com', '_blank');
    }
</script>
<!--Footer-->
<footer class="footer">
    <p class="content">© {year} Powered by <span on:click={goToWebsite} class="gohome">GenomOncology LLC.</span></p>
</footer>

<style>
    .footer {
        background: #111F3A;
        position: fixed;
        width: 100%;
        bottom: 0;
        color: white;
        padding: 16px 0;
    }

    .footer p {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.05em;
        margin: 0;
        margin-left: 24px;
    }
    .gohome {
        cursor: pointer;
    }
</style>
