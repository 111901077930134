<script>
    import AllRadioIcon from '../icons/all-radio-icon.svg';
    import AnyRadioIcon from '../icons/any-radio-icon.svg';
    import NoneRadioIcon from '../icons/none-radio-icon.svg';
    import {RADIO_NONE, RADIO_ANY, RADIO_ALL} from "../utils/constants";

    export let label = '';
    export let icon = null;
    export let onDelete = () => {
        console.log('chip `onDelete` is unimplemented');
    };
</script>

<div class="chip">
    <span class="chip-title">
        { label }
    </span>
    {#if icon !== null}
        {#if icon === RADIO_NONE}
            <img src={NoneRadioIcon} alt="None"/>
        {:else if icon === RADIO_ANY}
            <img src={AnyRadioIcon} alt="Any"/>
        {:else if icon === RADIO_ALL}
            <img src={AllRadioIcon} alt="All"/>
        {/if}
    {:else}
        <!-- give a little space between label and button if no icon is provided -->
        <i></i>
    {/if}
    <button class="delete-button" on:click={onDelete}>
        &times;
    </button>
</div>

<style>
    .chip {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0.75em;
    }

    .chip-title {
        text-transform: capitalize;
        font-weight: bold;
    }

    .chip > img {
        width: 22px;
        height: 22px;
        margin-top: -3px;
    }

    .chip > i {
        margin: 0 1px;
    }

    .delete-button {
        background-color: black;
        color: white;
        border: none;
        border-radius: 50%;
        width: 19px;
        height: 19px;
        margin: -2px 0 0 0;
        padding: 0;
        line-height: 20px;
    }
</style>