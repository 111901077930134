<script>
    import {RADIO_ALL, RADIO_ANY, RADIO_NONE} from '../utils/constants';
    import randomString from "../utils/randomString";

    // separate instances of this component must have unique id's for the form
    // or else they will all be in sync otherwise
    const id = randomString();

    export let value = '';
</script>

<!-- declare CSS variables for use in styles below -->
<div class="filter-options">
    <input id="filter-all-{id}" type="radio" class="radio-filter all" value={RADIO_ALL} bind:group={value}>
    <label for="filter-all-{id}">All</label>

    <input id="filter-any-{id}" type="radio" class="radio-filter any" value={RADIO_ANY} bind:group={value}>
    <label for="filter-any-{id}">Any</label>

    <input id="filter-none-{id}" type="radio" class="radio-filter none" value={RADIO_NONE} bind:group={value}>
    <label for="filter-none-{id}">None</label>
</div>

<style>
    .filter-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    input.radio-filter {
        display: none;
    }

    .radio-filter + label:before {
        content: '';
        display: inline-block;
        height: 22px;
        width: 22px;
        margin: 0px 5px -5px 0px;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    /* CSS variables defined in App.svelte */
    .radio-filter.all + label:before {
        background-image: var(--all-radio-icon);
    }

    .radio-filter.any + label:before {
        background-image: var(--any-radio-icon);
    }

    .radio-filter.none + label:before {
        background-image: var(--none-radio-icon);
    }

    .radio-filter + label:before {
        filter: grayscale(1);
    }

    .radio-filter:checked + label:before {
        filter: grayscale(0);
    }

</style>