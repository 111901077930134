<script>
    /* AccordionSection
    * A header defined by `header` and some content passed in via a slot
    * For a collection of AccordionSections of which only one is open at a time wrap the desired
    * elements in <AccordionGroup> which shares its context and a store with all contained AccordionSections
    */
    import { getContext } from 'svelte';
    import { Icon } from "svelte-awesome";
    import { faCaretSquareDown, faChevronUp, faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";


    export let filters = [];
    export let header = '';
    export let isSubHeader = false;
    export let onToggle = () => {};

    export let open = false;
    const toggleContent = () => {
        open = !open;
        onToggle(open);
    };

    const close = () => (open = false);
    const context = getContext('Accordion');
    const setCurrent = context && context.setCurrent;
    $: open && setCurrent && setCurrent(close);
</script>

<div class="accordion{$$props?.class ? ` ${$$props?.class}` : ''}">
    <div class="header" class:subheader={isSubHeader} on:click={toggleContent}  role="button" aria-expanded="{open}" aria-hidden="true">
        <slot name="header">
            <p class="header-text">
                {header}
            </p>
        </slot>
        <span>
            {#if filters.length !== 0}
                <!-- Hover over icon to show info bubble -->
                <div class="info-icon">
                    <Icon data="{faInfoCircle}" style="cursor: pointer; margin-right: 10px" scale="1" />
                    <div class="info-bubble">
                        {#each filters as filter}
                            <p><b>{filter.displayName}:</b> {filter.description}</p>
                        {/each}
                    </div>
                </div>
            {/if}
            {#if open}
                <Icon data="{faChevronUp}" />
            {:else}
                {#if isSubHeader}
                    <Icon data="{faCaretSquareDown}" scale="1.3" />
                {:else}
                    <Icon data="{faPlus}" />
                {/if}
            {/if}
		</span>
    </div>
    <div class="content" class:open={open}>
        <slot />
    </div>
</div>

<style>
    .accordion {
        border: 1px solid #ddd;
    }

    .accordion .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0 1em;
    }

    .header-text {
        padding: 1em 0;
        margin: 0 !important;
    }

    /* make the subheader a little shorter than full sized headers */
    .subheader {
        padding: 0.75em 0;
        height: 2.5rem;
    }

    .content {
        display: none;
        border-top: 1px solid #ddd;
    }

    .open {
        display: block;
    }
    .info-icon {
        position: relative;
        display: inline-block;
    }

    .info-bubble {
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        width: 200px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }

    /* Show the info bubble on hover */
    .info-icon:hover .info-bubble {
        display: block;
    }
</style>