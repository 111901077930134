<script>
    import {onMount} from 'svelte';
    import Router from 'svelte-spa-router';
    import {SvelteToast} from "@zerodevx/svelte-toast";

    import routes from './routes';
    import {session} from "./store/session";
    import {softLogin} from "./network/user";
    import AuthMain from './pages/AuthMain.svelte';
    import AllRadioIcon from './icons/all-radio-icon.svg';
    import AnyRadioIcon from './icons/any-radio-icon.svg';
    import NoneRadioIcon from './icons/none-radio-icon.svg';

    import {updateMostRecentActivity} from "./network/user";
    import {get} from "svelte/store";

    const options = {
        theme: {
            '--toastWidth': '20rem',
            '--toastHeight': '5rem'
        },
        duration: 1500
    };

    async function handleAction() {
        const response = await updateMostRecentActivity(false);
        if (response.ok) {
            let logoutInfo = await response.json();
            if (logoutInfo.logout) {
                location.reload();
            }
        }
    }

    onMount(async () => {
        const response = await softLogin();
        const body = await response.json();
        if (response.ok && body.token && body.user) {
            $session = body;
            let is_timer_enabled = get(session)?.is_timer_enabled;
            if (null != is_timer_enabled && is_timer_enabled) {
                await updateMostRecentActivity(true);
                window.addEventListener('click', handleAction);
                window.addEventListener('keydown', handleAction);
            }
        }

        // declare CSS variables and append a style tag
        const css = `:root {
            --all-radio-icon: url(${ AllRadioIcon });
            --any-radio-icon: url(${ AnyRadioIcon });
            --none-radio-icon: url(${ NoneRadioIcon })
        }`;
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        head.appendChild(style);

        style.appendChild(document.createTextNode(css));
    });
</script>

<style>
    :global(*){
        font-family: 'Poppins', sans-serif;
    }
</style>

{#if $session?.token}
    <Router {routes}/>
{:else}
    <AuthMain/>
{/if}

<SvelteToast {options}/>
