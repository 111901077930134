import handlebars from 'handlebars/dist/handlebars.min.js'; // plain 'handlebars' is a no-go since it requires fs

/**
 * Builds a queryItem which is used in constructing queries and for displaying the query
 * @param queryKey {String}: key for backend payload
 * @param value {String|Array}: value of the query item, goes along with queryKey in payload
 * @param icon {String}: icon for the applied filters chip, later used in query for filter type
 * @param core {String}: Solr core that this field belongs to
 * @param display {String}: a handlebars template string for displaying the value in the chip
 * @param keyType {String}: a keytype for the queryItem, either 'term' or 'range', default is 'term'
 * @returns {{queryKey, icon, value, keyType, display}}
 */
export default function queryItem(queryKey, value, icon, core, display = '{{value}}', keyType = 'term') {
    const template = handlebars.compile(display);
    const obj = {
        value,
        icon,
        queryKey,
        keyType,
        core
    };
    obj.display = template({ value });
    return obj;
}