<script>
    // components
    import AccordionGroup from '../accordion/AccordionGroup.svelte';
    // store
    import {build_export_filters_and_update_display_names, getFacetConfig} from "../../network/analytics/facets";
    import {onMount} from "svelte";

    export let configured_facets = [];

    onMount(async () => {
        configured_facets = await getFacetConfig();
    });

    // computed variables for accordion section headers
    $: export_filters = build_export_filters_and_update_display_names(configured_facets, true);

</script>

<div class="query-filter">
    <div role="group" aria-label="Construct Query Filters">
        <!-- QUERIES FILTERS -->
        <AccordionGroup>
            {#each export_filters as cat}
                {#if cat.catComponent !== undefined}
                    <svelte:component this={cat.catComponent} {...cat.componentProps} />
                {/if}
            {/each}
        </AccordionGroup>
    </div>
</div>

<style>
    .range-slide-labels span {
        font-size: 1.5em;
    }

    .query-row input {
        width: 80%;
        padding: 8px 12px;
    }

</style>