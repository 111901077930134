<script>
    import AddCasesTable from "../components/add_cases/AddCasesTable.svelte";
    import AppLayout from "../components/shared/AppLayout.svelte";
    import AddCasesHeader from "../components/add_cases/AddCasesHeader.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{AddCasesHeader}" mainContent={AddCasesTable}/>
</div>


