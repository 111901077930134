import {callApi} from "./rest.js";

const ROOT_URL = '/api/users';

export const login = async (username, password) => {
    const url = `${ROOT_URL}/login`;
    const body  = {username, password};
    return await callApi(url, "POST", body);
};

export const logout = async () => {
    const url = `${ROOT_URL}/logout`;
    await callApi(url, "GET");
    // unlike other calls we do not care about the response of logout
    location.reload();
};

export const softLogin = async () => {
    const url = `${ROOT_URL}/soft_login`;
    return await callApi(url, "POST", null);
};

export const register2FA = async (user_id, email, phone_number) => {
    const url = `${ROOT_URL}/${user_id}/register2FA`;
    return await callApi(url, "POST", {email, phone_number});
};

export const verify2FA = async (user_id, token) => {
    const url = `${ROOT_URL}/${user_id}/verify2FA`;
    return await callApi(url, "POST", {token});
};

export const updateCurrentAccount = async (user_id, body) => {
    const url = `${ROOT_URL}/${user_id}`;
    return await callApi(url, "PATCH", body);
};

export const changePassword = async (old_password, new_password) => {
    const url = `${ROOT_URL}/change_password`;
    const body = {old_password: old_password, new_password: new_password};
    return await callApi(url, "PUT", body);
};

export const deleteUser = async (id) => {
    const url = `${ROOT_URL}/${id}`;
    const response = await callApi(url, "DELETE");
    if (response.ok) {
        return null;
    } else {
        console.error("Couldn't delete User");
    }
};

export const getUser = async (id) => {
    const url = `${ROOT_URL}/${id}`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't delete User");
    }
};

export const getNotifications = async (id) => {
    const url = `${ROOT_URL}/${id}/alerts?page=1&page_size=10000`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get Notifications");
    }
};

export const getUsers = async () => {
    const url = `${ROOT_URL}/`;
    return await callApi(url, "GET");
};

export const getAlertConfigs = async (id, currentPage, selectedPageSize, status, ordering, patient_status, include_shared_with) => {
    let url = `${ROOT_URL}/${id}/alert_configs?page=${currentPage}&page_size=${selectedPageSize}&status=${status}`;

    if (ordering) {
        url = `${url}&sort_by=${ordering}`;
    }

    if (patient_status) {
        url = `${url}&patient_status=${patient_status}`;
    }

    if (include_shared_with) {
        url = `${url}&include_shared_with=${include_shared_with}`;
    }

    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't gather alerts");
    }
};

export const getFilteredAlerts = async (id, searchQueries) => {
    let url = `${ROOT_URL}/${id}/filtered_alerts?`;

    const searchParams = Object.entries(searchQueries)
        .map(([field, value]) => value && `${field}=${encodeURIComponent(value)}`)
        .filter(Boolean)
        .join('&');

    if (searchParams) {
        url = `${url}&${searchParams}`;
    }

    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't gather filtered alerts");
    }
};

export const deleteAlertConfig = async (id) => {
    const url = `${ROOT_URL}/alert_configs/${id}`;
    return await callApi(url, "DELETE");
};

export const updateAlertConfig = async (id, body) => {
    const url = `${ROOT_URL}/alert_configs/${id}`;
    return await callApi(url, "PUT", body);
};

export const patchAlertConfig = async (id, body, update_last_run_date) => {
    const url = `${ROOT_URL}/alert_configs/${id}?update_last_run_date=${update_last_run_date}`;
    return await callApi(url, "PATCH", body);
};

export const getAlertConfig = async (id) => {
    const url = `${ROOT_URL}/alert_configs/${id}`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get alert");
    }
};

export const createAlertConfig = async (user_id, body) => {
    const url = `${ROOT_URL}/${user_id}/alert_configs`;
    return await callApi(url, "POST", body);
};

export const updateHasViewedNotifications = async () => {
    const url = `${ROOT_URL}/change_has_viewed_notifications`;
    return await callApi(url, "PUT", {});
};

export const updateMostRecentActivity = async (on_login) => {
    const url = `${ROOT_URL}/change_most_recent_activity`;
    const body = {on_login: on_login};
    return await callApi(url, "PUT", body);
};


export const getAlertStatistics = async (user_id, alertId) => {
    const url = `${ROOT_URL}/${user_id}/alerts/${alertId}/statistics/`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't fetch alert statistics");
    }
};
