<script>
    import PatientTrackingTabs from './PatientTrackingTabs.svelte';
    import PatientTrackingTabContent from "./PatientTrackingTabContent.svelte";

    let activeTab = 'tab1';

</script>

<div>
    <PatientTrackingTabs on:tabChange={e => activeTab = e.detail}/>
    <PatientTrackingTabContent {activeTab}/>
</div>