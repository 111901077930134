<script>
    import CreateAlert from "./CreateAlert.svelte";
    import AlertsLeftNav from "./AlertsLeftNav.svelte";
    import {alertPage} from "../../store/navigation";

    import { HSplitPane } from "svelte-split-pane";
    import AlertManagementTabs from "./AlertManagementTabs.svelte";
    import AlertManagementTabContent from "./AlertManagementTabContent.svelte";

    let showleftNav = true;
    let showMainContent = true;
    let activeTab = 'tab1';


</script>

<style>
    div.wrapper {
        height:calc(80vh - 51px);
        margin: 0px 10px 10px 10px;
    }
</style>

<div>
    <div class="wrapper">
        <HSplitPane  leftPaneSize = "5%" rightPaneSize="95%"  minLeftPaneSize = "150px" minRightPaneSize="1000px">
            <left slot="left">
                <div class="alert-left-nav">
                    <AlertsLeftNav />
                </div>
            </left>
            <right slot="right">
                <div>
                    {#if $alertPage==="create"}
                        <CreateAlert />
                    {:else}
                        <AlertManagementTabs on:tabChange={e => activeTab = e.detail}/>
                        <AlertManagementTabContent {activeTab}/>
                    {/if}
                </div>
            </right>
        </HSplitPane>
    </div>
</div>
