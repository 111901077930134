<script>
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    function toLogin() {
        dispatch("toLogin");
    }

    function forgotPassword() {
        dispatch("forgotPassword");
    }
</script>

<h2>Request Successful</h2>

<div class="ui visible info message">
    Please check your email for a link for resetting your password.
</div>

<div id="buttons">
    <button class="ui large primary submit button" on:click={toLogin}>
        Back to Login
    </button>
    <button class="ui large black submit button" on:click={forgotPassword}>
        Re-request Code
    </button>
</div>

<style>
    h2 {
        padding-bottom: 1em;
    }
    div#buttons {
        padding-top: 2em;
    }
</style>