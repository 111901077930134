<script>
    import AlertQueryFilter from './AlertQueryFilter.svelte';
    import Chip from '../Chip.svelte';
    import IconKeyRow from "../IconKeyRow.svelte";
    import {
        queryKeys,
        currentQuery, remove, removeIndex
    } from "../../store/currentAlertQuery";
    import {onMount} from "svelte";
    import {build_export_filters_and_update_display_names, getFacetConfig} from "../../network/analytics/facets";
    import setFacetConfig from "../../store/facetConfig";
    import AlertConfiguration from "./AlertConfiguration.svelte";

    let configuredFacets = [];

    onMount(async () => {
        configuredFacets = await getFacetConfig();
        setFacetConfig(configuredFacets);
    });

    $: export_filters = build_export_filters_and_update_display_names(configuredFacets, true);

</script>

<div class="create-alert-page">
    <section class="query-builder">
        <div class="alert-config-header">
            <h1 class="title">
                Alert Configuration
            </h1>
        </div>
        <div>
            <AlertConfiguration/>
        </div>
    </section>
    <section class="applied-filters">
        <div class="row align-items-center flex-flow-wrap">
            <strong class="applied-filters-title">Applied Filters</strong>
            <IconKeyRow/>
            {#each $queryKeys as key}
                {#if $currentQuery[key]}
                    {#if Array.isArray($currentQuery[key])}
                        {#each $currentQuery[key] as item, index}
                            <Chip label={item.display} icon={item.icon} onDelete={removeIndex(key, index)}/>
                        {/each}
                    {:else }
                        <Chip label={$currentQuery[key].display} icon={$currentQuery[key].icon} onDelete={remove(key)}/>
                    {/if}
                {/if}
            {/each}
        </div>
        <div class="query-filter">
            <div class="query-filter-header">
                <h3 class="title">
                    Construct Query
                </h3>
            </div>
            <AlertQueryFilter/>
        </div>
    </section>
</div>

<style>
    .query-filter-header {
        background-color: #233C6B;
        color: white;
        padding: 0.75em 1em;
    }

    .query-filter-header .title {
        text-align: center;
        font-size: 25px;
    }

    .alert-config-header {
        background-color: #233C6B;
        color: white;
        padding: 0.75em 1em;
    }

    .alert-config-header .title {
        text-align: center;
    }

    .create-alert-page {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 50px;
        display: flex;
        height: calc(80vh - 51px);
    }

    .applied-filters-title {
        font-size: 1.25em;
    }
    .applied-filters {
        flex: 4;
        padding: 0.5em 1em;
        height: calc(70vh - 50px);
        overflow-x: auto;
    }
    .query-builder {
        flex: 1;
        border: 1px solid #ccc;
        height: 375px;
    }
</style>