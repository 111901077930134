<script>
    import queryItem from "../../utils/queryItem";
    import {add} from "../../store/currentAlertQuery";
    import {RADIO_ALL} from "../../utils/constants";
    import PlusIcon from '../../icons/plus-sign.svg';
    import SVGButton from "../SVGButton.svelte";
    import AutoComplete from "../AutoComplete/AutoComplete.svelte";

    //Field to query on in solr
    export let solrField;
    //How this filter will be named in the UI
    export let displayName;
    //ALL - ANY - NONE query option
    export let filterMode = RADIO_ALL;
    export let core;
    //Fetched Values
    export let allValues;
    export let queryLabel = "genomicMarkerCharacteristics";

    let textValue = '';
    const addFilter = () => {
        add(queryLabel, queryItem(solrField, textValue, filterMode, core, `${displayName}: ${textValue}`))();
        textValue = '';
    };

</script>

<AutoComplete bind:allNames={allValues} bind:inputValue={textValue} placeHolder="Search Disease"/>
<SVGButton onClick={addFilter} svg={PlusIcon} />

<style>
    input {
        width: 80%;
        padding: 8px 12px;
    }
</style>