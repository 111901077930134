<script>
    import {changePassword} from "../../network/user";

    export let closeResetModal;
    let oldPassword = '';
    let newPassword = '';
    let confirmPassword = '';
    let message = '';

    async function handlePasswordChange() {
        if (newPassword !== confirmPassword) {
            message = 'New passwords do not match.';
            return;
        }
        if (newPassword.length < 8) {
            message = 'New password must be at least 8 characters long.';
            return;
        }
        await changePassword(oldPassword, newPassword)
        message = 'Password changed successfully!';
        closeResetModal();
    }
</script>

<form on:submit|preventDefault={handlePasswordChange}>
    <div>
        <label for="old-password">Old Password:</label>
        <input type="password" id="old-password" bind:value={oldPassword} required>
    </div>
    <div>
        <label for="new-password">New Password:</label>
        <input type="password" id="new-password" bind:value={newPassword} required>
    </div>
    <div>
        <label for="confirm-password">Confirm New Password:</label>
        <input type="password" id="confirm-password" bind:value={confirmPassword} required>
    </div>
    <button class="modalButton" type="submit">Change Password</button>
    {#if message}
        <p>{message}</p>
    {/if}
</form>

<style>
    input, button {
        margin-top: 10px;
        display: block;
    }
    label {
        margin-top: 10px;
        display: block;
    }
    .modalButton{
        background-color: #5da3d9;
        width: 30%;
        border-radius: 5px;
        color: white;
        -webkit-font-smoothing: antialiased;
    }
</style>