<script>
    import {onMount} from 'svelte';
    import {CASE_FIELDS} from "../../utils/constants";
    import {getCasesWithUuids} from "../../network/cases";
    import Loader from "../shared/Loader.svelte";
    import {Icon} from "svelte-awesome";
    import {faCheckCircle, faEdit, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";
    import Modal from "../Modal.svelte";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {
        createPatientTrackingStatus,
        createPatientTrackingStatusComment,
        deletePatientTrackingStatus,
        getAllPatientTrackingStatusComments,
        getAllPatientTrackingStatuses
    } from "../../network/patient_search";
    import {getPdModOverrideVar, getUser} from "../../store/session";
    import {HSplitPane} from "svelte-split-pane";
    import AdditionalPatientInfoSideBar from "./AdditionalPatientInfoSideBar.svelte";
    import {patientInfoOptions} from '../../store/patientInfoOptions.js';
    import AdditionalPatientInfoTable from "./AdditionalPatientInfoTable.svelte";

    let cases_summary = {};
    let cases = [];
    let isLoading = false;
    let trackPatientModal;
    let notTrackPatientModal;
    let needsMoreReviewPatientModal;
    let editTrackPatientModal;
    let commentModal;
    let noTrackReason = '';
    let selectedNonTrackOptions = [];
    let currentCase;
    let currentAlertId;
    let currentAlertConfigName;
    let trackedPatientId;
    let hideLeftNav = false;
    let leftPaneWidth = "17%";
    let rightPaneWidth = "83%";
    let commentValue = '';
    let comments = [];
    let caseExportModal;
    let exportData = [];
    let patient_tracking_statuses = [];
    let tableData = [];
    let additionalPatientInfoCase;
    let sortedColumn = '';
    let sortOrder = 'asc';

    export let filteredNotificationData;
    export let isNotificationView;

    const presetNonTrackOptions = [
        { value: 'age', label: 'Age' },
        { value: 'biomarker ineligibility', label: 'Biomarker Ineligibility' },
        { value: 'cancer type', label: 'Cancer Type'},
        { value: 'cardiac', label: 'Cardiac' },
        { value: 'medical condition', label: 'Medical Condition' },
        { value: 'medical history', label: 'Medical History' },
        { value: 'number of prior therapies', label: 'Number of Prior Therapies' },
        { value: 'performance status inadequate', label: 'Performance Status Inadequate' },
        { value: 'stage of disease', label: 'Stage of Disease' }
    ];

    async function fetchCases() {
        isLoading = true;
        let patient_tracking_response = await getAllPatientTrackingStatuses();
        patient_tracking_statuses = patient_tracking_response.results;
        tableData = filteredNotificationData;
        let uuids = getAllCaseUuids(filteredNotificationData);
        cases_summary = await getCasesWithUuids(CASE_FIELDS, uuids, 1, 10000);
        cases = cases_summary.results;
        let comment_summary = await getAllPatientTrackingStatusComments();
        comments = comment_summary.results;
        isLoading = false;
    }

    function getAllCaseUuids(data) {
        let uniqueUUIDs = new Set();

        data.forEach(item => {
            item.case_uuids.forEach(uuid => {
                uniqueUUIDs.add(uuid);
            });
        });

        return Array.from(uniqueUUIDs).join(",");
    }

    function getCase(uuid) {
        return cases.find(caseObj => caseObj.uuid === uuid);
    }

    function checkForTrack(patient_id, alert_config_id) {
        return patient_tracking_statuses.some(status => status.patient === patient_id && status.alert_config === alert_config_id);
    }

    function getTrackStatus(patient_id, alert_config_id) {
        return patient_tracking_statuses.find(status => status.patient === patient_id && status.alert_config === alert_config_id);
    }

    patientInfoOptions.subscribe(value => {
        exportData = value;
    });

    function sortTable(column) {
        if (sortedColumn === column) {
            sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
            sortOrder = 'asc';
        }

        sortedColumn = column;

        const table = document.querySelector("table");
        const tbody = table.querySelector("tbody");
        const rowsArray = Array.from(tbody.querySelectorAll("tr"));

        rowsArray.sort((a, b) => {
            const aValue = a.querySelector(`td[data-column="${column}"]`).innerText;
            const bValue = b.querySelector(`td[data-column="${column}"]`).innerText;

            if (aValue < bValue) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });

        rowsArray.forEach(row => tbody.appendChild(row));
    }

    function handleCheckboxChange(event, optionValue) {
        if (event.target.checked) {
            selectedNonTrackOptions = [...selectedNonTrackOptions, optionValue];
        } else {
            selectedNonTrackOptions = selectedNonTrackOptions.filter(value => value !== optionValue);
        }
    }

    function handleOpenTrackPatientModal(c, alert_id, alert_config_name) {
        currentCase = c;
        currentAlertId = alert_id;
        currentAlertConfigName = alert_config_name;
        trackPatientModal.open()
    }

    function handleOpenNotTrackPatientModal(c, alert_config_name) {
        currentCase = c;
        currentAlertConfigName = alert_config_name;
        notTrackPatientModal.open()
    }

    function handleOpenNeedsMoreReviewPatientModal(c, alert_config_name) {
        currentCase = c;
        currentAlertConfigName = alert_config_name;
        needsMoreReviewPatientModal.open()
    }

    function handleCommentModalOpen(c, alert_config_name) {
        currentCase = c;
        currentAlertConfigName = alert_config_name;
        commentModal.open()
    }

    const onTrackPatient = async () => {
        let trigger = currentCase.alert_triggers.find(item => item.alert_id === currentAlertId);
        let diseases = [];
        let alts = [];
        let genes = [];
        if (trigger !== undefined) {
            if (trigger.disease_triggers !== "[]") {
                diseases = trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
            if (trigger.alteration_triggers !== "[]") {
                alts = trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
            if (trigger.gene_triggers !== "[]") {
                genes = trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
        }
        const body = {
            "alert_config_name": currentAlertConfigName,
            "patient_uuid": currentCase.patient_uuid,
            "tracked": true,
            "is_waiting": true,
            "tracked_user_name": getUser().first_name + " " + getUser().last_name,
            "matched_alterations": alts,
            "matched_genes": genes,
            "matched_diseases": diseases
        };
        let response = await createPatientTrackingStatus(body);
        if (response.ok) {
            trackPatientModal.close();
            await fetchCases();
            pushSuccess("Successfully tracking patient.");
        } else {
            trackPatientModal.close();
            pushFail("Failed to track patient.");
        }
    }

    const onNotTrackPatient = async () => {
        let not_tracking_reasons = selectedNonTrackOptions.join(",");
        if (noTrackReason.trim().length > 0) {
            if (not_tracking_reasons !== '') {
                not_tracking_reasons = not_tracking_reasons + "," + noTrackReason;
            } else {
                not_tracking_reasons = noTrackReason;
            }
        }
        if (not_tracking_reasons !== '') {
            const body = {
                "alert_config_name": currentAlertConfigName,
                "patient_uuid": currentCase.patient_uuid,
                "not_tracking_reasons": not_tracking_reasons,
                "tracked_user_name": getUser().first_name + " " + getUser().last_name
            };
            let response = await createPatientTrackingStatus(body);
            if (response.ok) {
                noTrackReason = '';
                selectedNonTrackOptions = [];
                notTrackPatientModal.close();
                await fetchCases();
                pushSuccess("Successfully not tracking patient.");
            } else {
                notTrackPatientModal.close();
                pushFail("Failed to save not tracking for patient.");
            }
        } else {
            pushFail("Must add a reason for not tracking.");
        }

    }

    const onNeedsMoreReviewPatient = async () => {
        const body = {
            "alert_config_name": currentAlertConfigName,
            "patient_uuid": currentCase.patient_uuid,
            "needs_more_review": true,
            "tracked_user_name": getUser().first_name + " " + getUser().last_name
        };
        let response = await createPatientTrackingStatus(body);
        if (response.ok) {
            needsMoreReviewPatientModal.close();
            await fetchCases();
            pushSuccess("Successfully designated patient as needs more review.");
        } else {
            notTrackPatientModal.close();
            pushFail("Failed to designate patient as needs more review.");
        }
    }

    const onSaveComment = async () => {
        if (commentValue !== '') {
            const comment_body = {
                "comment": commentValue,
                "user_created": getUser().first_name + " " + getUser().last_name,
                "alert_config_name": currentAlertConfigName,
                "patient": currentCase.patient_id
            };
            let comment_response = await createPatientTrackingStatusComment(comment_body);
            if (comment_response.ok) {
                commentValue = '';
                commentModal.close();
                await fetchCases();
                pushSuccess("Successfully created tracked patient comment.");
            } else {
                commentValue = '';
                commentModal.close();
                pushFail("Failed to create tracked patient comment.");
            }
        }
    }

    function handleNotTrackModalClose() {
        noTrackReason = '';
        notTrackPatientModal.close();
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function formatTime(datetimeString) {
        let date = new Date(datetimeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    function toggleDropdown(index1, index2) {
        tableData[index1].uuid_to_alert_info_dict[tableData[index1].case_uuids[index2]].showDropdown = !tableData[index1].uuid_to_alert_info_dict[tableData[index1].case_uuids[index2]].showDropdown;
        handleAdditionalPatientInfoCase(tableData[index1].case_uuids[index2]);
    }

    function handleOpenEditTrackingModal(patient_id, alert_config_id) {
        trackedPatientId = getTrackStatus(patient_id, alert_config_id).id;
        editTrackPatientModal.open()
    }

    const onEditTrackPatient = async () => {
        let response = await deletePatientTrackingStatus(trackedPatientId);
        if (response.ok) {
            editTrackPatientModal.close();
            await fetchCases();
            pushSuccess("Successfully updated tracking status.");
        } else {
            editTrackPatientModal.close();
            pushFail("Failed to update tracking status.");
        }
    };

    function getMatchingParameters(c, alert_id) {
        if (c.alert_triggers.length !== 0) {
            let trigger = c.alert_triggers.find(item => item.alert_id === alert_id);
            if (trigger !== undefined) {
                let finalString = "";
                if (trigger.disease_triggers !== "[]") {
                    finalString = finalString + "<b>Diseases:</b> " +
                        trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.alteration_triggers !== "[]") {
                    finalString = finalString + "<br><b>Alterations:</b> " +
                        trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.gene_triggers !== "[]") {
                    finalString = finalString + "<br><b>Genes:</b> " +
                        trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                return finalString;
            }
        }
        return "";
    }

    function getInfoFromCase(c, field) {
        let value = 'N/A';
        if (field in c) {
            value = c[field];
        } else if (c['meta'] !== null && field in c['meta']) {
            value = c['meta'][field];
        } else if (c['patient_information'] !== null && field in c['patient_information'][0]) {
            value = c['patient_information'][0][field];
        }

        if (Array.isArray(value)) {
            if (value.every(value => value === null)) {
                return "N/A";
            } else {
                return Array.from(new Set(value)).join(", ");
            }
        } else {
            if (value === null) {
                return "N/A";
            } else {
                return value;
            }
        }
    }

    function clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    const onCaseExport = async () => {
        let caseList = [];
        tableData.forEach(data => {
            data.case_uuids.forEach(uuid => {
                let case_info = clone(getCase(uuid));
                case_info["alert_name"] = data.uuid_to_alert_info_dict[String(uuid)].notification_name;
                case_info["notification_date"] = data.uuid_to_alert_info_dict[String(uuid)].notification_date;
                caseList = [...caseList, case_info];
            });
        });
        await exportToCSV(caseList);
        caseExportModal.close();
    };

    function generateCSVContent(data) {
        // Define the headers for the CSV
        let headers = [
            "Full Name",
            "Alert Name",
            "Notification Date",
            "Patient MRN",
            "Ordering Physician",
            "Assay Names",
            "Lab Reported Dates",
            "Matching Parameters"
        ];
        exportData.forEach((option) => {
            if (option.visible) {
                headers = [...headers, option.name];
            }
        });

        // Convert each patient's lab results into CSV rows
        const rows = [];
        data.forEach((c) => {
            let full_name = c.patient_name;
            let alert_name = c.alert_name;
            let notification_date = c.notification_date;
            let patient_mrn = c.patient_mrn;
            let ordering_physician = c.patient_information[0].ordering_physician;
            let assay_names = c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let reported_dates = Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let matching_parameters = getMatchingParameters(c);
            let row_info = [
                full_name || "",
                alert_name || "",
                notification_date || "",
                patient_mrn || "",
                ordering_physician || "",
                assay_names || "",
                reported_dates || "",
                matching_parameters || ""];
            exportData.forEach((option) => {
                if (option.visible) {
                    row_info = [...row_info, getInfoFromCase(c, option.field)];
                }
            });
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    async function exportToCSV(data) {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent(data))}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = "cases.csv";
        link.click();
    }

    function handleLeftNav() {
        hideLeftNav = !hideLeftNav;
        leftPaneWidth = hideLeftNav ? "0%" : "17%";
        rightPaneWidth = hideLeftNav ? "100%" : "83%";
    }

    function handleAdditionalPatientInfoCase(uuid) {
        additionalPatientInfoCase = getCase(uuid);
    }

    function getComments(patient_id, alert_config_id) {
        let patient_comments = comments.filter(comment => comment.patient === patient_id && comment.alert_config === alert_config_id);
        patient_comments = patient_comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        let string = "";
        patient_comments.forEach((c) => {
            string = string + "<li>" + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")" + "</li>";
        })
        return string;
    }

    onMount(() => {
        fetchCases();
    });

</script>
<style>
    .container {
        display: flex;
        height: calc(80vh - 51px);
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    thead th {
        position: sticky;
        top: 0;
    }
    th {
        background-color: #233C6B;
        color: #ffffff;
    }
    tr {
        background-color: white;
    }
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }
    .summary {
        height: 65vh;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 94%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .hide-additional-fields {
        background-color: rgba(7, 7, 7, 0.19);
        color: black;
        margin-right: 25px;
    }
    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .icon-container {
        display: inline-flex;
        align-items: center;
        background-color: white;
    }
    .modal-button{
        margin-right: 10px;
        padding: 8px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .modal-footer-row{
        margin-top: 20px;
        display: flex;
    }
    .checkbox{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .no-track-reason-input{
        float: left;
        margin-top: 10px;
        width: 80%;
    }
    .container-decision {
        display: flex;
        flex-direction: column; /* Stacks items vertically */
        margin-right: 10px;
    }
    .item {
        margin-bottom: 5px;
        font-size: 12px;
    }
    .decision-item {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
    }
    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }
    .big-textbox {
        width: 400px;
        height: 200px;
        resize: horizontal;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 15px;
    }
    .export-icon-png-large {
        width: 50px;
        cursor: pointer;
    }
    .more-review-icon-png {
        width: 35px;
        cursor: pointer;
    }

</style>
<div class="container">
    <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth}>
        <left slot="left">
            {#if !hideLeftNav}
                <div>
                    <AdditionalPatientInfoSideBar/>
                </div>
            {/if}
        </left>
        <right slot="right">
            <div class="summary">
                <div class="title">
                    <button class="hide-additional-fields" on:click={() => handleLeftNav()}>
                        {hideLeftNav ? 'View Additional Info' : 'Hide Additional Info'}
                    </button>
                    <div on:click={() => isNotificationView = true} aria-hidden="true" style="margin-right: 20px">
                        <span title="Close">
                            <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a"/>
                        </span>
                    </div>
                    <div on:click={() => caseExportModal.open()} aria-hidden="true">
                        <div title="Export">
                            <img src="static/images/exportCurrentResultsIcon.png"
                                 class="export-icon-png-large" alt=""></div>
                    </div>
                </div>
                <div class="scrollable-table">
                    {#if isLoading}
                        <Loader/>
                    {:else}
                        <table>
                            <thead>
                            <tr>
                                <th on:click={() => sortTable('name')}>
                                    Patient Name
                                </th>
                                <th>MRN</th>
                                <th on:click={() => sortTable('physician')}>
                                    Ordering Physician
                                </th>
                                {#if getPdModOverrideVar().includes('patient_tracking')}
                                    <th style="width: 15%;">Track Patient</th>
                                {/if}
                                <th on:click={() => sortTable('alert_name')}>
                                    Alert Name
                                </th>
                                <th on:click={() => sortTable('notification_date')}>
                                    Notification Date
                                </th>
                                {#if getPdModOverrideVar().includes('patient_tracking')}
                                    <th style="width: 20%;">Comments</th>
                                {/if}
                                <th>Test Type</th>
                                <th on:click={() => sortTable('date')}>
                                    Test Result Date
                                </th>
                                <th>Matching Parameters</th>
                                <th>Clinical Data</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each tableData as data, i1}
                                {#each data.case_uuids as c, i2}
                                    <tr>
                                        <td data-column="name">{getCase(c).patient_name}</td>
                                        <td>{getCase(c).patient_mrn}</td>
                                        <td data-column="physician">{getCase(c).patient_information[0].ordering_physician}</td>
                                        {#if getPdModOverrideVar().includes('patient_tracking')}
                                            {#if checkForTrack(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id)}
                                                {#if getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).tracked}
                                                    <td>
                                                        <div class="icon-container">
                                                            <div>
                                                                <Icon data={faCheckCircle} scale="1.75" style="color: green; margin-right: 10px;"/>
                                                            </div>
                                                            <div class="container-decision">
                                                                <div class="decision-item">Tracked</div>
                                                                <div class="item">{getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).tracked_user_name}</div>
                                                                <div class="item">{formatDate(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                                <div class="item">{formatTime(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                            </div>
                                                            <div on:click={() => handleOpenEditTrackingModal(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id)} aria-hidden="true">
                                                                <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                {:else}
                                                    {#if getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).needs_more_review}
                                                        <td>
                                                            <div class="icon-container">
                                                                <div class="container-decision">
                                                                    <div class="decision-item">Further Review</div>
                                                                    <div class="item">{getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).tracked_user_name}</div>
                                                                    <div class="item">{formatDate(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                                    <div class="item">{formatTime(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                                </div>
                                                                <div>
                                                                    <img src="static/images/needsMoreReviewIcon.png" class="more-review-icon-png" alt="">
                                                                </div>
                                                                <div on:click={() => handleOpenEditTrackingModal(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id)} aria-hidden="true">
                                                                    <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    {:else}
                                                        <td>
                                                            <div class="icon-container">
                                                                <div class="container-decision">
                                                                    <div class="decision-item">Not Tracked</div>
                                                                    <div class="item">{getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).not_tracking_reasons.replaceAll(",", ", ")}</div>
                                                                    <div class="item">{getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).tracked_user_name}</div>
                                                                    <div class="item">{formatDate(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                                    <div class="item">{formatTime(getTrackStatus(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id).created)}</div>
                                                                </div>
                                                                <div>
                                                                    <Icon data={faTimesCircle} scale="1.75" style="color: red;"/>
                                                                </div>
                                                                <div on:click={() => handleOpenEditTrackingModal(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id)} aria-hidden="true">
                                                                    <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    {/if}
                                                {/if}
                                            {:else}
                                                <td>
                                                    <div class="icon-container">
                                                        <div on:click={() => handleOpenTrackPatientModal(getCase(c), data.uuid_to_alert_info_dict[String(c)].alert_id, data.uuid_to_alert_info_dict[String(c)].notification_name)} aria-hidden="true">
                                                            <Icon data={faCheckCircle} scale="1.75" style="color: green; margin-right: 10px; cursor: pointer"/>
                                                        </div>
                                                        <div on:click={() => handleOpenNotTrackPatientModal(getCase(c), data.uuid_to_alert_info_dict[String(c)].notification_name)} aria-hidden="true">
                                                            <Icon data={faTimesCircle} scale="1.75" style="color: red; margin-right: 10px; cursor: pointer"/>
                                                        </div>
                                                        <div on:click={() => handleOpenNeedsMoreReviewPatientModal(getCase(c), data.uuid_to_alert_info_dict[String(c)].notification_name)} aria-hidden="true">
                                                            <img src="static/images/needsMoreReviewIcon.png" class="more-review-icon-png" alt="">
                                                        </div>
                                                    </div>
                                                </td>
                                            {/if}
                                        {/if}
                                        <td data-column="alert_name">{data.uuid_to_alert_info_dict[String(c)].notification_name}</td>
                                        <td data-column="notification_date">{formatDate(data.uuid_to_alert_info_dict[String(c)].notification_date)}</td>
                                        {#if getPdModOverrideVar().includes('patient_tracking')}
                                            <td>
                                                {@html getComments(getCase(c).patient_id, data.uuid_to_alert_info_dict[String(c)].alert_config_id)}
                                                <button on:click={() => handleCommentModalOpen(getCase(c), data.uuid_to_alert_info_dict[String(c)].notification_name)} style="background-color: rgba(144, 195, 76, 1); margin-top: 10px"> Add New Comment </button>
                                            </td>
                                        {/if}
                                        <td>{getCase(c).assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ")}</td>
                                        <td data-column="date">{Array.from(new Set(getCase(c).lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ")}</td>
                                        <td>{@html getMatchingParameters(getCase(c), data.uuid_to_alert_info_dict[String(c)].alert_id)}</td>
                                        <td>
                                            <button on:click={() => toggleDropdown(i1, i2)}>
                                                {tableData[i1].uuid_to_alert_info_dict[tableData[i1].case_uuids[i2]].showDropdown ? 'Close' : 'View'}
                                            </button>
                                        </td>
                                    </tr>
                                    {#if tableData[i1].uuid_to_alert_info_dict[tableData[i1].case_uuids[i2]].showDropdown}
                                        <tr>
                                            <td colspan="11">
                                                <div class="table">
                                                    <AdditionalPatientInfoTable {additionalPatientInfoCase}/>
                                                </div>
                                            </td>
                                        </tr>
                                    {/if}
                                {/each}
                            {/each}
                            </tbody>
                        </table>
                    {/if}
                </div>
            </div>
        </right>
    </HSplitPane>
</div>

<!--Track Patient Modal-->
<form on:submit|preventDefault={onTrackPatient}>
    <Modal bind:modalFunc={trackPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to track this patient?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Confirm</button>
                <button class="modal-button" on:click={() => trackPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Not Track Patient Modal-->
<form on:submit|preventDefault={onNotTrackPatient}>
    <Modal bind:modalFunc={notTrackPatientModal}>
        <div slot="header">
            <div>
                <h1>Select reason for not tracking patient</h1>
            </div>
        </div>
        <div slot="content">
            {#each presetNonTrackOptions as option}
                <label class="checkbox">
                    <input type="checkbox" value={option.value} on:change={(e) => handleCheckboxChange(e, option.value)} />
                    {option.label}
                </label>
            {/each}
            <input class="no-track-reason-input" type="text" bind:value={noTrackReason} name="alertName" placeholder="Alternate Reason">
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Submit</button>
                <button class="modal-button" on:click={() => handleNotTrackModalClose()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Track Patient Modal-->
<form on:submit|preventDefault={onNeedsMoreReviewPatient}>
    <Modal bind:modalFunc={needsMoreReviewPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to flag this patient for review?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Confirm</button>
                <button class="modal-button" on:click={() => needsMoreReviewPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Edit Track Patient Modal-->
<form on:submit|preventDefault={onEditTrackPatient}>
    <Modal bind:modalFunc={editTrackPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to edit this patients tracking status?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Edit</button>
                <button class="modal-button" on:click={() => editTrackPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Comment Modal-->
<form on:submit|preventDefault={onSaveComment}>
    <Modal bind:modalFunc={commentModal}>
        <div slot="content">
            <textarea bind:value={commentValue} class="big-textbox"></textarea>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Add Comment</button>
                <button class="modal-button" style="background-color: lightcoral" on:click={() => commentModal.close()}>Close</button>
            </div>
        </div>
    </Modal>
</form>

<!--Case Export Modal-->
<form on:submit|preventDefault={onCaseExport}>
    <Modal bind:modalFunc={caseExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export cases?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => caseExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>