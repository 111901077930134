<script>
    import { onMount } from 'svelte';
    import { select, range, timer } from 'd3';

    export let backgroundColor = '#ffffff';

    const width = 100;
    const height = 100;
    const n = 18;
    const r = 5;
    const π = Math.PI;
    const p = 1000;

    let svg;

    onMount(() => {
        const g = select(svg)
            .selectAll('g')
            .data(range(0, 2 * π, 2 * π / n))
            .enter()
            .append('g')
            .attr('transform', d => {
                const x = width * (0.35 * Math.cos(d) + 0.5);
                const y = height * (0.35 * Math.sin(d) + 0.5);
                return `translate(${x},${y})rotate(${(d * 180 / π)})`;
            });

        const moons = g.append('path')
            .attr('fill', '#233C6B');

        function animateMoon() {
            timer(function (t) {
                const θ = 2 * π * (t % p / p);
                moons.attr('d', d => moon((θ + d) % (2 * π)));
            });
        }

        animateMoon();
    });

    function moon(θ) {
        const rx0 = θ < π ? r : -r;
        const s0 = θ < π ? 0 : 1;
        const rx1 = r * Math.cos(θ);
        const s1 = θ < π / 2 || (π <= θ && θ < 3 * π / 2) ? 0 : 1;
        return `M 0 ${r} A ${rx0} ${r} 0 0 ${s0} 0 ${-r} A ${rx1} ${r} 0 0 ${s1} 0 ${r}`;
    }
</script>

<svg bind:this={svg} width={width} height={height} style="background:{backgroundColor}">
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" fill="#233C6B" font-size="14px">
        Loading
    </text>
</svg>
